import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import './allCategories.css'

const AllCategories = () => {

    const { lang } = useSelector(state => state.nowLanguage)
    const navigate = useNavigate()

    const [ allCategories, setAllCategories ] = useState([])
    const getAllCategories = () => {
        axios.get('/api/category/all')
            .then(res => {
                setAllCategories(res.data.categories)
            }).catch(err => {
                navigate('/error')
            })
    }

    useEffect(() => {
        getAllCategories()
    },[])

    return (
        <div className='all-categories-copmonent'>
            <div className='containercha'>
                <div className='all-main-categories'>
                    {
                        allCategories ? allCategories.filter(c => c.ref === null).map((item, index) => {
                            return (
                                <Link to={`/all-products?main=${item._id}`} key={index}>
                                    {item.name[`${lang}`]}
                                </Link>
                            )
                        }) : ''
                    }
                </div>

                <div className='all-wrapper'>
                    {
                        allCategories ? allCategories.filter(c => c.ref === null).map((item, index) => {
                            return (
                                <div className='one-type' key={index}>
                                    <Link to={`/all-products?main=${item._id}`} className='main'>
                                        {item.name[`${lang}`]}
                                    </Link>
                                    {
                                        allCategories.filter(sub => sub.ref === item._id).map((item1, index1) => {
                                            return (
                                                <div className='sub-wrap' key={index1}>
                                                    <Link to={`/all-products?sub=${item1._id}`} className='sub'>{item1.name[`${lang}`]}</Link>
                                                    <div className='sub-sub-wrap'>
                                                        {
                                                            allCategories.filter(subsub => subsub.ref === item1._id).map((item2, index2) => {
                                                                return (
                                                                    <Link to={`/all-products?subsub=${item2._id}`} key={index2}>{item2.name[`${lang}`]}</Link>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }) : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default AllCategories