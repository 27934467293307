import React from 'react'
import { useTranslation } from 'react-i18next'
import { GrClose } from 'react-icons/gr'
import truePng from '../../img/true.png'
import './successOrderModal.css'

const SuccessOrderModal = (props) => {

    const { t } = useTranslation()

    const { showModal, setShowModal, orderId } = props

    return (
        <>
            <div className={showModal ? 'modals-component modals-component-active' : 'modals-component'} onClick={() => setShowModal(false)}></div>
            <div className={showModal ? 'comment-inner-modal comment-inner-modal-active' : 'comment-inner-modal'}>
                <GrClose className='icon' onClick={() => setShowModal(false)} />
                <div>
                    <img src={truePng} alt='' />
                    <h2>{t('cart.thank')}</h2>
                    <h3>{t('cart.fast-oper')}</h3>
                    <h3>{t('cart.id')} <span>{orderId}</span></h3>
                </div>
            </div>
        </>
    )
}

export default SuccessOrderModal