import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import './popularProducts.css'
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { BsEyeFill, BsFillCartFill } from 'react-icons/bs'
import { AiFillHeart } from 'react-icons/ai'
import { RiBarChartFill } from 'react-icons/ri'
import Loader from '../loader/Loader'
import FastViewModal from '../fast-view/FastViewModal'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const PopularProducts = ({ link, addCart, addFavorites, addComparison, addSeen }) => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)
    const { currency } = useSelector(state => state.currencyReducer)

    const navigate = useNavigate()

    const [loading1, setLoading1] = useState(true)
    const loader1 = loading1 ? <Loader /> : null

    const [firstProducts, setFirstProducts] = useState([])
    const [secondProducts, setSecondProducts] = useState([])
    const [thirdProducts, setThirdProducts] = useState([])

    const getProducts = () => {
        axios.get('/api/products').then(res => {
            setFirstProducts(res.data.products.slice(0, 12))
            setSecondProducts(res.data.products.slice(12, 30))
            setThirdProducts(res.data.products.slice(30, 48))
            setLoading1(false)
        }).catch(err => {
            navigate('/error')
            setLoading1(false)
        })
    }

    const [productById, setProductById] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)

    const [productRate, setProductRate] = useState()
    const [commentsCount, setCommentsCount] = useState(0)
    const fastViewById = (id) => {
        setIsModalVisible(true)
        axios.get(`/api/products?id=${id}`).then(res => {
            setProductById(res.data.products)
            setProductRate(res.data.rate[0] ? res.data.rate[0].rate : null)
        }).catch(err => {
            navigate('/error')
        })

        axios.get(`/api/comment`, {
            params: {
                product: id
            }
        }).then(res => {
            setCommentsCount(res.data.count)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course?.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [firstBanner, setFirstBanner] = useState([])
    const [secondBanner, setSecondBanner] = useState([])
    const [thirdBanner, setThirdBanner] = useState([])
    const [fourthBanner, setFourthBanner] = useState([])
    const getBanners = () => {
        axios.get(`/api/banner?status=first`).then(res => {
            setFirstBanner(res.data.banners ? res.data.banners[0] : '')
        }).catch(err => {
            navigate('/error')
        })

        axios.get(`/api/banner?status=second`).then(res => {
            setSecondBanner(res.data.banners ? res.data.banners[0] : '')
        }).catch(err => {
            navigate('/error')
        })

        axios.get(`/api/banner?status=third`).then(res => {
            setThirdBanner(res.data.banners ? res.data.banners[0] : '')
        }).catch(err => {
            navigate('/error')
        })

        axios.get(`/api/banner?status=fours`).then(res => {
            setFourthBanner(res.data.banners ? res.data.banners[0] : '')
        }).catch(err => {
            navigate('/error')
        })
    }

    const [firstBannerMenu, setFirstBannerMenu] = useState([])
    const [secondBannerMenu, setSecondBannerMenu] = useState([])
    const [thirdBannerMenu, setThirdBannerMenu] = useState([])
    const [fourthBannerMenu, setFourthBannerMenu] = useState([])
    const getMenuBanners = () => {
        axios.get(`/api/banner?status=mini`).then(res => {
            setFirstBannerMenu(res.data.banners ? res.data.banners[0] : '')
            setSecondBannerMenu(res.data.banners ? res.data.banners[1] : '')
            setThirdBannerMenu(res.data.banners ? res.data.banners[2] : '')
            setFourthBannerMenu(res.data.banners ? res.data.banners[3] : '')
        }).catch(err => {
            navigate('/error')
        })
    }

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        getProducts()
        getCurrencyValue()
    }, [])

    useEffect(() => {
        getMenuBanners()
        getBanners()
    }, [lang])

    const navigateProductDetail = (e, id) => {
        if (e.target.className === 'curtain' || e.target.className === 'wrapper' || e.target.className === 'bg-img') {
            navigate(`/product/${id}`)
        } else {
            return 0
        }
    }

    return (
        <div className='popular-products-component'>

            <Row className='top-row'>
                <Col lg={3} md={3} sm={3} xs={6}>
                    <Link to='/catalog-by-car-brands'>
                        <img src={`${link}/${firstBanner && firstBanner.photo ? firstBanner.photo[`${lang}`] : ''}`} alt='' />
                    </Link>
                </Col>
                <Col lg={3} md={3} sm={3} xs={6}>
                    <Link to='/catalog-products'>
                        <img src={`${link}/${secondBanner && secondBanner.photo ? secondBanner.photo[`${lang}`] : ''}`} alt='' />
                    </Link>
                </Col>
                <Col lg={3} md={3} sm={3} xs={6}>
                    <Link to='/all-products?discount=1&sale=true'>
                        <img src={`${link}/${thirdBanner && thirdBanner.photo ? thirdBanner.photo[`${lang}`] : ''}`} alt='' />
                    </Link>
                </Col>
                <Col lg={3} md={3} sm={3} xs={6}>
                    <Link to='/all-brands'>
                        <img src={`${link}/${fourthBanner && fourthBanner.photo ? fourthBanner.photo[`${lang}`] : ''}`} alt='' />
                    </Link>
                </Col>
            </Row>

            <h1>{t('home.products')}</h1>
            {loader1}

            <FastViewModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                productById={productById}
                link={link}
                addCart={addCart}
                addComparison={addComparison}
                addFavorites={addFavorites}
                productRate={productRate}
                commentsCount={commentsCount}
                currency={currency}
                currencyValue={currencyValue}
                addSeen={addSeen}
            />
            <Row>
                {
                    firstProducts ? firstProducts.map((item, index) => {
                        return (
                            <Col lg={2} md={3} sm={4} xs={6} key={index} onClick={(e) => navigateProductDetail(e, item._id)}>
                                <div className='product'>
                                    <div className='bg-img' style={{ backgroundImage: `url(${link}/${item.photo})` }}>
                                        <div className='left-top' style={{ display: `${item.discount === null ? 'none' : 'flex'}` }}>
                                            <span>{`-${item.discount}%`}</span>
                                        </div>
                                        <div className='right-top' style={{ display: `${item.top ? 'flex' : 'none'}` }}>
                                            <div></div>
                                            <span>{t('product.lider')}</span>
                                        </div>
                                        <div className='left-bottom' style={{ display: `${item.status === 'advert' ? 'flex' : 'none'}` }}>
                                            <span>{t('product.advert')}</span>
                                        </div>
                                        <div className='left-bottom left-bottom-second' style={{ display: `${item.status === 'new' ? 'flex' : 'none'}` }}>
                                            <span>{t('product.new')}</span>
                                        </div>
                                        <div className='right-bottom' style={{ display: `${item.sale.status ? 'flex' : 'none'}` }}>
                                            <span>{t('product.sale')}</span>
                                        </div>
                                    </div>
                                    <div className='data-texts'>
                                        {
                                            currency === "UZS" ? (
                                                <div className='sale'>
                                                    <span style={{ opacity: item.discount ? '1' : '0' }}>{reFormat(item.price * currencyValue)} {t('cart.som')}</span>
                                                    <p>{reFormat(Math.ceil(item.price * (100 - item.discount) / 100) * currencyValue)} {t('cart.som')}</p>
                                                </div>
                                            ) : (
                                                <div className='sale'>
                                                    <span style={{ opacity: item.discount ? '1' : '0' }}>{reFormat(item.price)} $</span>
                                                    <p>{reFormat(Math.ceil(item.price * (100 - item.discount) / 100))} $</p>
                                                </div>
                                            )
                                        }
                                        <Link to={`/product/${item._id}`} className='text' onClick={() => addSeen(item._id)}>
                                            <p>{item.brand.name} / {item.name[`${lang}`]} / {item.partner.name} {item.model.name}</p>
                                        </Link>
                                    </div>

                                    <div className='mobile-bottom-menu'>
                                        <div className='icon-wrap' onClick={() => addCart(item._id)} >
                                            <BsFillCartFill className='icon' />
                                        </div>
                                        <div className='icon-wrap' onClick={() => addFavorites(item._id)}>
                                            <AiFillHeart className='icon' />
                                        </div>
                                        <div className='icon-wrap' onClick={() => addComparison(item._id)}>
                                            <RiBarChartFill className='icon' />
                                        </div>
                                    </div>

                                    <div className='curtain'>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.favorites')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addFavorites(item._id)}>
                                                    <AiFillHeart className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.comparison')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addComparison(item._id)}>
                                                    <RiBarChartFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.see')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => fastViewById(item._id)}>
                                                    <BsEyeFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.cart')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addCart(item._id)}>
                                                    <BsFillCartFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    }) : ''
                }
            </Row>

            <Row className='row-images'>
                {
                    firstBannerMenu ? (
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Link to={firstBannerMenu.product === null ? firstBannerMenu.brand !== null ? `/all-products?brand=${firstBannerMenu.brand._id}` : '/' : `/product/${firstBannerMenu.product?._id}`}>
                                <img src={`${link}/${firstBannerMenu && firstBannerMenu.photo ? firstBannerMenu.photo[`${lang}`] : ''}`} alt='' />
                            </Link>
                        </Col>
                    ) : null
                }
                {
                    secondBannerMenu ? (
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Link to={secondBannerMenu.product === null ? secondBannerMenu.brand !== null ? `/all-products?brand=${secondBannerMenu.brand._id}` : '/' : `/product/${secondBannerMenu.product?._id}`}>
                                <img src={`${link}/${secondBannerMenu && secondBannerMenu.photo ? secondBannerMenu.photo[`${lang}`] : ''}`} alt='' />
                            </Link>
                        </Col>
                    ) : null
                }
            </Row>


            {/* MOBILE VERSION */}
            <div className="home-banner carusel-banner">
                <Swiper
                    cssMode={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                    className="mySwiper"
                >
                    {
                        firstBannerMenu ? (
                            <SwiperSlide>
                                <Link to={firstBannerMenu.product === null ? firstBannerMenu.brand !== null ? `/all-products?brand=${firstBannerMenu.brand._id}` : '/' : `/product/${firstBannerMenu.product?._id}`}>
                                    <img src={`${link}/${firstBannerMenu && firstBannerMenu.photo ? firstBannerMenu.photo[`${lang}`] : ''}`} alt='' />
                                </Link>
                            </SwiperSlide>
                        ) : null
                    }
                    {
                        secondBannerMenu ? (
                            <SwiperSlide>
                                <Link to={secondBannerMenu.product === null ? secondBannerMenu.brand !== null ? `/all-products?brand=${secondBannerMenu.brand._id}` : '/' : `/product/${secondBannerMenu.product?._id}`}>
                                    <img src={`${link}/${secondBannerMenu && secondBannerMenu.photo ? secondBannerMenu.photo[`${lang}`] : ''}`} alt='' />
                                </Link>
                            </SwiperSlide>
                        ) : null
                    }
                </Swiper>
            </div>

            <Row>
                {
                    secondProducts ? secondProducts.map((item, index) => {
                        return (
                            <Col lg={2} md={3} sm={4} xs={6} key={index} onClick={(e) => navigateProductDetail(e, item._id)}>
                                <div className='product'>
                                    <div className='bg-img' style={{ backgroundImage: `url(${link}/${item.photo})` }}>
                                        <div className='left-top' style={{ display: `${item.discount === null ? 'none' : 'flex'}` }}>
                                            <span>{`-${item.discount}%`}</span>
                                        </div>
                                        <div className='right-top' style={{ display: `${item.top ? 'flex' : 'none'}` }}>
                                            <div></div>
                                            <span>{t('product.lider')}</span>
                                        </div>
                                        <div className='left-bottom' style={{ display: `${item.status === 'advert' ? 'flex' : 'none'}` }}>
                                            <span>{t('product.advert')}</span>
                                        </div>
                                        <div className='left-bottom left-bottom-second' style={{ display: `${item.status === 'new' ? 'flex' : 'none'}` }}>
                                            <span>{t('product.new')}</span>
                                        </div>
                                        <div className='right-bottom' style={{ display: `${item.sale.status ? 'flex' : 'none'}` }}>
                                            <span>{t('product.sale')}</span>
                                        </div>
                                    </div>
                                    <div className='data-texts'>
                                        {
                                            currency === "UZS" ? (
                                                <div className='sale'>
                                                    <span style={{ opacity: item.discount ? '1' : '0' }}>{reFormat(item.price * currencyValue)} {t('cart.som')}</span>
                                                    <p>{reFormat(Math.ceil(item.price * (100 - item.discount) / 100) * currencyValue)} {t('cart.som')}</p>
                                                </div>
                                            ) : (
                                                <div className='sale'>
                                                    <span style={{ opacity: item.discount ? '1' : '0' }}>{reFormat(item.price)} $</span>
                                                    <p>{reFormat(Math.ceil(item.price * (100 - item.discount) / 100))} $</p>
                                                </div>
                                            )
                                        }
                                        <Link to={`/product/${item._id}`} className='text' onClick={() => addSeen(item._id)}>
                                            <p>{item.brand.name} / {item.name[`${lang}`]} / {item.partner.name} {item.model.name}</p>
                                        </Link>
                                    </div>

                                    <div className='mobile-bottom-menu'>
                                        <div className='icon-wrap' onClick={() => addCart(item._id)} >
                                            <BsFillCartFill className='icon' />
                                        </div>
                                        <div className='icon-wrap' onClick={() => addFavorites(item._id)}>
                                            <AiFillHeart className='icon' />
                                        </div>
                                        <div className='icon-wrap' onClick={() => addComparison(item._id)}>
                                            <RiBarChartFill className='icon' />
                                        </div>
                                    </div>

                                    <div className='curtain'>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.favorites')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addFavorites(item._id)}>
                                                    <AiFillHeart className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.comparison')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addComparison(item._id)}>
                                                    <RiBarChartFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.see')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => fastViewById(item._id)}>
                                                    <BsEyeFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.cart')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addCart(item._id)}>
                                                    <BsFillCartFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    }) : ''
                }
            </Row>

            <Row className='row-images'>
                {
                    thirdBannerMenu ? (
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Link to={thirdBannerMenu.product === null ? thirdBannerMenu.brand !== null ? `/all-products?brand=${thirdBannerMenu.brand._id}` : '/' : `/product/${thirdBannerMenu.product?._id}`}>
                                <img src={`${link}/${thirdBannerMenu && thirdBannerMenu.photo ? thirdBannerMenu.photo[`${lang}`] : ''}`} alt='' />
                            </Link>
                        </Col>
                    ) : null
                }
                {
                    fourthBannerMenu ? (
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Link to={fourthBannerMenu.product === null ? fourthBannerMenu.brand !== null ? `/all-products?brand=${fourthBannerMenu.brand._id}` : '/' : `/product/${fourthBannerMenu.product?._id}`}>
                                <img src={`${link}/${fourthBannerMenu && fourthBannerMenu.photo ? fourthBannerMenu.photo[`${lang}`] : ''}`} alt='' />
                            </Link>
                        </Col>
                    ) : null
                }
            </Row>

            {/* MOBILE VERSION */}
            <div className="home-banner carusel-banner">
                <Swiper
                    cssMode={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                    className="mySwiper"
                >
                    {
                        thirdBannerMenu ? (
                            <SwiperSlide>
                                <Link to={thirdBannerMenu.product === null ? thirdBannerMenu.brand !== null ? `/all-products?brand=${thirdBannerMenu.brand._id}` : '/' : `/product/${thirdBannerMenu.product?._id}`}>
                                    <img src={`${link}/${thirdBannerMenu && thirdBannerMenu.photo ? thirdBannerMenu.photo[`${lang}`] : ''}`} alt='' />
                                </Link>
                            </SwiperSlide>
                        ) : null
                    }
                    {
                        fourthBannerMenu ? (
                            <SwiperSlide>
                                <Link to={fourthBannerMenu.product === null ? fourthBannerMenu.brand !== null ? `/all-products?brand=${fourthBannerMenu.brand._id}` : '/' : `/product/${fourthBannerMenu.product?._id}`}>
                                    <img src={`${link}/${fourthBannerMenu && fourthBannerMenu.photo ? fourthBannerMenu.photo[`${lang}`] : ''}`} alt='' />
                                </Link>
                            </SwiperSlide>
                        ) : null
                    }
                </Swiper>
            </div>

            <Row>
                {
                    thirdProducts ? thirdProducts.map((item, index) => {
                        return (
                            <Col lg={2} md={3} sm={4} xs={6} key={index} onClick={(e) => navigateProductDetail(e, item._id)}>
                                <div className='product'>
                                    <div className='bg-img' style={{ backgroundImage: `url(${link}/${item.photo})` }}>
                                        <div className='left-top' style={{ display: `${item.discount === null ? 'none' : 'flex'}` }}>
                                            <span>{`-${item.discount}%`}</span>
                                        </div>
                                        <div className='right-top' style={{ display: `${item.top ? 'flex' : 'none'}` }}>
                                            <div></div>
                                            <span>{t('product.lider')}</span>
                                        </div>
                                        <div className='left-bottom' style={{ display: `${item.status === 'advert' ? 'flex' : 'none'}` }}>
                                            <span>{t('product.advert')}</span>
                                        </div>
                                        <div className='left-bottom left-bottom-second' style={{ display: `${item.status === 'new' ? 'flex' : 'none'}` }}>
                                            <span>{t('product.new')}</span>
                                        </div>
                                        <div className='right-bottom' style={{ display: `${item.sale.status ? 'flex' : 'none'}` }}>
                                            <span>{t('product.sale')}</span>
                                        </div>
                                    </div>
                                    <div className='data-texts'>
                                        {
                                            currency === "UZS" ? (
                                                <div className='sale'>
                                                    <span style={{ opacity: item.discount ? '1' : '0' }}>{reFormat(item.price * currencyValue)} {t('cart.som')}</span>
                                                    <p>{reFormat(Math.ceil(item.price * (100 - item.discount) / 100) * currencyValue)} {t('cart.som')}</p>
                                                </div>
                                            ) : (
                                                <div className='sale'>
                                                    <span style={{ opacity: item.discount ? '1' : '0' }}>{reFormat(item.price)} $</span>
                                                    <p>{reFormat(Math.ceil(item.price * (100 - item.discount) / 100))} $</p>
                                                </div>
                                            )
                                        }
                                        <Link to={`/product/${item._id}`} className='text' onClick={() => addSeen(item._id)}>
                                            <p>{item.brand.name} / {item.name[`${lang}`]} / {item.partner.name} {item.model.name}</p>
                                        </Link>
                                    </div>

                                    <div className='mobile-bottom-menu'>
                                        <div className='icon-wrap' onClick={() => addCart(item._id)} >
                                            <BsFillCartFill className='icon' />
                                        </div>
                                        <div className='icon-wrap' onClick={() => addFavorites(item._id)}>
                                            <AiFillHeart className='icon' />
                                        </div>
                                        <div className='icon-wrap' onClick={() => addComparison(item._id)}>
                                            <RiBarChartFill className='icon' />
                                        </div>
                                    </div>

                                    <div className='curtain'>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.favorites')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addFavorites(item._id)}>
                                                    <AiFillHeart className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.comparison')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addComparison(item._id)}>
                                                    <RiBarChartFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.see')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => fastViewById(item._id)}>
                                                    <BsEyeFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='wrapper'>
                                            <div></div>
                                            <div>
                                                <div className='text'>
                                                    <p>{t('product.cart')}</p>
                                                    <div className='line'></div>
                                                </div>
                                                <Link to='/' className='icon-wrap' onClick={() => addCart(item._id)}>
                                                    <BsFillCartFill className='icon' />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    }) : ''
                }
            </Row>

        </div>
    )
}

export default PopularProducts