import React from 'react'
import { useTranslation } from 'react-i18next'
import deliveryPng from '../../img/delivery.png'
import './delivery.css'

const Delivery = () => {

    const { t } = useTranslation()

    return (
        <div className='delivery-component'>
            <div className='containercha'>
                <img src={deliveryPng} alt='' className='banner' />
                <h1>{t('delivery.delivery')}</h1>
                <h2 style={{color: 'red'}}>{t('delivery.h1')}</h2>
                <p>{t('delivery.p1')}</p>
                <p>{t('delivery.p2')}</p>
                <p>{t('delivery.p3')}</p>
                <p>{t('delivery.p4')}</p>

                <h2>{t('delivery.h2')}</h2>
                <p>{t('delivery.p5')}</p>

                <h2>{t('delivery.h3')}</h2>
                <p>{t('delivery.p6')}</p>
                <p>{t('delivery.p7')}</p>
                <p>{t('delivery.p8')}</p>
                <p>{t('delivery.p9')}</p>
                <p>{t('delivery.p10')}</p>
                <p>{t('delivery.p11')}</p>
                <p>{t('delivery.p12')}</p>
                <p>{t('delivery.p13')}</p>
                <p>{t('delivery.p14')}</p>

                <h2>{t('delivery.h4')}</h2>
                <p>{t('delivery.p15')}</p>
                <p>{t('delivery.p16')}</p>

                <h2>{t('delivery.h5')}</h2>
                <p>{t('delivery.p17')}</p>
                <p>{t('delivery.p18')}</p>
                <p>{t('delivery.p19')}</p>
                <p>{t('delivery.p20')}</p>

                <h2 style={{fontStyle: 'italic'}}>{t('delivery.h6')}</h2>

                <h2>{t('delivery.h7')}</h2>
                <p>{t('delivery.p21')}</p>

                <h2>{t('delivery.h8')}</h2>
                <p>{t('delivery.p22')}</p>
                <p>{t('delivery.p23')}</p>
                <p>{t('delivery.p24')}</p>
                <p>{t('delivery.p25')}</p>

                <h2>{t('delivery.h9')}</h2>
                <p>{t('delivery.p26')}</p>
                <p>{t('delivery.p27')}</p>
                <p>{t('delivery.p28')}</p>
                <p>{t('delivery.p29')}</p>
                <p>{t('delivery.p30')}</p>
                <p>{t('delivery.p31')}</p>

                <h2>{t('delivery.h10')}</h2>
                <p>{t('delivery.p32')}</p>
                <p>{t('delivery.p33')}</p>
                <p>{t('delivery.p34')}</p>
                <p>{t('delivery.p35')}</p>
                <p>{t('delivery.p36')}</p>

                <h2>{t('delivery.h11')}</h2>
                <p>{t('delivery.p37')}</p>
                <p>{t('delivery.p38')}</p>
                <p>{t('delivery.p39')}</p>
                <p>{t('delivery.p40')}</p>
                <p>{t('delivery.p41')}</p>
                <p>{t('delivery.p42')}</p>
                <p>{t('delivery.p43')}</p>
                <p>{t('delivery.p44')}</p>

                <h2 style={{color: 'red'}}>{t('delivery.h12')}</h2>

                <h2>{t('delivery.h13')}</h2>
                <p>{t('delivery.p45')}</p>
                <p>{t('delivery.p46')}</p>

                <h2 style={{fontStyle: 'italic'}}>{t('delivery.h14')}</h2>
                <p><span style={{color: '#212121', fontWeight: '600'}}>{t('delivery.p47-span')}</span> {t('delivery.p47')}</p>
            </div>
        </div>
    )
}

export default Delivery