import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { BiEditAlt } from 'react-icons/bi'
import { HiOutlineLogout } from 'react-icons/hi'
import { IoIosArrowForward } from 'react-icons/io'
import { Rating } from 'react-simple-star-rating'
import { Link, useNavigate } from 'react-router-dom'
import './myComments.css'
import { MdDelete } from 'react-icons/md'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../App'

const MyComments = ({ link, userData, setRend, rend, setFavoritesCount, setCartCount, setComparisonCount }) => {

    const token = window.localStorage.getItem('token')
    const navigate = useNavigate()

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)
    const { currency } = useSelector(state => state.currencyReducer)

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course?.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [allComments, setAllComments] = useState([])
    const getMyComments = () => {
        axios.get('/api/comment/me', {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setAllComments(res.data.comments)
        }).catch(err => {
            navigate('/error')
        })
    }

    const deleteComment = (id) => {
        axios.put(`/api/comment/${id}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            getMyComments()
        }).catch(err => {
            navigate('/error')
        })
    }

    const logout = () => {
        window.localStorage.removeItem('token')
        navigate('/')
        setRend(!rend)
        setFavoritesCount(0)
        setCartCount(0)
        setComparisonCount(0)
    }

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        getMyComments()
        getCurrencyValue()
        //eslint-disable-next-line
    }, [])

    return (
        <div className='my-comments-component'>
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/profile'>{t('home.profile')}</Link> / <span>{t('profile.my-comments')}</span></p>
                </div>

                <div className='wrapper'>
                    <div className='left-user-menu'>
                        <div className='user-top'>
                            <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
                            <div className='name-phone'>
                                <h4>{userData ? userData.firstname : ''} {userData ? userData.lastname : ''}</h4>
                                <p>{userData ? userData.number : ''}</p>
                            </div>
                            <Link to='/profile' className='icon-wrap'>
                                <BiEditAlt className='icon' />
                            </Link>
                        </div>
                        <div className='links-wrapper'>
                            <Link to='/my-orders'>
                                <h5>{t('profile.my-orders')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-address'>
                                <h5>{t('profile.my-address')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-messages'>
                                <h5>{t('profile.sms')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-comments'>
                                <h5>{t('profile.my-comments')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/favorites'>
                                <h5>{t('navbar.favorites')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/comparison'>
                                <h5>{t('profile.comparison-list')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                        </div>
                        <div className='logout-wrap' style={{ cursor: 'pointer' }} onClick={logout}>
                            <HiOutlineLogout className='icon' />
                            <h5>{t('profile.logout')}</h5>
                        </div>
                    </div>

                    <div className='right-layout'>
                        <h1>{t('profile.my-comments')}</h1>
                        <Row className='all-comments'>
                            {
                                allComments && Array.isArray(allComments) ? allComments.map((item, index) => {
                                    return (
                                        <Col lg={6} md={12} sm={12} xs={12} key={index}>
                                            <div className='comment'>
                                                <div className='top'>
                                                    <div className='bg' style={{ backgroundImage: `url(${link}/${item.product.photo})` }}></div>
                                                    <div className='texts'>
                                                        <p>{item.product.brand.name} / {item.product.name[`${lang}`]} / {item.product.partner.name} {item.product.model.name}</p>
                                                        {/* <h4>{Math.ceil(item.product.price * (100 - item.product.discount) / 100)} {t('cart.som')}</h4> */}
                                                        {
                                                            currency === 'UZS' ? (
                                                                <h4>{reFormat(Math.ceil(item?.product.price * (100 - item?.product.discount) / 100) * currencyValue)} {t('cart.som')}</h4>
                                                            ) : (
                                                                <h4>{reFormat(Math.ceil(item?.product.price * (100 - item?.product.discount) / 100))} $</h4>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <h5>{t('cart.rate')}</h5>
                                                <Rating ratingValue={item.rate * 20} allowHover={false} readonly size={32} />
                                                <h5>{t('cart.name')}</h5>
                                                <input type='text' value={item.name} readOnly />
                                                <h5>{t('cart.comment')}</h5>
                                                <div className='comment-text'>
                                                    {item.comment}
                                                </div>
                                                <div className='del-wrap' onClick={() => deleteComment(item._id)}>
                                                    <MdDelete className='icon' />
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }) : ''
                            }
                        </Row>
                        {
                            allComments.length === 0 ? (
                                <h6>{t('empty.empty')}</h6>
                            ) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyComments