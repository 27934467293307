import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import axios from 'axios'
import './jobs.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Jobs = () => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    const [allJobs, setAllJobs] = useState([])
    const getAllJobs = () => {
        axios.get('/api/vacancy').then(res => {
            setAllJobs(res.data.vacancy)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getAllJobs()
    }, [])

    return (
        <div className='jobs-component'>
            <div className='containercha'>
                <h1>{t('vacancy.empty')}</h1>
                <Row>
                    {
                        allJobs && Array.isArray(allJobs) ? allJobs.map((item, index) => {
                            return (
                                <Col lg={6} md={6} sm={12} xs={12} key={index}>
                                    <div className='job'>
                                        <div className='top'>
                                            <h4>{item.title[`${lang}`]}</h4>
                                        </div>
                                        <div className='bottom'>
                                            <p>{item.text[`${lang}`]}</p>
                                            <span>{item.city[`${lang}`]}</span>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }) : ''
                    }
                </Row>
            </div>
        </div>
    )
}

export default Jobs