import { Modal } from 'antd'
import React, { useState } from 'react'
import './fastView.css'
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";
import { Rating } from 'react-simple-star-rating';

import { AiFillHeart } from 'react-icons/ai';
import { BsFillBarChartFill, BsFillCartFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom'
import FastOrder from '../fast-order/FastOrder';

import axios from 'axios'
import SuccessOrderModal from './SuccessOrderModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const FastViewModal = (props) => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const [activeIndex, setActiveIndex] = useState(null);
    const [num, setNum] = useState(1)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [master, setMaster] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isModalVisible1, setIsModalVisible1] = useState(false)

    const { isModalVisible, setIsModalVisible, productById, link, addFavorites, addCart, addComparison,
        productRate, commentsCount, currency, currencyValue, addSeen
    } = props

    const navigate = useNavigate()

    const [orderId, setOrderId] = useState('')
    const submitOrder = (e) => {
        e.preventDefault()
        const formData = {
            firstname: name,
            phone,
            totalCount: num,
            totalDiscount: productById.discount ? Math.floor((productById.discount * productById.price) / 100) * num : 0,
            totalPrice: productById.price * num,
            totalMaster: master ? productById?.master : 0,
            products: [
                {
                    product: productById ? productById._id : '',
                    count: num,
                    master: master ? productById?.master : 0,
                    sum: productById.discount ? Math.ceil((productById.price * (100 - productById.discount)) / 100) * num : productById.price * num,
                    discount: productById.discount ? Math.floor((productById.discount * productById.price) / 100) * num : 0
                }
            ]
        }
        axios.post('/api/order/quick', formData).then(res => {
            setIsModalVisible1(false)
            setOrderId(res.data.orderId)
            setShowModal(true)
        }).catch(err => {
            navigate('/error')
        })
    }

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    return (
        <Modal
            centered
            title={null}
            visible={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            closable={false}
            className='fast-view-modal'
        >
            <FastOrder
                isModalVisible1={isModalVisible1}
                setIsModalVisible1={setIsModalVisible1}
                submitOrder={submitOrder}
                name={name}
                setName={setName}
                phone={phone}
                setPhone={setPhone}
            />

            <SuccessOrderModal
                setShowModal={setShowModal}
                showModal={showModal}
                orderId={orderId}
            />
            <div className='wrapper'>
                <div className='left'>
                    <div className='bg' style={{ backgroundImage: activeIndex === null ? `url(${link}/${productById ? productById.photo : ''})` : `url(${link}/${productById.photos[activeIndex]})` }}>
                        <div className='left-top' style={{ display: `${productById.discount === null ? 'none' : 'flex'}` }}>
                            <span>{`-${productById.discount}%`}</span>
                        </div>
                        <div className='right-top' style={{ display: `${productById.top ? 'flex' : 'none'}` }}>
                            <div></div>
                            <span>{t('product.lider')}</span>
                        </div>
                        <div className='left-bottom' style={{ display: `${productById.status === 'advert' ? 'flex' : 'none'}` }}>
                            <span>{t('product.advert')}</span>
                        </div>
                        <div className='left-bottom left-bottom-second' style={{ display: `${productById.status === 'new' ? 'flex' : 'none'}` }}>
                            <span>{t('product.new')}</span>
                        </div>
                        <div className='right-bottom' style={{ display: `${(productById.sale && productById.sale.status) ? 'flex' : 'none'}` }}>
                            <span>{t('product.sale')}</span>
                        </div>
                    </div>
                    <div className="fast-extra-images-wrapper">
                        <Swiper
                            slidesPerView={3}
                            centeredSlides={true}
                            spaceBetween={30}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                            onActiveIndexChange={(e) => setActiveIndex(e.activeIndex)}
                        >
                            {
                                productById && productById.photos && Array.isArray(productById.photos) ? productById.photos.map((item, index) => {
                                    return <SwiperSlide key={index} style={{ backgroundImage: `url(${link}/${item})` }}></SwiperSlide>
                                }) : ''
                            }
                        </Swiper>
                    </div>
                </div>
                <div className='right'>
                    <h2>{productById.brand ? productById.brand.name : ''} / {productById.name ? productById.name[`${lang}`] : ''} / {productById.partner ? productById.partner.name : ''} {productById.model ? productById.model.name : ''}</h2>
                    <div className='rating-w'>
                        {
                            productRate ? (
                                <Rating ratingValue={productRate * 20} readonly size={32} />
                            ) : null
                        }
                        {
                            commentsCount ? (
                                <h6>({commentsCount} {t('product.comment')})</h6>
                            ) : null
                        }
                    </div>
                    <p>{t('product.kod')} <strong>{productById.productCode}</strong></p>
                    {
                        currency === "USD" ? (
                            <div className='sums-w'>
                                <h1>{(productById.price * (100 - productById.discount) / 100) ? reFormat(Math.ceil((productById.price * (100 - productById.discount)) / 100)) : ''} $</h1>
                                <h2 style={{ display: productById.discount ? 'block' : 'none', color: 'red' }}>{reFormat(productById.price)} $</h2>
                            </div>
                        ) : (
                            <div className='sums-w'>
                                <h1>{(productById.price * (100 - productById.discount) / 100) ? reFormat(Math.ceil((productById.price * (100 - productById.discount)) / 100) * currencyValue) : ''} {t('cart.som')}</h1>
                                <h2 style={{ display: productById.discount ? 'block' : 'none', color: 'red' }}>{reFormat(productById.price * currencyValue)} {t('cart.som')}</h2>
                            </div>
                        )
                    }
                    <p style={{ display: `${(productById.sale && productById.sale.status) ? 'flex' : 'none'}` }}>{t('product.time-sale')} <strong>{productById.sale ? productById.sale[`${lang}`] : ''}</strong></p>
                    <p>{t('product.country')} <strong>{productById.country ? productById.country[`${lang}`] : ''}</strong></p>
                    <img src={`${link}/${productById.brand ? productById.brand.photo : ''}`} alt='' />

                    {
                        productById.master ? (
                            <div className='chechbox-w'>
                                <input id='with' type='checkbox' value={master} onChange={() => setMaster(!master)} />
                                <label htmlFor='with'>{t('product.master')} {reFormat(productById.master)} {t('cart.som')}</label>
                            </div>
                        ) : null
                    }

                    <div className='qator'>
                        <div className='nums-wrap'>
                            <div onClick={() => num > 1 ? setNum(num - 1) : null}>
                                <span>-</span>
                            </div>
                            <input type='number' readOnly value={num} />
                            <div onClick={() => setNum(num + 1)}>
                                <span>+</span>
                            </div>
                        </div>
                        <button className='fast' onClick={() => setIsModalVisible1(true)}>{t('product.fast-order')}</button>
                    </div>
                    <div className='qator'>
                        <div className='btn-w'>
                            <div onClick={() => { addFavorites(productById._id); setIsModalVisible(false) }}>
                                <AiFillHeart className='icon' />
                            </div>
                            <div onClick={() => { addComparison(productById._id); setIsModalVisible(false) }}>
                                <BsFillBarChartFill className='icon' />
                            </div>
                        </div>
                        <button className='add' onClick={() => { addCart(productById._id); setIsModalVisible(false) }}><BsFillCartFill className='icon' />{t('product.cart')}</button>
                    </div>
                    <Link to={`/product/${productById ? productById._id : ''}`} onClick={() => addSeen(productById ? productById._id : '')}>
                        <button>{t('product.more')}</button>
                    </Link>
                </div>
            </div>
        </Modal>
    )
}

export default FastViewModal