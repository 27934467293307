import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import './login.css'
import LoginError from './LoginError';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = ({ rend, setRend, setUserMenu, getFavoritesCount, getUserData }) => {

    const navigate = useNavigate()

    const { t } = useTranslation()

    const [number, setNumber] = useState('')
    const [password, setPassword] = useState('')
    const [passwordShow, setPasswordShow] = useState(false)

    const [showErr, setShowErr] = useState(false)

    const submitNumber = (e) => {
        e.preventDefault()

        axios.post('/api/auth/login', { number, password }).then(res => {
            window.localStorage.setItem('token', res.data.token)
            window.localStorage.removeItem('carts')
            setUserMenu(false)
            navigate('/')
            getUserData()
            setRend(!rend)
            getFavoritesCount()
        }).catch(err => {
            setShowErr(true)
            // navigate('/error')
        })
    }

    return (
        <div className='login-component'>
            {<LoginError showErr={showErr} setShowErr={setShowErr} />}
            <div className='containercha'>
                <div className='square'>
                    <h2>{t('login.login')}</h2>
                    <p>{t('login.text')}</p>
                    <form className='input-wrap' onSubmit={submitNumber}>
                        <div>
                            <label>{t('cart.phone')}</label>
                            <ReactInputMask maskChar={null} name="phone" required mask="+\9\9\8999999999" value={number} onChange={(e) => setNumber(e.target.value)} />
                        </div>
                        <div>
                            <label>{t('cart.password')}</label>
                            <div className='password-wrapper'>
                                {
                                    passwordShow ? <FaEye className='icon' onClick={() => setPasswordShow(false)} /> : <FaEyeSlash className='icon' onClick={() => setPasswordShow(true)} />
                                }
                                <input type={passwordShow ? 'text' : 'password'} name="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <Link to='/register'>{t('login.register')}</Link>
                        </div>
                        <button type='submit' className='active-btn'>{t('login.kirish')}</button>
                    </form>
                    <span>{t('login.log')}</span>
                </div>
            </div>
        </div>
    )
}

export default Login