import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillHeart } from 'react-icons/ai'
import { RiBarChartFill } from 'react-icons/ri'
import { BsFillCartFill } from 'react-icons/bs'
import { Rating } from 'react-simple-star-rating'
import './productStyle3.css'
import axios from 'axios'
import FastOrder from '../fast-order/FastOrder'
import SuccessOrderModal from '../fast-view/SuccessOrderModal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const ProductStyle3 = (props) => {

    const { link, item, addComparison, addCart, addFavorites, currency, currencyValue, addSeen } = props

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    const [num, setNum] = useState(1)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [master, setMaster] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isModalVisible1, setIsModalVisible1] = useState(false)

    const [productRate, setProductRate] = useState()
    const fastViewById = () => {
        if (item) {
            axios.get(`/api/products?id=${item._id}`).then(res => {
                setProductRate(res.data.rate[0] ? res.data.rate[0].rate : null)
            }).catch(err => {
                navigate('/error')
            })
            axios.get(`/api/comment`, {
                params: {
                    product: item?._id
                }
            }).then(res => {
                setCommentsCount(res.data.count)
            }).catch(err => {
                navigate('/error')
            })
        }
    }

    const [commentsCount, setCommentsCount] = useState('')
    const getComments = () => {
        if (item) {
            axios.get(`/api/comment`, {
                params: {
                    product: item._id
                }
            }).then(res => {
                setCommentsCount(res.data.count)
            }).catch(err => {
                navigate('/error')
            })
        }
    }

    const [orderId, setOrderId] = useState('')
    const submitOrder = (e) => {
        e.preventDefault()
        const formData = {
            firstname: name,
            phone,
            totalCount: num,
            totalDiscount: item.discount ? Math.floor((item.discount * item.price) / 100) * num : 0,
            totalPrice: item.price * num,
            totalMaster: master ? item?.master : 0,
            products: [
                {
                    product: item ? item._id : '',
                    count: num,
                    master: master ? item?.master : 0,
                    sum: item.discount ? Math.ceil((item.price * (100 - item.discount)) / 100) * num : item.price * num,
                    discount: item.discount ? Math.floor((item.discount * item.price) / 100) * num : 0
                }
            ]
        }
        axios.post('/api/order/quick', formData).then(res => {
            setIsModalVisible1(false)
            setOrderId(res.data.orderId)
            setShowModal(true)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        fastViewById()
        getComments()
        //eslint-disable-next-line
    }, [])

    return (
        <Col lg={12} md={12} sm={12} xs={12}>

            <FastOrder
                isModalVisible1={isModalVisible1}
                setIsModalVisible1={setIsModalVisible1}
                submitOrder={submitOrder}
                name={name}
                setName={setName}
                phone={phone}
                setPhone={setPhone}
            />

            <SuccessOrderModal
                setShowModal={setShowModal}
                showModal={showModal}
                orderId={orderId}
            />

            <div className='product-style3'>
                <div className='bg-text-wrapper'>
                    <div className='bg' style={{ backgroundImage: `url(${link}/${item?.photo})` }}>
                        <div className='left-top' style={{ display: `${item?.discount === null ? 'none' : 'flex'}` }}>
                            <span>{`-${item?.discount}%`}</span>
                        </div>
                        <div className='right-top' style={{ display: `${item?.top ? 'flex' : 'none'}` }}>
                            <div></div>
                            <span>{t('product.lider')}</span>
                        </div>
                        <div className='left-bottom' style={{ display: `${item?.status === 'advert' ? 'flex' : 'none'}` }}>
                            <span>{t('product.advert')}</span>
                        </div>
                        <div className='left-bottom left-bottom-second' style={{ display: `${item?.status === 'new' ? 'flex' : 'none'}` }}>
                            <span>{t('product.new')}</span>
                        </div>
                        <div className='right-bottom' style={{ display: `${item?.sale?.status ? 'flex' : 'none'}` }}>
                            <span>{t('product.sale')}</span>
                        </div>
                    </div>
                    <div className='texts-center'>
                        <p>{item?.brand?.name} / {item?.name ? item?.name[`${lang}`] : ''} / {item?.partner.name} {item?.model?.name}</p>
                        <div className='rating-wrap'>
                            <Rating ratingValue={productRate * 20} readonly size={24} />
                            <p>({commentsCount} {t('product.comment')})</p>
                        </div>
                        <div>
                            <p>{t('product.kod')} <span>{item?.productCode}</span></p>
                        </div>
                        <div>
                            <p>{t('product.country')} <span>{item?.country[`${lang}`]}</span></p>
                        </div>
                        <img src={`${link}/${item?.brand?.photo}`} alt='' />
                        <Link to={`/product/${item?._id}`} onClick={() => addSeen(item._id)}>{t('product.more')}</Link>
                    </div>
                </div>
                <div className='right-nums'>
                    {
                        currency === 'UZS' ? (
                            <div className='price'>
                                <p>{(item?.price * (100 - item?.discount) / 100) ? reFormat(Math.ceil((item?.price * (100 - item?.discount)) / 100) * currencyValue) : ''} {t('cart.som')}</p>
                                <span style={{ opacity: item?.discount ? '1' : '0', color: 'red' }}>{reFormat(item?.price * currencyValue)} {t('cart.som')}</span>
                            </div>
                        ) : (
                            <div className='price'>
                                <p>{(item?.price * (100 - item?.discount) / 100) ? reFormat(Math.ceil((item?.price * (100 - item?.discount)) / 100)) : ''} $</p>
                                <span style={{ opacity: item?.discount ? '1' : '0', color: 'red' }}>{reFormat(item?.price)} $</span>
                            </div>
                        )
                    }
                    <div className='aks' style={{ display: `${item?.sale?.status ? 'flex' : 'none'}` }}>
                        <p>{t('product.time-sale')} <span>{item?.sale ? item?.sale[`${lang}`] : ''}</span></p>
                        <div>{t('product.sale')}</div>
                    </div>
                    {
                        item.master ? (
                            <div className='inp-wrap'>
                                <input type='checkbox' value={master} onChange={() => setMaster(!master)} />
                                <label>{t('product.master')}</label>
                            </div>
                        ) : null
                    }
                    <div className='num-btn-wrap'>
                        <div className='nums'>
                            <div onClick={() => num > 1 ? setNum(num - 1) : null}>-</div>
                            <input type='text' readOnly value={num} />
                            <div onClick={() => setNum(num + 1)}>+</div>
                        </div>
                        <button onClick={() => setIsModalVisible1(true)}>{t('product.fast-order')}</button>
                    </div>
                    <div className='bottom'>
                        <div className='left'>
                            <div onClick={() => addFavorites(item._id)}>
                                <AiFillHeart className='icon' />
                            </div>
                            <div onClick={() => addComparison(item._id)}>
                                <RiBarChartFill className='icon' />
                            </div>
                        </div>
                        <button onClick={() => addCart(item._id)}>
                            <BsFillCartFill className='icon' />
                            <span>{t('product.cart')}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default ProductStyle3