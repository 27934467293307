import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import ReactInputMask from 'react-input-mask'
import './profile.css'
import { useTranslation } from 'react-i18next'
import { BASE_URL } from '../../App'

const Profile = ({ rend, setRend }) => {

    const token = window.localStorage.getItem('token')

    const navigate = useNavigate()
    const { t } = useTranslation()

    const [image1, setImage1] = useState(null)
    const [imageFileUrl1, setImageFileUrl1] = useState()

    const getUrl1 = (e) => {
        const files1 = Array.from(e.target.files);
        setImageFileUrl1(URL.createObjectURL(files1[0]));
        setImage1(e.target.files);
    }

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [number, setNumber] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('')
    const [password, setPassword] = useState('')
    const [mail, setMail] = useState('')

    const [userData, setUserData] = useState()
    const getUserDatas = () => {
        axios.get('/api/user', {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setUserData(res.data.findUser)
            setFirstname(res.data.findUser.firstname)
            setLastname(res.data.findUser.lastname)
            setNumber(res.data.findUser.number)
            setGender(res.data.findUser.gender)
            setMail(res.data.findUser.email)
        }).catch(err => {
            navigate('/error')
        })
    }

    const cancel = (e) => {
        e.preventDefault()
        setFirstname(userData ? userData.firstname : '')
        setLastname(userData ? userData.lastname : '')
        setNumber(userData ? userData.number : '')
        setBirthday(userData && userData.birthday ? userData.birthday : '')
        setGender(userData && userData.gender ? userData.gender : '')
        setPassword('')
    }

    const submitHandler = (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('image', image1 ? image1[0] : '')
        formData.append('gender', gender ? gender : null)
        formData.append('password', password ? password : null)
        formData.append('birthday', birthday ? birthday : null)
        formData.append('firstname', firstname)
        formData.append('lastname', lastname)
        formData.append('number', number)
        formData.append('mail', mail)

        axios.put('/api/user', formData, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setRend(!rend)
            navigate('/')
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getUserDatas()
    }, [])

    return (
        <div className='profile-component'>
            <div className='containercha'>
                <form encType='multipart/form-data'>
                    <div className='left'>
                        <div className='bg' style={{ backgroundImage: image1 ? `url(${imageFileUrl1})` : `url(${BASE_URL}/${userData ? userData.photo : ''})` }}>
                            <input type='file' files={image1} accept='.jpg, .jpeg, .png' onChange={getUrl1} />
                            <div className='img-w'></div>
                        </div>
                        <h1>{userData ? userData.firstname : ''} {userData ? userData.lastname : ''}</h1>
                        <p>{userData ? userData.number : ''}</p>
                    </div>
                    <div className='right'>
                        <h2>{t('profile.personal')}</h2>
                        <div className='qator'>
                            <div>
                                <label>{t('cart.name')}</label>
                                <input type='text' name='firstname' value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </div>
                            <div>
                                <label>{t('cart.surname')}</label>
                                <input type='text' name='lastname' value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </div>
                        </div>
                        <div className='qator'>
                            <div>
                                <label>{t('cart.phone')}</label>
                                <ReactInputMask type="text" name="number" mask="+\9\9\8999999999" value={number} onChange={(e) => setNumber(e.target.value)} />
                            </div>
                            <div>
                                <label>{t('profile.birthday')}</label>
                                <input type='date' value={birthday} onChange={(e) => setBirthday(e.target.value)} />
                            </div>
                        </div>
                        <div className='qator'>
                            <div>
                                <label>{t('profile.password')}</label>
                                <input type='password' placeholder='*****' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div>
                                <label>Email:</label>
                                <input type='email' placeholder='Email' value={mail} onChange={(e) => setMail(e.target.value)} />
                            </div>
                        </div>
                        <div className='radio-wrap'>
                            <input type='radio' id='man' value='erkak' checked={gender === 'erkak' ? true : false} onChange={(e) => setGender(e.target.value)} />
                            <label htmlFor='man'>{t('profile.male')}</label>
                        </div>
                        <div className='radio-wrap'>
                            <input type='radio' id='woman' value='ayol' checked={gender === 'ayol' ? true : false} onChange={(e) => setGender(e.target.value)} />
                            <label htmlFor='woman'>{t('profile.female')}</label>
                        </div>
                        <div className='btn-wrap'>
                            <button type='submit' onClick={submitHandler}>{t('profile.save')}</button>
                            <button onClick={cancel}>{t('profile.cancel')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Profile