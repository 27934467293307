import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './contact.css'
import ReactInputMask from 'react-input-mask'
import SellingModal from '../selling/SellingModal'

const Contact = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [callCenter, setCallCenter] = useState('')
    const [salesDepartment, setSalesDepartment] = useState('')
    const [number, setNumber] = useState('')

    const getInfo = () => {
        axios.get('/api/info').then(res => {
            setNumber(res.data.info[0] ? res.data.info[0].number : '')
            setCallCenter(res.data.info[0] ? res.data.info[0].callcentre : '')
            setSalesDepartment(res.data.info[0] ? res.data.info[0].salesdepartment : '')
        }).catch(err => {
            navigate('/error')
        })
    }

    const [showModal, setShowModal] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [text, setText] = useState('')

    const submmitContact = (e) => {
        e.preventDefault()

        let token = '6515123647:AAHE2lypLrRMLIXP9ktG0kb9-fErwWbpoQw';
        let chatID = '-4000345079';

        let message = `<b>📬 Online ariza</b>  %0A<b>👤 Ismi: </b><i>${firstname}</i>  %0A<b>👤 Familiyasi: </b><i>${lastname}</i>  %0A<b>📞 Tel. raqami: </b><i>${phone}</i>  %0A<b>📧 Email: </b><i>${email}</i>  %0A<b>📝 Text: </b><i>${text}</i>`;
        let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatID}&text=${message}&parse_mode=html`;

        let apibot = new XMLHttpRequest();
        apibot.open("GET", url, true);
        apibot.send();
        // alert(`So'rovingiz muvaffaqqiyatli jo'natildi`);
        setShowModal(true)

        setFirstname('')
        setLastname('')
        setPhone('')
        setEmail('')
        setText('')
    }

    useEffect(() => {
        getInfo()
    }, [])

    return (
        <div className='contact-component'>
            <SellingModal showModal={showModal} setShowModal={setShowModal} />
            <div className='containercha'>
                <h1>{t('footer.contact')}</h1>
                <div className='numbers-wrapper'>
                    <div className='sq'>
                        <p>{t('contact.call-center')}</p>
                        <a href={`tel:${callCenter}`}>{callCenter}</a>
                        <span>{t('contact.every-day')}</span>
                    </div>
                    <div className='sq'>
                        <p>{t('contact.service-center')}</p>
                        <a href={`tel:${number}`}>{number}</a>
                        <span>{t('contact.every-day')}</span>
                    </div>
                    <div className='sq'>
                        <p>{t('contact.savdo')}</p>
                        <a href={`tel:${salesDepartment}`}>{salesDepartment}</a>
                        <span>{t('contact.every-day')}</span>
                    </div>
                </div>

                <h2>{t('contact.suggestions')}</h2>
                <h2>{t('contact.write')}</h2>

                <div className='form-map'>
                    <form onSubmit={submmitContact}>
                        <div className='qator'>
                            <div>
                                <label>{t('cart.name')}</label>
                                <input type='text' value={firstname} onChange={e => setFirstname(e.target.value)} required />
                            </div>
                            <div>
                                <label>{t('cart.surname')}</label>
                                <input type='text' value={lastname} onChange={e => setLastname(e.target.value)} required />
                            </div>
                        </div>
                        <div className='qator'>
                            <div>
                                <label>{t('cart.phone')}</label>
                                <ReactInputMask maskChar={null} name="phone" mask="+\9\9\8999999999" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                                {/* <input type='text' value={phone} onChange={e => setPhone(e.target.value)} required /> */}
                            </div>
                            <div>
                                <label>{t('cart.email')}</label>
                                <input type='email' value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='textarea-w'>
                            <label>{t('cart.sms')}</label>
                            <textarea value={text} onChange={e => setText(e.target.value)} required></textarea>
                        </div>
                        <div className='btn-w'>
                            <button type='submit'>{t('cart.sen')}</button>
                        </div>
                    </form>
                    <div className='map'>
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23963.387922477363!2d69.26661777005918!3d41.34314803617036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b454cf51035%3A0xc1f97becd64cf681!2sMinor%20Mosque!5e0!3m2!1sen!2s!4v1650014445220!5m2!1sen!2s" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact