import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { RiSearchLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { TiArrowSortedUp } from 'react-icons/ti'
import { Accordion } from 'react-bootstrap'
import { Slider } from 'antd'
import './mobileFilter.css'

const MobileFilter = (props) => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const { mobFilter, setMobFilter, searchEndCategory, searchedCategories, setMainCategoryId,
        allCategories, getSubCategories, allSubCategories, getSubSubCategories, allSubSubCategories, setCategoryId, setStatusDiscTire, priceShow,
        setPriceShow, reFormat, rangeValue1, rangeValue2, onChange, tireShow, statusDiscTire, setTireShow, tireDiametrValue, setTireDiametrValue,
        tireDiametr, tireWidthValue, setTireWidthValue, tireWidth, tireHeightValue, setTireHeightValue, tireHeight, sezonShow, setSezonShow, setSeasonValue,
        texShow, setTexShow, techValue, setTechValue, tipShow, setTipShow, setTypeTireValue, discShow, setDiscShow, discDiametrValue, setDiscDiametrValue,
        discDiametr, widthDiscValue, setWidthDiscValue, widthDisc, spaceDiscValue, setSpaceDiscValue, spaceDisc, vyletDiscValue, setVyletDiscValue,
        vyletDisc, hubDiametrValue, setHubDiametrValue, hubDiametr, countDiscValue, setCountDiscValue, countDisc, typeDiscShow, setTypeDiscShow,
        setDiscTypeValue, discountShow, setDiscountShow, setDiscount, markaShow, setMarkaShow, allPartners, getModels, allModels, setModelId, countryShow,
        setCountryShow, setCountryId, allCountries, brandShow, setBrandShow, brandName, setBrandName, searchBrand, searchEndBrand, searchedBrands,
        setBrandId, allBrands, clearFilter, checkCountriesId, allCountriesId
    } = props

    return (
        <div className={mobFilter ? 'mobile-filter-component mobile-filter-component-active' : 'mobile-filter-component'}>
            <div className='toppp'>
                <h2>{t('product.filter')}</h2>
                <MdClose className='icon' onClick={() => setMobFilter(false)} />
            </div>
            <div className='left-filters'>
                {/* FILTER BY CATEGORY */}
                <div className='by-category'>
                    <h3>{t('product.category')}</h3>
                    <div className={searchedCategories.length > 0 ? 'searched' : 'searched not-searched'}>
                        {
                            searchedCategories && Array.isArray(searchedCategories) ? searchedCategories.map((item, index) => {
                                return (
                                    <div key={index} onClick={() => { setMainCategoryId(item._id); searchEndCategory(); setCategoryId(null) }}>{item.name[`${lang}`]}</div>
                                )
                            }) : ''
                        }
                    </div>
                    {/* main category */}
                    <Accordion>
                        {
                            allCategories && Array.isArray(allCategories) ? allCategories.map((item, index) => {
                                return (
                                    <Accordion.Item eventKey={index} key={index} onClick={() => getSubCategories(item._id)}>
                                        <Accordion.Header>{item.name[`${lang}`]}</Accordion.Header>
                                        <Accordion.Body className='main-body'>
                                            {/* sub category */}
                                            <Accordion>
                                                {
                                                    allSubCategories ? allSubCategories.map((item1, index1) => {
                                                        return (
                                                            <Accordion.Item eventKey={index1} key={index1} onClick={() => getSubSubCategories(item1._id)} >
                                                                <Accordion.Header>{item1.name[`${lang}`]}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    {/* sub sub category */}
                                                                    {
                                                                        allSubSubCategories ? allSubSubCategories.map((item2, index2) => {
                                                                            return (
                                                                                <p key={index2} onClick={() => { setCategoryId(item2._id); setStatusDiscTire(item2.status); setMainCategoryId(null) }}>{item2.name[`${lang}`]}</p>
                                                                            )
                                                                        }) : ''
                                                                    }
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        )
                                                    }) : ''
                                                }
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }) : ''
                        }
                    </Accordion>
                </div>

                {/* FILTER BY PRICE */}
                <div className={priceShow ? 'by-price' : 'by-price by-price-false'}>
                    <div className='top'>
                        <h6>{t('product.price')}</h6>
                        <div className='icon-wrap' onClick={() => setPriceShow(!priceShow)}>
                            <TiArrowSortedUp className={priceShow ? 'icon' : 'icon icon-false'} />
                        </div>
                    </div>
                    <div className='prices'>
                        <div>{reFormat(rangeValue1)} {t('cart.som')}</div>
                        <span>-</span>
                        <div>{reFormat(rangeValue2)} {t('cart.som')}</div>
                    </div>
                    <div className='range-w'>
                        <Slider
                            range={{
                                draggableTrack: true,
                            }}
                            defaultValue={[0, 100]}
                            onChange={onChange}
                        />
                    </div>
                </div>

                {/* FILTER BY TIRE */}
                <div className={tireShow ? 'by-tire' : 'by-tire by-tire-false'} style={{ display: statusDiscTire === 'tire' ? 'block' : 'none' }}>
                    <div className='top'>
                        <h6>{t('product.size-tire')}</h6>
                        <div className='icon-wrap' onClick={() => setTireShow(!tireShow)}>
                            <TiArrowSortedUp className={tireShow ? 'icon' : 'icon icon-false'} />
                        </div>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.diametr-tire')}</label>
                        <select value={tireDiametrValue} onChange={(e) => setTireDiametrValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                tireDiametr ? tireDiametr.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.width-tire')}</label>
                        <select value={tireWidthValue} onChange={(e) => setTireWidthValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                tireWidth ? tireWidth.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.height-tire')}</label>
                        <select value={tireHeightValue} onChange={(e) => setTireHeightValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                tireHeight ? tireHeight.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>

                    <div className={sezonShow ? 'by-sezon' : 'by-sezon by-sezon-false'}>
                        <div className='top'>
                            <h6>{t('product.sezon')}</h6>
                            <div className='icon-wrap' onClick={() => setSezonShow(!sezonShow)}>
                                <TiArrowSortedUp className={sezonShow ? 'icon' : 'icon icon-false'} />
                            </div>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='season' id='yoz' value='summer' onChange={(e) => setSeasonValue(e.target.value)} />
                            <label htmlFor='yoz'>{t('product.yoz')}</label>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='season' id='qish' value='winter' onChange={(e) => setSeasonValue(e.target.value)} />
                            <label htmlFor='qish'>{t('product.qish')}</label>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='season' id='qish-w' value='spikes' onChange={(e) => setSeasonValue(e.target.value)} />
                            <label htmlFor='qish-w'>{t('product.qish-w')}</label>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='season' id='all' value='all' onChange={(e) => setSeasonValue(e.target.value)} />
                            <label htmlFor='all'>{t('product.all-sezon')}</label>
                        </div>
                    </div>

                    <div className={texShow ? 'by-tex' : 'by-tex by-tex-false'}>
                        <div className='top'>
                            <h6>{t('product.tech')}</h6>
                            <div className='icon-wrap' onClick={() => setTexShow(!texShow)}>
                                <TiArrowSortedUp className={texShow ? 'icon' : 'icon icon-false'} />
                            </div>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='checkbox' id='runflat' checked={techValue} value={techValue} onChange={() => setTechValue(!techValue)} />
                            <label htmlFor='runflat'>RunFlat</label>
                        </div>
                    </div>

                    <div className={tipShow ? 'by-sezon' : 'by-sezon by-sezon-false'}>
                        <div className='top'>
                            <h6>{t('product.type')}</h6>
                            <div className='icon-wrap' onClick={() => setTipShow(!tipShow)}>
                                <TiArrowSortedUp className={tipShow ? 'icon' : 'icon icon-false'} />
                            </div>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='tire-type' id='avto' value='cars' onChange={(e) => setTypeTireValue(e.target.value)} />
                            <label htmlFor='avto'>{t('product.avto')}</label>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='tire-type' id='no-way' value='offroad' onChange={(e) => setTypeTireValue(e.target.value)} />
                            <label htmlFor='no-way'>{t('product.no-way')}</label>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='tire-type' id='sport' value='sport' onChange={(e) => setTypeTireValue(e.target.value)} />
                            <label htmlFor='sport'>{t('product.sport')}</label>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='tire-type' id='commer' value='commercial' onChange={(e) => setTypeTireValue(e.target.value)} />
                            <label htmlFor='commer'>{t('product.com')}</label>
                        </div>
                    </div>

                </div>

                {/* FILTER BY DISC */}
                <div className={discShow ? 'by-tire' : 'by-tire by-tire-false'} style={{ display: statusDiscTire === 'disc' ? 'block' : 'none' }}>
                    <div className='top'>
                        <h6>{t('product.size-disk')}</h6>
                        <div className='icon-wrap' onClick={() => setDiscShow(!discShow)}>
                            <TiArrowSortedUp className={discShow ? 'icon' : 'icon icon-false'} />
                        </div>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.diametr-disk')}</label>
                        <select value={discDiametrValue} onChange={(e) => setDiscDiametrValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                discDiametr ? discDiametr.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.width-disk')}</label>
                        <select value={widthDiscValue} onChange={(e) => setWidthDiscValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                widthDisc ? widthDisc.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.bolt')}</label>
                        <select value={spaceDiscValue} onChange={(e) => setSpaceDiscValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                spaceDisc ? spaceDisc.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.vilet')}</label>
                        <select value={vyletDiscValue} onChange={(e) => setVyletDiscValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                vyletDisc ? vyletDisc.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.center-disk')}</label>
                        <select value={hubDiametrValue} onChange={(e) => setHubDiametrValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                hubDiametr ? hubDiametr.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>
                    <div className='inp-wrap'>
                        <label>{t('product.number-fasten')}</label>
                        <select value={countDiscValue} onChange={(e) => setCountDiscValue(e.target.value)}>
                            <option hidden value={null}>{t('place-order.not-selected')}</option>
                            {
                                countDisc ? countDisc.map((item, index) => {
                                    return (
                                        <option key={index} value={item}>{item}</option>
                                    )
                                }) : ''
                            }
                        </select>
                    </div>

                    <div className={typeDiscShow ? 'by-sezon' : 'by-sezon by-sezon-false'}>
                        <div className='top'>
                            <h6>{t('product.type-disk')}</h6>
                            <div className='icon-wrap' onClick={() => setTypeDiscShow(!typeDiscShow)}>
                                <TiArrowSortedUp className={typeDiscShow ? 'icon' : 'icon icon-false'} />
                            </div>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='type-disc' id='cast' value='cast' onChange={(e) => setDiscTypeValue(e.target.value)} />
                            <label htmlFor='cast'>{t('product.cast')}</label>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='type-disc' id='stamped' value='stamped' onChange={(e) => setDiscTypeValue(e.target.value)} />
                            <label htmlFor='stamped'>{t('product.stamped')}</label>
                        </div>
                        <div className='checkbox-wrap'>
                            <input type='radio' name='type-disc' id='forged' value='forged' onChange={(e) => setDiscTypeValue(e.target.value)} />
                            <label htmlFor='forged'>{t('product.forged')}</label>
                        </div>
                    </div>
                </div>

                {/* FILTER BY DISCOUNT */}
                <div className={discountShow ? 'by-discount' : 'by-discount by-discount-false'}>
                    <div className='top'>
                        <h6>{t('product.discount')}</h6>
                        <div className='icon-wrap' onClick={() => setDiscountShow(!discountShow)}>
                            <TiArrowSortedUp className={discountShow ? 'icon' : 'icon icon-false'} />
                        </div>
                    </div>
                    <div className='checkbox-wrap mt-3'>
                        <input type='radio' name='discount' id='all-discount' value={1} onChange={(e) => setDiscount(1)} />
                        <label htmlFor='all-discount'>{t('product.all')}</label>
                    </div>
                    <div className='checkbox-wrap'>
                        <input type='radio' name='discount' id='5' value={5} onChange={(e) => setDiscount(5)} />
                        <label htmlFor='5'>{t('product.5')}</label>
                    </div>
                    <div className='checkbox-wrap'>
                        <input type='radio' name='discount' id='10' value={10} onChange={(e) => setDiscount(10)} />
                        <label htmlFor='10'>{t('product.10')}</label>
                    </div>
                    <div className='checkbox-wrap'>
                        <input type='radio' name='discount' id='15' value={15} onChange={(e) => setDiscount(15)} />
                        <label htmlFor='15'>{t('product.15')}</label>
                    </div>
                    <div className='checkbox-wrap'>
                        <input type='radio' name='discount' id='20' value={20} onChange={(e) => setDiscount(20)} />
                        <label htmlFor='20'>{t('product.20')}</label>
                    </div>
                </div>

                {/* FILTER BY MARKA */}
                <div className={markaShow ? 'by-marka' : 'by-marka by-marka-false'}>
                    <div className='top'>
                        <h6>{t('product.marka')}</h6>
                        <div className='icon-wrap' onClick={() => setMarkaShow(!markaShow)}>
                            <TiArrowSortedUp className={markaShow ? 'icon' : 'icon icon-false'} />
                        </div>
                    </div>
                    <Accordion>
                        {
                            allPartners && Array.isArray(allPartners) ? allPartners.map((item, index) => {
                                return (
                                    <Accordion.Item eventKey={index} key={index} onClick={() => getModels(item._id)}>
                                        <Accordion.Header>{item.name}</Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                allModels && Array.isArray(allModels) ? allModels.map((item1, index1) => {
                                                    return (
                                                        <p key={index1} onClick={() => setModelId(item1._id)}>{item1.name}</p>
                                                    )
                                                }) : ''
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }) : ""
                        }
                    </Accordion>
                </div>

                {/* FILTER BY COUNTRY */}
                <div className={countryShow ? 'by-country' : 'by-country by-country-false'}>
                    <div className='top'>
                        <h6>{t('product.mamlakat')}</h6>
                        <div className='icon-wrap' onClick={() => setCountryShow(!countryShow)}>
                            <TiArrowSortedUp className={countryShow ? 'icon' : 'icon icon-false'} />
                        </div>
                    </div>

                    {
                        allCountries && Array.isArray(allCountries) ? allCountries.map((item, index) => {
                            return (
                                <div className='checkbox-wrap' key={index}>
                                    <input type='checkbox' name='country' id={item._id[`${lang}`]} checked={allCountriesId?.includes(item._id[`${lang}`])} value={item._id[`${lang}`]} onChange={(e) => checkCountriesId(e.target.value)} />
                                    <label htmlFor={item._id[`${lang}`]}>{item._id[`${lang}`]}</label>
                                </div>
                            )
                        }) : ''
                    }
                </div>

                {/* FILTER BY BRANDS */}
                <div className={brandShow ? 'by-brand' : 'by-brand by-brand-false'}>
                    <div className='top'>
                        <h6>{t('product.brands')}</h6>
                        <div className='icon-wrap' onClick={() => setBrandShow(!brandShow)}>
                            <TiArrowSortedUp className={brandShow ? 'icon' : 'icon icon-false'} />
                        </div>
                    </div>
                    <div className='search-wrap mt-3'>
                        <RiSearchLine className='icon' />
                        <input type='text' value={brandName} onChange={(e) => { setBrandName(e.target.value); searchBrand() }} placeholder={t('product.search')} />
                        <MdClose className={brandName.length > 0 ? 'iconn iconn-show' : 'iconn'} onClick={searchEndBrand} />
                    </div>
                    <div className={searchedBrands.length > 0 ? 'searched' : 'searched not-searched'}>
                        {
                            searchedBrands && Array.isArray(searchedBrands) ? searchedBrands.map((item, index) => {
                                return (
                                    <div key={index} onClick={() => { setBrandId(item._id); searchEndBrand() }}>{item.name}</div>
                                )
                            }) : ''
                        }
                    </div>

                    <div className='checkbox-wrap'>
                        <input type='radio' name='brand' id='all-brands' value='all' onChange={(e) => setBrandId(e.target.value)} />
                        <label htmlFor='all-brands'>{t('product.all')}</label>
                    </div>
                    {
                        allBrands && Array.isArray(allBrands) ? allBrands.map((item, index) => {
                            return (
                                <div key={index} className='checkbox-wrap'>
                                    <input type='radio' name='brand' id={item.name} value={item._id} onChange={(e) => setBrandId(e.target.value)} />
                                    <label htmlFor={item.name}>{item.name}</label>
                                </div>
                            )
                        }) : ''
                    }
                </div>

                <button className='reset-filter' onClick={clearFilter}>{t('product.clear-filter')}</button>

            </div>
        </div>
    )
}

export default MobileFilter