import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { Link, useNavigate } from 'react-router-dom'
import undovPng from '../../img/undov.png'
import axios from 'axios'
import './placeOrder.css'
import SuccessOrderModal from '../fast-view/SuccessOrderModal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const districts = require('./districts.json')
const regions = require('./regions.json')

const PlaceOrder = () => {

    const token = window.localStorage.getItem('token')

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const [ AllProducts, setAllProducts ] = useState([])
    const [ firstname, setFirstname ] = useState('')
    const [ lastname, setLastname ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ region, setRegion ] = useState(null)
    const [ city, setCity ] = useState([])
    const [ address, setAddress ] = useState('')
    const [ showModal, setShowModal ] = useState(false)

    useEffect(() => {
        setAllProducts(JSON.parse(window.localStorage.getItem('order')))
    }, [])


    const navigate = useNavigate()

    const [ orderId, setOrderId ] = useState('')
    const submitOrder = (e) => {
        e.preventDefault()

        const regionName = regions.find((r) => r.region_id === region)?.name;
        const districtName = districts.filter((d) => d.region_id === region)[city]?.name;

        const formData = {
            firstname,
            lastname,
            phone,
            region: regionName,
            district: districtName,
            address,
            totalCount: AllProducts.totalCount,
            totalDiscount: AllProducts.totalDiscount,
            totalPrice: AllProducts.totalPrice,
            totalMaster: AllProducts.totalMaster,
            products: AllProducts ? AllProducts.products : ''
        }

        axios.post('/api/order', formData, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setOrderId(res.data.orderId)
            setShowModal(true)
            window.localStorage.removeItem('order')
            setFirstname('')
            setLastname('')
            setPhone('')
            setRegion('')
            setCity('')
            setAddress('')
        }).catch(err => {
            navigate('/error')
        })
    }

    const [ allDistricts, setAllDistricts ] = useState([])
    const getDistricts = (id) => {
        setAllDistricts(districts.filter(el => el.region_id === id))
    }

    const regionn = regions.find((r) => r.region_id === region)?.region_id;

    return (
        <div className='place-order-component'>
            <div className='containercha'>

                <SuccessOrderModal 
                    setShowModal={setShowModal}
                    showModal={showModal}
                    orderId={orderId}
                />

                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/cart'>{t('navbar.cart')}</Link> / <span>{t('place-order.confirm')}</span></p>
                </div>
                <h1>{t('place-order.confirm')}</h1>
                <form onSubmit={submitOrder}>
                    <div className='qator'>
                        <div>
                            <label>{t('cart.name')}</label>
                            <input type='text' value={firstname} onChange={(e) => setFirstname(e.target.value)} required />
                        </div>
                        <div>
                            <label>{t('cart.surname')}</label>
                            <input type='text' value={lastname} onChange={(e) => setLastname(e.target.value)} required />
                        </div>
                    </div>
                    <div className='qator'>
                        <div>
                            <label>{t('cart.phone')}</label>
                            <ReactInputMask maskChar={null} name="phone" mask="+\9\9\8999999999" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </div>
                        <div>
                            <label>{t('place-order.region')}</label>
                            <select value={regionn} onChange={(e) => {setRegion(e.target.value); getDistricts(e.target.value)}}>
                                <option value={null} hidden>{t('place-order.not-selected')}</option>
                                {
                                    regions.map((item, index) => {
                                        return (
                                            <option key={index} id={item.region_id} value={item.region_id}>{item.name[`${lang}`]}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='qator'>
                        <div>
                            <label>{t('place-order.district')}</label>
                            <select value={city} multiple={false} onChange={(e) => setCity(e.target.value)}>
                                <option value={null} hidden>{t('place-order.not-selected')}</option>
                                {
                                    allDistricts && Array.isArray(allDistricts) ? allDistricts.map((item, index) => {
                                        return (
                                            <option key={index} value={index}>{item.name[`${lang}`]}</option>
                                        )
                                    }) : ''
                                }
                            </select>
                        </div>
                        <div>
                            <label>{t('place-order.address')}</label>
                            <input type='text' value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </div>
                    </div>
                    <div className='last-qator'>
                        <div className='left'>
                            <img src={undovPng} alt='' />
                            <p>{t('cart.operator')}</p>
                        </div>
                        <div className='right'>
                            <button>{t('place-order.button')}</button>
                            <p>{t('place-order.accept')}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PlaceOrder