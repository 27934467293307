import { combineReducers, createStore } from 'redux'
import LanguageReducers from './reducers/Reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import CurrencyReducers from './reducers/CurrencyReducer'

const rootReducers = combineReducers({
    nowLanguage: LanguageReducers,
    currencyReducer: CurrencyReducers
})

const store = createStore(rootReducers, composeWithDevTools())

export default store

