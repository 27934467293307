import React from 'react'
import makeOrderPng from '../../img/makeOrder.png'
import { Link } from 'react-router-dom'
import './makeOrder.css'
import { useTranslation } from 'react-i18next'

const MakeOrder = () => {

    const { t } = useTranslation()

    return (
        <div className='make-order-component'>
            <div className='containercha'>
                <img src={makeOrderPng} alt='' className='banner' />
                <h1>{t('make-order.h1')}</h1>

                <div>
                    <h2>{t('make-order.h2-1')}</h2>
                    <p>{t('make-order.p1')}</p>
                    <p>{t('make-order.p2')}</p>
                    <Link to='/terms-of-use'>
                        <p>{t('make-order.p3')}</p>
                    </Link>
                    <p>{t('make-order.p4')}</p>
                    <p>{t('make-order.p5')}</p>
                    <p>{t('make-order.p6')}</p>
                    <p><span>{t('make-order.p7-span')}</span> {t('make-order.p7')}</p>
                    <p>{t('make-order.p8')}</p>
                    <p><span>{t('make-order.p9-span')}</span> {t('make-order.p9')}</p>
                    <p><span>{t('make-order.p10-span')}</span> {t('make-order.p10')}</p>
                    <p><span>{t('make-order.p11-span')}</span> {t('make-order.p11')}</p>
                    <p>{t('make-order.p12')}</p>
                    <h2>{t('make-order.h2-2')}</h2>
                </div>
            </div>
        </div>
    )
}

export default MakeOrder