import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactInputMask from 'react-input-mask'
import { Link, useNavigate } from 'react-router-dom'
import './login.css'

const Register = ({ rend, setRend, setUserMenu, getUserData }) => {

    const navigate = useNavigate()

    const { t } = useTranslation()

    const [number, setNumber] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')

    const submitNumber = (e) => {
        e.preventDefault()

        axios.post('/api/auth/registration', { number, password, firstname, lastname }).then(res => {
            window.localStorage.setItem('token', res.data.token)
            window.localStorage.removeItem('carts')
            setUserMenu(false)
            navigate('/')
            getUserData()
            setRend(!rend)
        }).catch(err => {
            // navigate('/error')
        })
    }

    return (
        <div className='login-component'>
            <div className='containercha'>
                <div className='square'>
                    <h2>{t('login.login')}</h2>
                    <p>{t('login.text')}</p>
                    <form className='input-wrap' onSubmit={submitNumber}>
                        <div>
                            <label>{t('cart.name')}</label>
                            <input type='text' name="firstname" required value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                        </div>
                        <div>
                            <label>{t('cart.surname')}</label>
                            <input type='text' name="lastname" required value={lastname} onChange={(e) => setLastname(e.target.value)} />
                        </div>
                        <div>
                            <label>{t('cart.phone')}</label>
                            <ReactInputMask maskChar={null} name="phone" required mask="+\9\9\8999999999" value={number} onChange={(e) => setNumber(e.target.value)} />
                        </div>
                        <div>
                            <label>{t('cart.password')}</label>
                            <input type='password' name="password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                            <Link to='/login'>{t('login.kirish')}</Link>
                        </div>
                        <button type='submit' className='active-btn'>{t('login.register')}</button>
                    </form>
                    <span>{t('login.log1')}</span>
                </div>
            </div>
        </div>
    )
}

export default Register