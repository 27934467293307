import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillDelete } from 'react-icons/ai'
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Empty from '../empty/Empty'
import Loader from '../loader/Loader'
import './comparison.css'
import OneComparisonProduct from './OneComparisonProduct'

const Comparison = ({ link, getComparisonCount, addCart }) => {

    const token = window.localStorage.getItem('token')

    const navigate = useNavigate()

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)
    const { currency } = useSelector(state => state.currencyReducer)

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course?.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [showEmpty, setShowEmpty] = useState(false)
    const [emptyImg, setEmptyImg] = useState("comparison")
    const [emptyH3, setEmptyH3] = useState("comparison")

    const [loading1, setLoading1] = useState(true)
    const loader1 = loading1 ? <Loader /> : null

    const [count, setCount] = useState()
    const [allComparison, setAllComparison] = useState([])

    const getAllComparison = () => {
        if (token) {
            axios.get("/api/contrast", {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }).then(res => {
                if (res.data.count > 0) {
                    setShowEmpty(false)
                } else {
                    setShowEmpty(true)
                }
                setEmptyImg("comparison")
                setEmptyH3("comparison")
                setCount(res.data.count)
                setLoading1(false)
                setAllComparison(res.data.contrasts)
            }).catch(err => {
                setEmptyImg("comparison")
                setEmptyH3("comparison")
                setCount(0)
                setShowEmpty(true)
                setLoading1(false)
                navigate('/error')
            })
        } else {
            setEmptyImg("comparison")
            setEmptyH3("comparison")
            setCount(0)
            setShowEmpty(true)
            setLoading1(false)
        }
    }

    const deleteComparison = (id) => {
        axios.delete(`/api/contrast/${id}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            getAllComparison()
            getComparisonCount()
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getAllComparison()
        getCurrencyValue()
        //eslint-disable-next-line
    }, [])

    const scroll = (scrollOffset) => {
        let all = document.querySelector('.all-products');
        all.scrollLeft += scrollOffset;
    };

    return (
        <div className='comparison-component'>
            <div className='containercha'>
                <div className='top'>
                    <h1>{t('comparison.comparison-product')}</h1>
                    <p>{t('cart.product')} <span>({count} {t('cart.ta')})</span></p>
                </div>

                {<Empty showEmpty={showEmpty} emptyImg={emptyImg} emptyH3={emptyH3} />}
                {loader1}

                {
                    count ? (
                        <div className='next-prev'>
                            <div className='prev-comp arrow-comp' onClick={() => scroll(-200)}>
                                <MdOutlineArrowBackIos className='icon' />
                            </div>
                            <div className='next-comp arrow-comp' onClick={() => scroll(200)}>
                                <MdOutlineArrowForwardIos className='icon' />
                            </div>
                        </div>
                    ) : null
                }

                <div className='wrapper' style={{ display: count === 0 ? 'none' : 'flex' }}>
                    <div className='left-col'>
                        <div className='for-product'></div>
                        <div className='text'>
                            <p>{t('product.kod')}</p>
                        </div>
                        <div className='text'>
                            <p>{t('comparison.country')}</p>
                        </div>
                        <div className='text'>
                            <p>{t('comparison.rating')}</p>
                        </div>
                        {/* <div className='text'>
                            <p>{t('comparison.ves')}</p>
                        </div>
                        <div className='text'>
                            <p>{t('comparison.size')}</p>
                        </div> */}
                        <div className='text'>
                            <p>{t('product.kod')}</p>
                        </div>
                    </div>

                    <div className='all-products'>
                        {
                            allComparison && Array.isArray(allComparison) ? allComparison.map((item, index) => {
                                return (
                                    <OneComparisonProduct
                                        item={item}
                                        key={index}
                                        link={link}
                                        lang={lang}
                                        currency={currency}
                                        deleteComparison={deleteComparison}
                                        currencyValue={currencyValue}
                                        addCart={addCart}
                                    />
                                )
                            }) : ''
                        }

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Comparison