import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { BiEditAlt } from 'react-icons/bi'
import { HiOutlineLogout } from 'react-icons/hi'
import { IoIosArrowForward } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader'
import './myMessages.css'
import { useTranslation } from 'react-i18next'
import { BASE_URL } from '../../App'
import { useSelector } from 'react-redux'

const MyMessages = ({ link, userData, setRend, rend, setFavoritesCount, setCartCount, setComparisonCount }) => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const token = window.localStorage.getItem('token')

    const [loading1, setLoading1] = useState(true)
    const loader1 = loading1 ? <Loader /> : null

    const navigate = useNavigate()
    const logout = () => {
        window.localStorage.removeItem('token')
        navigate('/')
        setRend(!rend)
        setFavoritesCount(0)
        setCartCount(0)
        setComparisonCount(0)
    }

    const [allMessages, setAllMessages] = useState([])
    const getMessages = () => {
        axios.get('/api/notification', {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setAllMessages(res.data.notifications)
            setLoading1(false)
        }).catch(err => {
            navigate('/error')
            setLoading1(false)
        })
    }

    const editStatus = (id) => {
        axios.post(`/api/unread`, { message: id }, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            // console.log(res.data)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getMessages()
    }, [])

    return (
        <div className='my-sms-component'>
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/profile'>{t('home.profile')}</Link> / <span>{t('profile.sms')}</span></p>
                </div>

                <div className='wrapper'>
                    <div className='left-user-menu'>
                        <div className='user-top'>
                            <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
                            <div className='name-phone'>
                                <h4>{userData ? userData.firstname : ''} {userData ? userData.lastname : ''}</h4>
                                <p>{userData ? userData.number : ''}</p>
                            </div>
                            <Link to='/profile' className='icon-wrap'>
                                <BiEditAlt className='icon' />
                            </Link>
                        </div>
                        <div className='links-wrapper'>
                            <Link to='/my-orders'>
                                <h5>{t('profile.my-orders')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-address'>
                                <h5>{t('profile.my-address')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-messages'>
                                <h5>{t('profile.sms')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-comments'>
                                <h5>{t('profile.my-comments')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/favorites'>
                                <h5>{t('navbar.favorites')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/comparison'>
                                <h5>{t('profile.comparison-list')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                        </div>
                        <div className='logout-wrap' style={{ cursor: 'pointer' }} onClick={logout}>
                            <HiOutlineLogout className='icon' />
                            <h5>{t('profile.logout')}</h5>
                        </div>
                    </div>

                    <div className='right-layout'>
                        <h1>{t('profile.sms')}</h1>
                        <Row className='all-messages'>
                            {loader1}
                            {
                                allMessages && Array.isArray(allMessages) ? allMessages.map((item, index) => {

                                    const date = new Date(JSON.parse(JSON.stringify(item.createdAt)))

                                    let descr = ''
                                    let continueDescr = ''

                                    for (let i = 0; i < item.text[`${lang}`].length; i++) {
                                        if (i < 200) {
                                            descr += item.text[`${lang}`][i]
                                        } else {
                                            continueDescr = '...'
                                            break
                                        }
                                    }
                                    return (
                                        <Col key={index} lg={12} md={12} sm={12} xs={12} onClick={() => !item.isRead ? editStatus(item.notification) : null}>
                                            <Link to={`/my-messages/${item.notification}`} className={item.isRead ? 'message' : 'unred-message message'}>
                                                <div className='bg' style={{ backgroundImage: `url(${link}/${item.image})` }}></div>
                                                <div className='texts'>
                                                    <h4>{item.title[`${lang}`]}</h4>
                                                    <p>{descr}{continueDescr}</p>
                                                </div>
                                                <div className='date'>
                                                    <span>{date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}.{(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}.{date.getFullYear()}</span>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                }) : ''
                            }
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyMessages