import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaFacebookF, FaInstagram, FaTelegramPlane, FaYoutube } from 'react-icons/fa'
import { BsTelephonePlus } from 'react-icons/bs'
import { HiOutlineMail } from 'react-icons/hi'
import { Collapse } from 'antd'

import logoPng from '../../img/logo.png'
import clickPng from '../../img/click.png'
import paymePng from '../../img/payme.png'
import humoPng from '../../img/humo.png'
import uzcardPng from '../../img/uzcard.png'
import visaPng from '../../img/visa.png'

import './footer.css'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Footer = (props) => {

    const { Panel } = Collapse
    const { link } = props
    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    const [haveLogo, setHaveLogo] = useState()
    const [logoImg, setLogoImg] = useState('')
    const getLogo = () => {
        axios.get('/api/logo').then(res => {
            setLogoImg(res.data.logo[0] ? res.data.logo[0].image : '')
            setHaveLogo(res.data.logo[0] ? true : false)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [have, setHave] = useState()
    const [email, setEmail] = useState('')
    const [facebook, setFacebook] = useState('')
    const [instagram, setInstagram] = useState('')
    const [telegram, setTelegram] = useState('')
    const [youtube, setYoutube] = useState('')
    const [number, setNumber] = useState('')
    const getInfo = () => {
        axios.get('/api/info').then(res => {
            setEmail(res.data.info[0] ? setHave(true) : setHave(false))
            setEmail(res.data.info[0] ? res.data.info[0].email : '')
            setFacebook(res.data.info[0] ? res.data.info[0].facebook : '/')
            setInstagram(res.data.info[0] ? res.data.info[0].instagram : '/')
            setTelegram(res.data.info[0] ? res.data.info[0].telegram : '/')
            setYoutube(res.data.info[0] ? res.data.info[0].youtube : '/')
            setNumber(res.data.info[0] ? res.data.info[0].callcentre : '')
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getLogo()
        getInfo()
    }, [])

    return (
        <div className='footer-component'>
            <div className='laptop'>
                <div className='containercha'>
                    <div className='footer-wrapper'>

                        <div className='ustun'>
                            <img src={haveLogo ? `${link}/${logoImg}` : logoPng} alt='' />
                            <p>{t('footer.text')}</p>
                            <div>
                                <img src={clickPng} alt='' />
                                <img src={paymePng} alt='' />
                                <img src={humoPng} alt='' />
                                <img src={uzcardPng} alt='' />
                                <img src={visaPng} alt='' />
                            </div>
                        </div>

                        <div className='ustun'>
                            <h5>{t('footer.buyers')}</h5>
                            <Link to='/how-to-make-order'>{t('footer.make-order')}</Link>
                            <Link to='/delivery'>{t('footer.delivery')}</Link>
                            <Link to='/master-service'>{t('footer.master')}</Link>
                            <Link to='/quality-assurance'>{t('footer.garranty')}</Link>
                            <Link to='/return-exchange'>{t('footer.return')}</Link>
                            <Link to='/terms-of-use'>{t('footer.using')}</Link>
                        </div>

                        <div className='ustun'>
                            <h5>{t('footer.partners')}</h5>
                            {
                                lang === "ru" ? (
                                    <Link to='/sell-on-zap'>{t('footer.on')} ZAP.UZ</Link>
                                ) : (
                                    <Link to='/sell-on-zap'>ZAP.UZ {t('footer.on')}</Link>
                                )
                            }
                            <Link to='/wholesale-buyers'>{t('footer.whole-sale')}</Link>
                            <Link to='/car-services'>{t('footer.auto-service')}</Link>
                            <Link to='/advertisement'>{t('footer.advert')}</Link>
                        </div>

                        <div className='ustun'>
                            <h5>{t('footer.company')}</h5>
                            <Link to='/about-us'>{t('footer.about')}</Link>
                            <Link to='/news-blog'>{t('footer.news')}</Link>
                            <Link to='/all-categories'>{t('footer.categories')}</Link>
                            <Link to='/contact'>{t('footer.contact')}</Link>
                            <Link to='/jobs'>{t('footer.vacancy')}</Link>
                            <Link to='/all-brands'>{t('product.brands')}</Link>
                        </div>

                        <div className='ustun'>
                            <h5>{t('footer.social')}</h5>
                            <div className='socials'>
                                <a href={facebook} target='_blank' rel="noreferrer" >
                                    <FaFacebookF className='icon' />
                                </a>
                                <a href={instagram} target='_blank' rel="noreferrer" >
                                    <FaInstagram className='icon' />
                                </a>
                                <a href={telegram} target='_blank' rel="noreferrer" >
                                    <FaTelegramPlane className='icon' />
                                </a>
                                <a href={youtube} target='_blank' rel="noreferrer" >
                                    <FaYoutube className='icon' />
                                </a>
                            </div>
                            <div className='qator'>
                                {
                                    have ? <BsTelephonePlus className='icon' /> : ''
                                }
                                <p>{number}</p>
                            </div>
                            <div className='qator'>
                                {
                                    have ? <HiOutlineMail className='icon iconn' /> : ''
                                }
                                <p>{email}</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='bottom-footer'>
                    <span>2016-2024 © ZAP. {t('footer.defended')}</span>
                </div>
            </div>

            <div className='mobile containercha'>
                <Collapse expandIconPosition='right'>
                    <Panel header={t('footer.buyers')} key="1">
                        <Link to='/how-to-make-order'>{t('footer.make-order')}</Link>
                        <Link to='/delivery'>{t('footer.delivery')}</Link>
                        <Link to='/master-service'>{t('footer.master')}</Link>
                        <Link to='/quality-assurance'>{t('footer.garranty')}</Link>
                        <Link to='/return-exchange'>{t('footer.return')}</Link>
                        <Link to='/terms-of-use'>{t('footer.using')}</Link>
                    </Panel>
                    <Panel header={t('footer.partners')} key="2">
                        {
                            lang === "ru" ? (
                                <Link to='/sell-on-zap'>{t('footer.on')} ZAP.UZ</Link>
                            ) : (
                                <Link to='/sell-on-zap'>ZAP.UZ {t('footer.on')}</Link>
                            )
                        }
                        <Link to='/wholesale-buyers'>{t('footer.whole-sale')}</Link>
                        <Link to='/car-services'>{t('footer.auto-service')}</Link>
                        <Link to='/advertisement'>{t('footer.advert')}</Link>
                    </Panel>
                    <Panel header={t('footer.company')} key="3">
                        <Link to='/about-us'>{t('footer.about')}</Link>
                        <Link to='/news-blog'>{t('footer.news')}</Link>
                        <Link to='/all-categories'>{t('footer.categories')}</Link>
                        <Link to='/contact'>{t('footer.contact')}</Link>
                        <Link to='/jobs'>{t('footer.vacancy')}</Link>
                        <Link to='/all-brands'>{t('product.brands')}</Link>
                    </Panel>

                    <div className='square'>
                        <h5>{t('footer.social')}</h5>
                        <div className='socials'>
                            <a href={telegram} target='_blank' rel="noreferrer" >
                                <FaFacebookF className='icon' />
                            </a>
                            <a href={instagram} target='_blank' rel="noreferrer" >
                                <FaInstagram className='icon' />
                            </a>
                            <a href={facebook} target='_blank' rel="noreferrer" >
                                <FaTelegramPlane className='icon' />
                            </a>
                            <a href={youtube} target='_blank' rel="noreferrer" >
                                <FaYoutube className='icon' />
                            </a>
                        </div>
                        <div className='qator'>
                            {
                                have ? <BsTelephonePlus className='icon' /> : ''
                            }
                            <p>{number}</p>
                        </div>
                        <div className='qator'>
                            {
                                have ? <HiOutlineMail className='icon iconn' /> : ''
                            }
                            <p>{email}</p>
                        </div>
                        <p>{t('footer.text')}</p>
                    </div>

                    <div className='square'>
                        <div>
                            <img src={clickPng} alt='' />
                            <img src={paymePng} alt='' />
                        </div>
                        <div>
                            <img src={humoPng} alt='' />
                            <img src={uzcardPng} alt='' />
                            <img src={visaPng} alt='' />
                        </div>
                        <p>2016-2022 © ZAP. {t('footer.defended')}</p>
                    </div>

                </Collapse>
            </div>
        </div>
    )
}

export default Footer