import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import './innerOneProduct.css'

const InnerOneProduct = (props) => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    const { item, link, currency, currencyValue } = props

    const [product, setProduct] = useState([])
    const getProductById = () => {
        axios.get(`/api/products?id=${item}`).then(res => {
            setProduct(res.data.products)
        }).catch(err => {
            navigate('/error')
        })
    }

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        getProductById()
        //eslint-disable-next-line
    }, [])

    return (
        <div className='one-product-component'>
            <Link to={`/product/${product._id}`}>
                <div className='bg' style={{ backgroundImage: `url(${link}/${product ? product.photo : ''})` }}>
                    <div className='left-top' style={{ display: `${product?.discount === null ? 'none' : 'flex'}` }}>
                        <span>{`-${product?.discount}%`}</span>
                    </div>
                    <div className='right-top' style={{ display: `${product?.top ? 'flex' : 'none'}` }}>
                        <div></div>
                        <span>{t('product.lider')}</span>
                    </div>
                    <div className='left-bottom' style={{ display: `${product?.status === 'advert' ? 'flex' : 'none'}` }}>
                        <span>{t('product.advert')}</span>
                    </div>
                    <div className='left-bottom left-bottom-second' style={{ display: `${product?.status === 'new' ? 'flex' : 'none'}` }}>
                        <span>{t('product.new')}</span>
                    </div>
                    <div className='right-bottom' style={{ display: `${(product?.sale && product?.sale.status) ? 'flex' : 'none'}` }}>
                        <span>{t('product.sale')}</span>
                    </div>
                </div>
                {
                    currency === 'UZS' ? (
                        <div className='nums'>
                            <h4>{(product.price * (100 - product.discount) / 100) ? reFormat(Math.ceil((product.price * currencyValue * (100 - product.discount)) / 100)) : ''} {t('cart.som')}</h4>
                            {
                                product?.discount ? (
                                    <h6>{reFormat(product.price * currencyValue)} {t('cart.som')}</h6>
                                ) : ''
                            }
                        </div>
                    ) : (
                        <div className='nums'>
                            <h4>{(product.price * (100 - product.discount) / 100) ? reFormat(Math.ceil((product.price * (100 - product.discount)) / 100)) : ''} $</h4>
                            {
                                product?.discount ? (
                                    <h6>{reFormat(product.price)} $</h6>
                                ) : ''
                            }
                        </div>
                    )
                }
                <p>{product.brand ? product.brand.name : ''} / {product.name ? product.name[`${lang}`] : ''} / {product.partner ? product.partner.name : ''} {product.model ? product.model.name : ''}</p>
            </Link>
        </div>
    )
}

export default InnerOneProduct