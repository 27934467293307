import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import './news.css'
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import axios from 'axios';
import Loader from '../loader/Loader';
import { FaCalendarAlt } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const News = (props) => {

    const { link } = props
    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const loader = loading ? <Loader /> : null

    const [allBlogs, setAllBlogs] = useState([])
    const getNewsBlog = () => {
        axios.get("/api/blog?skip=1&limit=6").then(res => {
            setAllBlogs(res.data.blogs)
            setLoading(false)
        }).catch(err => {
            navigate('/error')
            setLoading(false)
        })
    }

    useEffect(() => {
        getNewsBlog()
    }, [])

    let settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        cssEase: "linear",
    };

    return (
        <div className='news-component'>
            {loader}
            <Row className='comp-version'>
                {
                    allBlogs && Array.isArray(allBlogs) ? allBlogs.map((item, index) => {
                        const date = new Date(JSON.parse(JSON.stringify(item.createdAt)))

                        let descr = ''
                        let continueDescr = ''

                        for (let i = 0; i < item.text[`${lang}`].length; i++) {
                            if (i < 70) {
                                descr += item.text[`${lang}`][i]
                            } else {
                                continueDescr = '...'
                                break
                            }
                        }

                        return (
                            <Col lg={4} md={4} sm={6} xs={12} key={index}>
                                <div className='news'>
                                    <div className='bg-img' style={{ backgroundImage: `url(${link}/${item.photo})` }} ></div>
                                    <div className='date'>
                                        <div>
                                            <FaCalendarAlt className='icon' />
                                            <span>{date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-{(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-{date.getFullYear()}</span>
                                        </div>
                                        <div>
                                            <AiFillEye className='icon' />
                                            <span>{item.views}</span>
                                        </div>
                                    </div>
                                    <h2>{item.title[`${lang}`]}</h2>
                                    <p>{descr}{continueDescr}</p>
                                    <Link to={`/news/${item._id}`}>{t('home.read')} <BsArrowRight /></Link>
                                </div>
                            </Col>
                        )
                    }) : ''
                }
            </Row>

            {/* <Swiper
                cssMode={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                loop={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    {
                        allBlogs && Array.isArray(allBlogs) ? allBlogs.map((item, index) => {
                            const date = new Date(JSON.parse(JSON.stringify(item.createdAt)))

                            let descr = ''
                            let continueDescr = ''

                            for ( let i = 0; i < item.text.uz.length; i++ ) {
                                if ( i < 70 ) {
                                    descr += item.text.uz[i]
                                } else {
                                    continueDescr = '...'
                                    break
                                }
                            }

                            return (
                                <Col lg={4} md={4} sm={6} xs={12} key={index}>
                                    <div className='news'>
                                        <div className='bg-img' style={{backgroundImage: `url(${link}/${item.photo})`}} ></div>
                                        <div className='date'>
                                            <div>
                                                <FaCalendarAlt className='icon' />
                                                <span>{date.getDate()}-{date.getMonth()}-{date.getFullYear()}</span>
                                            </div>
                                            <div>
                                                <AiFillEye className='icon' />
                                                <span>{item.views}</span>
                                            </div>
                                        </div>
                                        <h2>{item.title.uz}</h2>
                                        <p>{descr}{continueDescr}</p>
                                        <Link to='/'>Читать далее <BsArrowRight /></Link> 
                                    </div>
                                </Col>
                            )
                        }):''
                    }
                </SwiperSlide>
            </Swiper> */}

            <div className='mobile-slider'>
                <Slider {...settings}>
                    {
                        allBlogs && Array.isArray(allBlogs) ? allBlogs.map((item, index) => {
                            const date = new Date(JSON.parse(JSON.stringify(item.createdAt)))

                            let descr = ''
                            let continueDescr = ''

                            for (let i = 0; i < item.text.uz.length; i++) {
                                if (i < 70) {
                                    descr += item.text.uz[i]
                                } else {
                                    continueDescr = '...'
                                    break
                                }
                            }

                            return (
                                <Col lg={4} md={4} sm={6} xs={12} key={index}>
                                    <div className='news'>
                                        <div className='bg-img' style={{ backgroundImage: `url(${link}/${item.photo})` }} ></div>
                                        <div className='date'>
                                            <div>
                                                <FaCalendarAlt className='icon' />
                                                <span>{date.getDate()}-{date.getMonth()}-{date.getFullYear()}</span>
                                            </div>
                                            <div>
                                                <AiFillEye className='icon' />
                                                <span>{item.views}</span>
                                            </div>
                                        </div>
                                        <h2>{item.title.uz}</h2>
                                        <p>{descr}{continueDescr}</p>
                                        <Link to={`/news/${item._id}`}>Читать далее <BsArrowRight /></Link>
                                    </div>
                                </Col>
                            )
                        }) : ''
                    }
                </Slider>
            </div>
        </div>
    )
}

export default News