import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaHeart } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Rating } from 'react-simple-star-rating'
import './cart.css'

const OneCart = ({ item, link, deleteCart, checkedCart, addFavorites, inc, dec, allRate, checkedMaster, currency, currencyValue }) => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const [commentsCount, setCommentsCount] = useState(0)
    const [rate, setRate] = useState(0)

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        allRate.forEach(el => {
            if (el._id === item.product._id) {
                setCommentsCount(el.count)
                setRate(el.rate)
            }
        })
        //eslint-disable-next-line
    }, [allRate])

    return (
        <div className='product'>
            <input type='checkbox' checked={item.checked} onChange={() => checkedCart(item._id)} />
            <div className='product-body'>
                <div className='bg-texts-wrap'>
                    <div className='bg' style={{ backgroundImage: `url(${link}/${item ? item.product.photo : ''})` }}></div>
                    <div className='texts'>
                        <div>
                            <h5>{item.product.brand.name} / {item.product.name[`${lang}`]} / {item.product.partner.name} {item.product.model.name}</h5>
                            {
                                commentsCount ? (
                                    <div className='rating-w'>
                                        <Rating ratingValue={rate * 20} allowHover={false} size={24} readonly />
                                        <span>({commentsCount} {t('product.comment')})</span>
                                    </div>
                                ) : null
                            }
                        </div>
                        <p>{t('product.kod')} <strong>{item.product.productCode}</strong></p>
                        {
                            item.product.master ? (
                                <div className='label-input'>
                                    <input type='checkbox' checked={item.master} onChange={() => checkedMaster(item._id)} />
                                    <label>Usta xizmati <span style={{ marginLeft: '5px' }}>{reFormat(item?.product?.master)}</span> so'm</label>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                <div className='btn-wrap'>
                    <p onClick={() => dec(item._id)}>-</p>
                    <input type='text' value={item.count} readOnly />
                    <p onClick={() => inc(item._id)}>+</p>
                </div>
                <div className='nums'>
                    <div>
                        {
                            currency === 'UZS' ? (
                                <span style={{ display: item.product.discount ? 'block' : 'none', color: 'red' }}>{reFormat(item.product.price * currencyValue)} {t('cart.som')}</span>
                            ) : (
                                <span style={{ display: item.product.discount ? 'block' : 'none', color: 'red' }}>{reFormat(item.product.price)} $</span>
                            )
                        }
                        <div style={{ display: `${item.product.sale.status ? 'flex' : 'none'}` }}>{t('product.sale')}</div>
                    </div>
                    {
                        currency === 'UZS' ? (
                            <h4>{reFormat(Math.ceil(item.product.price * (100 - item.product.discount) / 100) * currencyValue)} {t('cart.som')}</h4>
                        ) : (
                            <h4>{reFormat(Math.ceil(item.product.price * (100 - item.product.discount) / 100))} $</h4>
                        )
                    }
                </div>
            </div>
            <div className='right'>
                <div onClick={() => addFavorites(item.product._id)}>
                    <FaHeart className='icon' />
                </div>
                <div onClick={() => deleteCart(item._id)}>
                    <MdDelete className='icon' />
                </div>
            </div>
        </div>
    )
}

export default OneCart