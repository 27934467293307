import { CURRENCY, LANGUAGE_TOP } from './Types'


export const lanProduct = (langs) => {

    return {
        type: LANGUAGE_TOP,
        payload: langs
    }
}

export const Currency = (currency) => {
    window.localStorage.setItem('currency', currency)

    return {
        type: CURRENCY,
        payload: currency
    }
}