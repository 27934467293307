import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Marquee from "react-fast-marquee";
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader'
import './brands.css'

const Brands = ({ link }) => {
    
    const [ loading, setLoading ] = useState(true)
    const loader = loading ? <Loader /> : null

    const navigate = useNavigate()

    const [ allBrands, setAllBrands ] = useState([])
    const getNewsBrand = () => {
        axios.get("/api/brand/random").then(res => {
            setAllBrands(res.data.brands)
            setLoading(false)
        }).catch(err => {
            navigate('/error')
            setLoading(false)
        })
    }

    useEffect(() => {
        getNewsBrand()
    }, [])
    
    return (
        <>
            <Row className='brands-component'>
                {loader}
                {
                    allBrands && Array.isArray(allBrands) ? allBrands.map((item, index) => {
                        return (
                            <Col lg={2} md={3} sm={4} xs={6} key={index} className='col'>
                                <Link to={`/all-products?brand=${item._id}`}>
                                    <div className='brand'>
                                        <img src={`${link}/${item.photo}`} alt='' />
                                    </div>
                                </Link>
                            </Col>
                        )
                    }):''
                }
            </Row>

            <Marquee gradient={false} pauseOnHover={true} speed={60}>
                {loader}
                <div className='mobile-all-brands'>
                    {
                        allBrands && Array.isArray(allBrands) ? allBrands.map((item, index) => {
                            return (
                                <Link to={`/all-products?brand=${item._id}`} key={index}>
                                    <div className='brand'>
                                        <img src={`${link}/${item.photo}`} alt='' />
                                    </div>
                                </Link>
                            )
                        }):''
                    }
                </div>
            </Marquee>
        </>
    )
}

export default Brands