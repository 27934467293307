import React from 'react'
import { useTranslation } from 'react-i18next'
import termsUsePng from '../../img/termsUse.png'
import './termsOfUse.css'

const TermsOfUse = () => {

    const { t } = useTranslation()

    return (
        <div className='make-order-component'>
            <div className='containercha'>
                <img src={termsUsePng} alt='' className='banner' />
                <h1>{t('terms.head')}</h1>
                <p>{t('terms.head-text')}</p>

                <h2>{t('terms.1')}</h2>
                <p>{t('terms.1-1')}</p>
                <p>{t('terms.1-2')}</p>
                <p>{t('terms.1-3')}</p>

                <h2>{t('terms.2')}</h2>
                <p>{t('terms.2-1')}</p>
                <p>{t('terms.2-2')}</p>
                <p>{t('terms.2-3')}</p>

                <h2>{t('terms.3')}</h2>
                <p>{t('terms.3-1')}</p>
                <p>{t('terms.3-2')}</p>
                <p>{t('terms.3-3')}</p>

                <h2>{t('terms.4')}</h2>
                <p>{t('terms.4-1')}</p>
                
                <h2>{t('terms.5')}</h2>
                <p>{t('terms.5-1')}</p>
                <p>{t('terms.5-1-1')}</p>
                <p>{t('terms.5-2')}</p>
                <p>{t('terms.5-2-2')}</p>
                <p>{t('terms.5-2-3')}</p>

                <h2>{t('terms.6')}</h2>
                <p>{t('terms.6-1-1')}</p>
                <p>{t('terms.6-1-2')}</p>
                <p>{t('terms.6-1-3')}</p>
                <p>{t('terms.6-2')}</p>
                <p>{t('terms.6-2-1')}</p>

                <h2>{t('terms.7')}</h2>
                <p>{t('terms.7-1')}</p>
                <p>{t('terms.7-2')}</p>
                <p>{t('terms.7-2-1')}</p>
                <p>{t('terms.7-2-2')}</p>
                <p>{t('terms.7-2-3')}</p>
                <p>{t('terms.7-2-4')}</p>
                <p>{t('terms.7-2-5')}</p>
                <p>{t('terms.7-3')}</p>
                <p>{t('terms.7-3-1')}</p>
                <p>{t('terms.7-3-2')}</p>
                <p>{t('terms.7-3-3')}</p>
                
                <h2>{t('terms.8')}</h2>
                <p>{t('terms.8-1')}</p>
                <p>{t('terms.8-2')}</p>
                <p>{t('terms.8-2-1-')}</p>
                <p>{t('terms.8-2-2-')}</p>
                <p>{t('terms.8-2-3-')}</p>
                <p>{t('terms.8-2-4-')}</p>
                <p>{t('terms.8-2-5-')}</p>
                <p>{t('terms.8-3')}</p>

                <h2>{t('terms.9')}</h2>
                <p>{t('terms.9-1')}</p>

                <h2>{t('terms.10')}</h2>
                <p>{t('terms.10-1')}</p>
                <p>{t('terms.10-2')}</p>
                <p>{t('terms.10-3')}</p>
                <p>{t('terms.10-4')}</p>
                <p>{t('terms.10-5')}</p>
                <p>{t('terms.10-6')}</p>
                <p>{t('terms.10-7')}</p>
                <p>{t('terms.10-8')}</p>
                <p>{t('terms.10-9')}</p>
                <p>{t('terms.10-10')}</p>
                <p>{t('terms.10-10-1')}</p>
                <p>{t('terms.10-10-2')}</p>
                <p>{t('terms.10-10-3')}</p>
                <p>{t('terms.10-11')}</p>
                <p>{t('terms.10-12')}</p>

                <h2>{t('terms.11')}</h2>
                <p>{t('terms.11-1')}</p>
                <p>{t('terms.11-2')}</p>
                <p>{t('terms.11-3')}</p>
                <p>{t('terms.11-4')}</p>
                <p>{t('terms.11-5')}</p>

                <h2>{t('terms.12')}</h2>
                <p>{t('terms.12-1')}</p>
                <p>{t('terms.12-2')}</p>
                <p>{t('terms.12-3')}</p>
                <p>{t('terms.12-4')}</p>

                <h2>{t('terms.13')}</h2>
                <p>{t('terms.13-1')}</p>

                <h2>{t('terms.14')}</h2>
                <p>{t('terms.14-1')}</p>

                <h2>{t('terms.15')}</h2>
                <p>{t('terms.15-1')}</p>

                <h2>{t('terms.16')}</h2>
                <p>{t('terms.16-1')}</p>
                <p>{t('terms.16-2')}</p>
                <p>{t('terms.16-3')}</p>

                <h2>{t('terms.17')}</h2>
                <p>{t('terms.17-1')}</p>
                <p>{t('terms.17-2')}</p>

                <p style={{marginTop: '20px'}}>{t('terms.last-text')}</p>

            </div>
        </div>
    )
}

export default TermsOfUse