import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { FiHeadphones, FiMenu, FiSearch } from 'react-icons/fi'
import { RiArrowDownSFill } from 'react-icons/ri'
import { CgMenu } from 'react-icons/cg'
import { AiFillHeart } from 'react-icons/ai'
import { IoIosArrowForward } from 'react-icons/io'
import { TiHome } from 'react-icons/ti'
import { BiCategoryAlt, BiEditAlt } from 'react-icons/bi'
import { BsCartFill } from 'react-icons/bs'
import { RiBarChartFill } from 'react-icons/ri'
import Marquee from "react-fast-marquee";

import logoPng from '../../img/logo.png'
import userPng from '../../img/user.png'

import './navbar.css'
import Catalog from './Catalog'
import { HiOutlineLogout, HiUser } from 'react-icons/hi'
import { MdClose } from 'react-icons/md'
import MobileCatalog from './MobileCatalog'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Currency } from '../../redux/action/LanguageAction'
import { BASE_URL } from '../../App'

const Navbar = (props) => {

    const { auth, link, rend, setRend, userMenu, setUserMenu, setFavoritesCount, favoritesCount, cartCount,
        setCartCount, userData, setComparisonCount, comparisonCount, changeLan
    } = props


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)
    const { currency } = useSelector(state => state.currencyReducer)

    const [mobileSearch, setMobileSearch] = useState(false)
    const [lan, setLan] = useState(false)
    const [usd, setUsd] = useState(false)
    const [catalogShow, setCatalogShow] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)

    const [number, setNumber] = useState('')
    const getInfo = () => {
        axios.get('/api/info').then(res => {
            setNumber(res.data.info[0] ? res.data.info[0].callcentre : '')
        }).catch(err => {
            navigate('/error')
        })
    }

    const [haveLogo, setHaveLogo] = useState()
    const [logoImg, setLogoImg] = useState('')
    const getLogo = () => {
        axios.get('/api/logo').then(res => {
            setLogoImg(res.data.logo[0] ? res.data.logo[0].image : '')
            setHaveLogo(res.data.logo[0] ? true : false)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [langStatus, setLangStatus] = useState()
    const getLang = () => {
        axios.get('/api/lang').then(res => {
            setLangStatus(res.data.lang.length !== 0 ? res.data.lang[0].status : true)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [usdStatus, setUsdStatus] = useState()
    const getUsd = () => {
        axios.get('/api/usd').then(res => {
            setUsdStatus(res.data.usd.length !== 0 ? res.data.usd[0].status : true)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [allNotifies, setAllNotifies] = useState([])
    const getNotify = () => {
        axios.get("/api/notify").then(res => {
            setAllNotifies(res.data.notify)
        }).catch(err => {
            navigate('/error')
        })
    }

    const logout = () => {
        window.localStorage.removeItem('token')
        navigate('/')
        setRend(!rend)
        setFavoritesCount(0)
        setCartCount(0)
        setComparisonCount(0)
    }

    const [allSearchProducts, setAllSearchedProducts] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const searchProduct = () => {
        if (searchInput.length > 0) {
            axios.get(`/api/products/search?word=${searchInput}`).then(res => {
                setAllSearchedProducts(res.data)
            }).catch(err => {
                navigate('/error')
            })
        }
    }

    const searchProduct1 = (e) => {
        e.preventDefault()

        searchProduct()
    }

    useEffect(() => {
        getLogo()
        getLang()
        getUsd()
        getNotify()
        getInfo()
    }, [])

    useEffect(() => {
        searchProduct()
        //eslint-disable-next-line
    }, [searchInput])

    const searchEnd = () => {
        setMobileSearch(false)
        setAllSearchedProducts([])
        setSearchInput('')
    }

    const authFalseLogin = (
        <Link to='/login' className='div'>
            <div>
                <img src={userPng} alt='' />
            </div>
            <p>{t('profile.login')}</p>
        </Link>
    )

    const authTrue = (
        <div className='div user-wrapper'>
            <div className='user-img' onClick={() => setUserMenu(!userMenu)} style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
            <div className={userMenu ? 'user-menu user-menu-active' : 'user-menu'}>
                <div className='top'>
                    <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
                    <div className='text'>
                        <h6>{userData ? userData.firstname : ''} {userData ? userData.lastname : ''}</h6>
                        <p>{userData ? userData.number : ''}</p>
                    </div>
                    <Link to='/profile' className='icon-wrap' onClick={() => setUserMenu(false)}>
                        <BiEditAlt className='icon' />
                    </Link>
                </div>
                <div className='links-wrapper'>
                    <Link to='/my-orders' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.my-orders')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/my-address' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.my-address')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/my-messages' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.sms')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/my-comments' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.my-comments')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/favorites' onClick={() => setUserMenu(false)}>
                        <h5>{t('navbar.favorites')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/comparison' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.comparison-list')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                </div>
                <div className='logout-wrap' onClick={logout}>
                    <HiOutlineLogout className='icon' />
                    <h5>{t('profile.logout')}</h5>
                </div>
            </div>
        </div>
    )

    const mobileAuthTrue = (
        <div className='mobile-user-wrapper'>
            <div className='user-img' onClick={() => setUserMenu(!userMenu)} style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
            <div className={userMenu ? 'user-menu user-menu-active' : 'user-menu'}>
                <div className='top'>
                    <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
                    <div className='text'>
                        <h6>{userData ? userData.firstname : ''} {userData ? userData.lastname : ''}</h6>
                        <p>{userData ? userData.number : ''}</p>
                    </div>
                    <Link to='/profile' className='icon-wrap' onClick={() => setUserMenu(false)}>
                        <BiEditAlt className='icon' />
                    </Link>
                </div>
                <div className='links-wrapper'>
                    <Link to='/my-orders' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.my-orders')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/my-address' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.my-address')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/my-messages' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.sms')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/my-comments' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.my-comments')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/favorites' onClick={() => setUserMenu(false)}>
                        <h5>{t('navbar.favorites')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                    <Link to='/comparison' onClick={() => setUserMenu(false)}>
                        <h5>{t('profile.comparison-list')}</h5>
                        <IoIosArrowForward className='icon' />
                    </Link>
                </div>
                <div className='logout-wrap' onClick={logout}>
                    <HiOutlineLogout className='icon' />
                    <h5>{t('profile.logout')}</h5>
                </div>
            </div>
        </div>
    )

    return (
        <div className='navbar-component'>
            {
                allNotifies && Array.isArray(allNotifies) ? allNotifies.map((item, index) => {
                    return (
                        <Marquee key={index} gradient={false} pauseOnHover={true} speed={60}>
                            <div className='notify' style={{ backgroundColor: `${item.bg}`, color: `${item.color}` }}>{item.text[`${lang}`]}</div>
                        </Marquee>
                    )
                }) : ''
            }

            <div className='navbar-top-part'>
                <div className='containercha inner-top'>
                    <div className='left'>
                        <Link to='/delivery'>{t('navbar.delivery')}</Link>
                        <Link to='/master-service'>{t('navbar.service')}</Link>
                        <Link to='/all-products?discount=1'>{t('navbar.discount')}</Link>
                        {
                            lang === 'ru' ? (
                                <Link to='/sell-on-zap'>{t('navbar.sell')} <span>ZAP.UZ</span></Link>
                            ) : (
                                <Link to='/sell-on-zap'><span>ZAP.UZ</span> {t('navbar.sell')}</Link>
                            )
                        }
                    </div>
                    <div className='right'>
                        <div className='tel-w'>
                            <FiHeadphones className='icon' />
                            <a href={`tel:${number}`}>{number}</a>
                        </div>
                        <div className='lang-w'>
                            <div className='one' onClick={() => setLan(!lan)}>
                                <span>{t('navbar.lang')}</span>
                                <RiArrowDownSFill className='icon' />
                            </div>
                            <div className={lan ? 'two-lans two-lans-act' : 'two-lans'}>
                                {
                                    langStatus ? <div onClick={() => { setLan(false); changeLan('uz') }}>UZB</div> : null
                                }
                                <div onClick={() => { setLan(false); changeLan('ru') }}>RUS</div>
                            </div>
                        </div>

                        <div className='bb'></div>

                        <div className='lang-w usd-w'>
                            <div className='one' onClick={() => setUsd(!usd)}>
                                <span>{currency}</span>
                                <RiArrowDownSFill className='icon' />
                            </div>
                            <div className={usd ? 'two-lans two-lans-act' : 'two-lans'}>
                                <div onClick={() => { setUsd(false); dispatch(Currency("UZS")) }}>UZS</div>
                                {
                                    usdStatus ? <div onClick={() => { setUsd(false); dispatch(Currency("USD")) }}>USD</div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='navbar-bottom-part'>
                <div className='containercha inner-bottom'>
                    <div className='left'>
                        <Link to='/'>
                            <img src={haveLogo ? `${link}/${logoImg}` : logoPng} alt='' />
                        </Link>
                        <button className='catalog-btn' onClick={() => setCatalogShow(true)}>
                            <FiMenu className='icon' />
                            <span>{t('navbar.catalog')}</span>
                        </button>
                        <form onSubmit={searchProduct1}>
                            <input type='text' placeholder={t('navbar.search-product')} value={searchInput} onChange={(e) => { setSearchInput(e.target.value); searchProduct() }} />
                            <MdClose className={searchInput.length > 0 ? 'close-icon close-icon-show' : 'close-icon'} onClick={searchEnd} />
                            <button type='submit'>{t('navbar.search')}</button>
                            <div className={allSearchProducts.length > 0 ? 'search-results-curtain search-results-curtain-show' : 'search-results-curtain'} onClick={searchEnd}></div>
                            <div className={allSearchProducts.length > 0 ? 'search-results' : 'search-results search-results-false'}>
                                {
                                    allSearchProducts && Array.isArray(allSearchProducts) ? allSearchProducts.map((item, index) => {
                                        return (
                                            <Link key={index} to={`product/${item._id}`} className='aa' onClick={searchEnd}>
                                                <div className='bg' style={{ backgroundImage: `url(${link}/${item.photo})` }}></div>
                                                <div>{item.brand} / {item.name.uz} / {item.partner} {item.model}</div>
                                            </Link>
                                        )
                                    }) : ''
                                }
                            </div>
                        </form>
                    </div>
                    <div className='right'>
                        {
                            auth ? authTrue : authFalseLogin
                        }
                        <Link to='/favorites' className='div'>
                            <div>
                                <AiFillHeart className='icon' />
                                <span style={{ display: favoritesCount === 0 ? 'none' : 'flex' }}>{favoritesCount}</span>
                            </div>
                            <p>{t('navbar.favorites')}</p>
                        </Link>
                        <Link to='/comparison' className='div'>
                            <div>
                                <RiBarChartFill className='icon' />
                                <span style={{ display: comparisonCount === 0 ? 'none' : 'flex' }}>{comparisonCount}</span>
                            </div>
                            <p>{t('navbar.comparison')}</p>
                        </Link>
                        {
                            <Link to='/cart' className='div'>
                                <div>
                                    <BsCartFill className='icon' />
                                    <span style={{ display: cartCount === 0 ? 'none' : 'flex' }}>{cartCount}</span>
                                </div>
                                <p>{t('navbar.cart')}</p>
                            </Link>
                        }
                    </div>
                </div>
            </div>


            {/* MOBILE NAVBAR  */}

            <div className='mobile-navbar'>
                <div className='top-mobile'>
                    <div>
                        <CgMenu className='icon' onClick={() => setMobileMenu(true)} />
                        <Link to='/'>
                            <img src={haveLogo ? `${link}/${logoImg}` : logoPng} alt='' />
                        </Link>
                    </div>
                    <div>
                        <div className={mobileSearch ? 'searchs searchs-active' : 'searchs'}>
                            <MdClose className='icon' onClick={() => { setMobileSearch(false); searchEnd() }} />
                            <input type='text' value={searchInput} onChange={(e) => { setSearchInput(e.target.value); searchProduct() }} />
                            <div className={allSearchProducts.length > 0 ? 'results' : 'results results-false'}>
                                {
                                    allSearchProducts && Array.isArray(allSearchProducts) ? allSearchProducts.map((item, index) => {
                                        return (
                                            <Link key={index} to={`product/${item._id}`} className='aa' onClick={searchEnd}>
                                                <div className='bg' style={{ backgroundImage: `url(${link}/${item.photo})` }}></div>
                                                <div>{item.brand} / {item.name.uz} / {item.partner} {item.model}</div>
                                            </Link>
                                        )
                                    }) : ''
                                }
                            </div>
                        </div>
                        <FiSearch className='icon icon-search' onClick={() => setMobileSearch(true)} />
                        {
                            auth ? mobileAuthTrue : (
                                <Link to='/login'>
                                    <HiUser className='icon' />
                                </Link>
                            )
                        }
                    </div>
                </div>


                <div className={mobileMenu ? 'mobile-navbar-body mobile-navbar-body-active' : 'mobile-navbar-body'}>
                    <div className='top'>
                        <Link to='/'>
                            <img src={haveLogo ? `${link}/${logoImg}` : logoPng} alt='' />
                        </Link>
                        <div className='right'>
                            <div className='lang-w'>
                                <div className='one' onClick={() => setLan(!lan)}>
                                    <span>{t('navbar.lang')}</span>
                                    <RiArrowDownSFill className='icon' />
                                </div>
                                <div className={lan ? 'two-lans two-lans-active' : 'two-lans'}>
                                    <div onClick={() => { setLan(false); changeLan('ru') }}>RUS</div>
                                    {
                                        langStatus ? <div onClick={() => { setLan(false); changeLan('uz') }}>UZB</div> : null
                                    }
                                </div>
                            </div>

                            <div className='lang-w usd-w'>
                                <div className='one' onClick={() => setUsd(!usd)}>
                                    <span>{currency}</span>
                                    <RiArrowDownSFill className='icon' />
                                </div>
                                <div className={usd ? 'two-lans two-lans-active' : 'two-lans'}>
                                    <div onClick={() => { setUsd(false); dispatch(Currency("UZS")) }}>UZS</div>
                                    {
                                        usdStatus ? <div onClick={() => { setUsd(false); dispatch(Currency("USD")) }}>USD</div> : null
                                    }
                                </div>
                            </div>

                            <MdClose className='icon' onClick={() => setMobileMenu(false)} />
                        </div>
                    </div>

                    <MobileCatalog setMobileMenu={setMobileMenu} />

                </div>
            </div>

            <Catalog catalogShow={catalogShow} setCatalogShow={setCatalogShow} link={link} />

            <div className='bottom-panel'>
                <Link to='/' className='square'>
                    <TiHome className='icon' />
                </Link>
                <Link to='/all-categories' className='square'>
                    <BiCategoryAlt className='icon' />
                </Link>
                <Link to='/cart' className='square'>
                    <BsCartFill className='icon' />
                    <span style={{ display: cartCount === 0 || cartCount === '' ? 'none' : 'flex' }}>{cartCount}</span>
                </Link>
                <Link to='/favorites' className='square'>
                    <AiFillHeart className='icon' />
                    <span style={{ display: favoritesCount === 0 || favoritesCount === '' ? 'none' : 'flex' }}>{favoritesCount}</span>
                </Link>
                <Link to='/comparison' className='square'>
                    <RiBarChartFill className='icon' />
                    <span style={{ display: comparisonCount === 0 || comparisonCount === '' ? 'none' : 'flex' }}>{comparisonCount}</span>
                </Link>
            </div>

        </div>
    )
}

export default Navbar