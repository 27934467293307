import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Loader from '../loader/Loader'
import { Col, Row } from 'react-bootstrap'
import './catalogByCarBrands.css'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CatalogByCarBrands = ({ link }) => {

    const [ loading, setLoading ] = useState(true)
    const loader = loading ? <Loader /> : null

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [ allBrands, setAllBrands ] = useState([])
    const getAllBrands = () => {
        axios.get("/api/partners").then(res => {
            setAllBrands(res.data.partners)
            setLoading(false)
        }).catch(err => {
            navigate('/error')
            setLoading(false)
        })
    }

    useEffect(() => {
        getAllBrands()
    }, [])

    return (
        <div className='all-brands-component'>
            <div className='containercha'>
                <h1>{t('home.catalog-avto')}</h1>
                <Row>
                {loader}
                    {
                        allBrands && Array.isArray(allBrands) ? allBrands.map((item, index) => {
                            return (
                                <Col lg={2} md={3} sm={4} xs={6} key={index} className='col'>
                                    <Link to={`/all-products?partner=${item._id}`}>
                                        <div className='brand'>
                                            <div className='bg' style={{backgroundImage: `url(${link}/${item.image})`}}></div>
                                            <div className='bottom'>
                                                <p>{item.name}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        }):''
                    }
                </Row>
            </div>
        </div>
    )
}

export default CatalogByCarBrands