import React from 'react'
import './notFound.css'
import notfoundPng from '../../img/notfound.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NotFound = () => {

    const { t } = useTranslation()

    return (
        <div className='not-found-component'>
            <div className='wrapper'>
                <img src={notfoundPng} alt='' />
                <h2>{t('not-found.page')}</h2>
                <p>{t('not-found.keep')}</p>
                <Link to='/'>
                    <button>{t('not-found.home')}</button>
                </Link>
            </div>
        </div>
    )
}

export default NotFound