import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./homeBanner.css";

import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const HomeBanner = (props) => {

  const { link } = props
  const { lang } = useSelector(state => state.nowLanguage)

  const navigate = useNavigate()

  const [ allBanners, setAllBanners ] = useState([])
  const getBanners = () => {
    axios.get(`/api/banner?status=main`).then(res => {
      setAllBanners(res.data.banners)
    }).catch(err => {
      navigate('/error')
    })
  }

  const [ allBannersMobile, setAllBannersMobile ] = useState([])
  const getBannersMobile = () => {
    axios.get(`/api/banner?status=mainmobile`).then(res => {
      setAllBannersMobile(res.data.banners)
    }).catch(err => {
      navigate('/error')
    })
  }

  useEffect(() => {
    getBanners()
    getBannersMobile()
  }, [])

  return (
    <div className="home-banner">
      <Swiper
        cssMode={true}
        autoplay={{
            delay: 5000,
            disableOnInteraction: false,
        }}
        loop={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className="mySwiper comp-swiper"
      >
        {
          allBanners && Array.isArray(allBanners) ? allBanners.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to={item.product === null ? item.brand !== null ? `/all-products?brand=${item.brand._id}` : '/' : `/product/${item.product?._id}`}>
                    <img src={`${link}/${item.photo[`${lang}`]}`} alt='' />
                  </Link>
              </SwiperSlide>
            )
          }):''
        }
      </Swiper>

      <Swiper
        cssMode={true}
        autoplay={{
            delay: 5000,
            disableOnInteraction: false,
        }}
        loop={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className="mySwiper mob-swiper"
      >
        {
          allBannersMobile && Array.isArray(allBannersMobile) ? allBannersMobile.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to={item.product === null ? item.brand !== null ? `/all-products?brand=${item.brand._id}` : '/' : `/product/${item.product?._id}`}>
                    <img src={`${link}/${item.photo[`${lang}`]}`} alt='' />
                  </Link>
              </SwiperSlide>
            )
          }):''
        }
      </Swiper>
    </div>
  );
}

export default HomeBanner