import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AiFillEye } from 'react-icons/ai'
import { BsArrowRight } from 'react-icons/bs'
import { FaCalendarAlt } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { Pagination } from 'antd';
import Loader from '../loader/Loader'
import '../news/oneNews.css'
import './newsBlog.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const NewsBlog = (props) => {

    const { link } = props
    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    const [ banner, setBanner ] = useState('')
    const getBanner = () => {
        axios.get(`/api/banner?status=advert`).then(res => {
            setBanner(res.data.banners.length !== 0 ? res.data.banners[0] : '')
            setLoading(false)
        }).catch(err => {
            navigate('/error')
            setLoading(false)
        })
    }

    const [ skip, setSkip ] = useState(1)
    const [ limit, setLimit ] = useState(9)

    const change = ( skip, limit ) => {
        setSkip(skip)
        setLimit(limit)
    }

    const [ loading, setLoading ] = useState(true)
    const loader = loading ? <Loader /> : null

    const [ allBlogs, setAllBlogs ] = useState([])
    const [ count, setCount ] = useState(0)
    const getNewsBlog = () => {
        axios.get(`/api/blog?skip=${skip}&limit=${limit}`).then(res => {
            setAllBlogs(res.data.blogs)
            setCount(res.data.count)
            setLoading(false)
        }).catch(err => {
            navigate('/error')
            setLoading(false)
        })
    }

    useEffect(() => {
        getNewsBlog()
        //eslint-disable-next-line
    }, [ skip, limit ])

    useEffect(() => {
        getBanner()
        //eslint-disable-next-line
    }, [lang])

    return (
        <div className='news-blog-component'>
            <div className='containercha'>
                {loader}
                <Link to={banner.product === null ? banner.brand !== null ? `/all-products?brand=${banner.brand._id}` : '/' : `/product/${banner.product?._id}`}>
                    <img src={`${link}/${banner?.photo ? banner?.photo[`${lang}`] : ''}`} alt='' className='banner' />
                </Link>
                <h1>{t('footer.news')}</h1>
                {loader}
                <Row>
                    {
                        allBlogs && Array.isArray(allBlogs) ? allBlogs.map((item, index) => {
                            const date = new Date(JSON.parse(JSON.stringify(item.createdAt)))

                            let descr = ''
                            let continueDescr = ''

                            for ( let i = 0; i < item.text[`${lang}`].length; i++ ) {
                                if ( i < 70 ) {
                                    descr += item.text[`${lang}`][i]
                                } else {
                                    continueDescr = '...'
                                    break
                                }
                            }

                            return (
                                <Col lg={4} md={4} sm={6} xs={12} key={index}>
                                    <div className='news'>
                                        <div className='bg-img' style={{backgroundImage: `url(${link}/${item.photo})`}} ></div>
                                        <div className='date'>
                                            <div>
                                                <FaCalendarAlt className='icon' />
                                                <span>{date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-{date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-{date.getFullYear()}</span>
                                            </div>
                                            <div>
                                                <AiFillEye className='icon' />
                                                <span>{item.views}</span>
                                            </div>
                                        </div>
                                        <h2>{item.title[`${lang}`]}</h2>
                                        <p>{descr}{continueDescr}</p>
                                        <Link to={`/news/${item._id}`}>{t('home.read')} <BsArrowRight /></Link> 
                                    </div>
                                </Col>
                            )
                        }):''
                    }
                </Row>

                <div className='pagination-wrapper'>
                    <Pagination 
                        defaultPageSize={9}
                        onChange={change} 
                        pageSizeOptions={[9, 12, 15, 18]}
                        total={count} 
                    />
                </div>
            </div>
        </div>
    )
}

export default NewsBlog