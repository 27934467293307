import React from 'react'
import { useTranslation } from 'react-i18next'
import qualityPng from '../../img/quality.png'
import './qualityAssurance.css'

const QualityAssurance = () => {

    const { t } = useTranslation()

    return (
        <div className='make-order-component'>
            <div className='containercha'>
                <img src={qualityPng} alt='' className='banner' />
                <h1>{t('quality.h1')}</h1>
                <p style={{color: '#212121', fontWeight: '600'}}>{t('quality.p1')}</p>
                <p>{t('quality.p2')}</p>
                <p>{t('quality.p3')}</p>
                <p>{t('quality.p4')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('quality.p5')}</p>
                <p>{t('quality.p6')}</p>
                <p>{t('quality.p7')}</p>
                <p>{t('quality.p8')}</p>
                <p>{t('quality.p9')}</p>
                <p>{t('quality.p10')}</p>
                <p>{t('quality.p11')}</p>
                <p>{t('quality.p12')}</p>
                <p>{t('quality.p13')}</p>
                <p>{t('quality.p14')}</p>
                <p>{t('quality.p15')}</p>
                <p>{t('quality.p16')}</p>
                <p>{t('quality.p17')}</p>
                <p><span>{t('quality.p18-span')}</span> {t('quality.p18')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('quality.p19')}</p>
                <p>{t('quality.p20')}</p>
                <p>{t('quality.p21')}</p>
                <p><span>{t('quality.p22-span')}</span> {t('quality.p22')}</p>
                
                <p style={{color: '#212121', fontWeight: '600'}}>{t('quality.p23')}</p>
                <p>{t('quality.p24')}</p>
                <p>{t('quality.p25')}</p>
                <p>{t('quality.p26')}</p>
                <p>{t('quality.p27')}</p>
                <p>{t('quality.p28')}</p>
                <p>{t('quality.p29')}</p>
                <p>{t('quality.p30')}</p>
                <p>{t('quality.p31')}</p>
                <p>{t('quality.p32')}</p>
                <p>{t('quality.p33')}</p>
                <p>{t('quality.p34')}</p>
                <p>{t('quality.p35')}</p>
                <p>{t('quality.p36')}</p>
                <p>{t('quality.p37')}</p>
                <p>{t('quality.p38')}</p>
                <p>{t('quality.p39')}</p>
                <p>{t('quality.p40')}</p>
                <p>{t('quality.p41')}</p>
                <p>{t('quality.p42')}</p>
                <p>{t('quality.p43')}</p>
                <p>{t('quality.p44')}</p>
                <p>{t('quality.p45')}</p>
                <p>{t('quality.p46')}</p>
                <p>{t('quality.p47')}</p>
                <p>{t('quality.p48')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('quality.p49')}</p>
                <p>{t('quality.p50')}</p>
                <p>{t('quality.p51')}</p>
                <p>{t('quality.p52')}</p>
                <p>{t('quality.p53')}</p>
                <p>{t('quality.p54')}</p>
                <p>{t('quality.p55')}</p>
            </div>
        </div>
    )
}

export default QualityAssurance