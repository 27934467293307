import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { AiFillHeart } from 'react-icons/ai'
import { RiBarChartFill } from 'react-icons/ri'
import { BsEyeFill, BsFillCartFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import './product1.css'
import axios from 'axios'
import FastViewModal from '../fast-view/FastViewModal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const ProductStyle1 = (props) => {

    const { link, item, addComparison, addCart, addFavorites, currency, currencyValue, addSeen } = props

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    const navigate = useNavigate()

    const [productById, setProductById] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [productRate, setProductRate] = useState()
    const [commentsCount, setCommentsCount] = useState(0)

    const fastViewById = (id) => {
        setIsModalVisible(true)
        axios.get(`/api/products?id=${id}`).then(res => {
            setProductById(res.data.products)
            setProductRate(res.data.rate[0] ? res.data.rate[0].rate : null)
        }).catch(err => {
            navigate('/error')
        })

        axios.get(`/api/comment`, {
            params: {
                product: id
            }
        }).then(res => {
            setCommentsCount(res.data.count)
        }).catch(err => {
            navigate('/error')
        })
    }

    const navigateProductDetail = (e, id) => {
        if (e.target.className === 'curtain' || e.target.className === 'wrapper' || e.target.className === 'bg-img') {
            navigate(`/product/${id}`)
        } else {
            return 0
        }
    }

    return (
        <Col lg={3} md={3} sm={4} xs={6} onClick={(e) => navigateProductDetail(e, item?._id)}>
            <FastViewModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                productById={productById}
                link={link}
                addCart={addCart}
                addComparison={addComparison}
                addFavorites={addFavorites}
                productRate={productRate}
                commentsCount={commentsCount}
                addSeen={addSeen}
                currency={currency}
                currencyValue={currencyValue}
            />
            <div className='product1'>
                <div className='bg-img' style={{ backgroundImage: `url(${link}/${item?.photo})` }}>
                    <div className='left-top' style={{ display: `${item?.discount === null ? 'none' : 'flex'}` }}>
                        <span>{`-${item?.discount}%`}</span>
                    </div>
                    <div className='right-top' style={{ display: `${item?.top ? 'flex' : 'none'}` }}>
                        <div></div>
                        <span>{t('product.lider')}</span>
                    </div>
                    <div className='left-bottom' style={{ display: `${item?.status === 'advert' ? 'flex' : 'none'}` }}>
                        <span>{t('product.advert')}</span>
                    </div>
                    <div className='left-bottom left-bottom-second' style={{ display: `${item?.status === 'new' ? 'flex' : 'none'}` }}>
                        <span>{t('product.new')}</span>
                    </div>
                    <div className='right-bottom' style={{ display: `${item?.sale?.status ? 'flex' : 'none'}` }}>
                        <span>{t('product.sale')}</span>
                    </div>
                </div>
                <div className='data-texts'>
                    {
                        currency === 'UZS' ? (
                            <div className='sale'>
                                <span style={{ opacity: item?.discount ? '1' : '0', color: 'red' }}>{reFormat(item?.price * currencyValue)} {t('cart.som')}</span>
                                <p>{(item?.price * (100 - item?.discount) / 100) ? reFormat(Math.ceil((item?.price * (100 - item?.discount)) / 100) * currencyValue) : ''} {t('cart.som')}</p>
                            </div>
                        ) : (
                            <div className='sale'>
                                <span style={{ opacity: item?.discount ? '1' : '0', color: 'red' }}>{reFormat(item?.price)} $</span>
                                <p>{(item?.price * (100 - item?.discount) / 100) ? reFormat(Math.ceil((item?.price * (100 - item?.discount)) / 100)) : ''} $</p>
                            </div>
                        )
                    }
                    <Link to={`/product/${item?._id}`} className='text' onClick={() => addSeen(item._id)}>
                        <p>{item?.brand?.name} / {item?.name ? item?.name[`${lang}`] : ''} / {item?.partner.name} {item?.model?.name}</p>
                    </Link>
                </div>

                <div className='mobile-bottom-menu'>
                    <div className='icon-wrap' onClick={() => addCart(item?._id)}>
                        <BsFillCartFill className='icon' />
                    </div>
                    <div className='icon-wrap' >
                        <AiFillHeart className='icon' onClick={() => addFavorites(item?._id)} />
                    </div>
                    <div className='icon-wrap' onClick={() => addComparison(item?._id)}>
                        <RiBarChartFill className='icon' />
                    </div>
                </div>

                <div className='curtain'>
                    <div className='wrapper'>
                        <div></div>
                        <div>
                            <div className='text'>
                                <p>{t('product.favorites')}</p>
                                <div className='line'></div>
                            </div>
                            <div className='icon-wrap' onClick={() => addFavorites(item?._id)}>
                                <AiFillHeart className='icon' />
                            </div>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div></div>
                        <div>
                            <div className='text'>
                                <p>{t('product.comparison')}</p>
                                <div className='line'></div>
                            </div>
                            <div className='icon-wrap' onClick={() => addComparison(item?._id)}>
                                <RiBarChartFill className='icon' />
                            </div>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div></div>
                        <div>
                            <div className='text'>
                                <p>{t('product.see')}</p>
                                <div className='line'></div>
                            </div>
                            <div className='icon-wrap' onClick={() => fastViewById(item._id)}>
                                <BsEyeFill className='icon' />
                            </div>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <div></div>
                        <div>
                            <div className='text'>
                                <p>{t('product.cart')}</p>
                                <div className='line'></div>
                            </div>
                            <div className='icon-wrap' onClick={() => addCart(item?._id)}>
                                <BsFillCartFill className='icon' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default ProductStyle1