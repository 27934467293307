import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaHeart } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Rating } from 'react-simple-star-rating'
import './cart.css'

const OneMobileCart = ({ item, link, deleteCart, checkedCart, addFavorites, inc, dec, allRate, checkedMaster, currency, currencyValue }) => {

    const [ commentsCount, setCommentsCount ] = useState(0)
    const [ rate, setRate ] = useState(0)

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    function reFormat(num){
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        allRate.forEach(el => {
            if( el._id === item.product._id ) {
                setCommentsCount(el.count)
                setRate(el.rate)
            }
        })
        //eslint-disable-next-line
    }, [allRate])

    return (
        <div className='mobile-product'>
            <div className='top'>
                <div className='bg-input-w'>
                    <input type='checkbox' checked={item.checked} onChange={() => checkedCart(item._id)} />
                    <div className='bg' style={{backgroundImage: `url(${link}/${item ? item.product.photo : ''})`}}></div>
                </div>
                <div className='data-text'>
                    <h5>{item.product.brand.name} / {item.product.name[`${lang}`]} / {item.product.partner.name} {item.product.model.name}</h5>
                    {
                        commentsCount ? (
                            <div className='rating-w'>
                                <Rating ratingValue={rate * 20} size={18} readonly />
                                <span className='mx-2'>({commentsCount} {t('product.comment')})</span>
                            </div>
                        ) : null
                    }
                    <p>{t('product.kod')} <strong>{item.product.productCode}</strong></p>
                    {
                        item.product.master ? (
                            <div className='label-input'>
                                <input type='checkbox' checked={item.master} onChange={() => checkedMaster(item._id)} />
                                <label>Usta xizmati <span style={{marginLeft: '5px'}}>{reFormat(item?.product?.master)}</span> so'm</label>
                            </div>
                        ) : null
                    }
                    <div className='aksiya'>
                        {
                            currency === 'UZS' ? (
                                <h6 style={{display: item.product.discount ? 'block' : 'none', color: 'red'}}>{reFormat(item.product.price * currencyValue)} {t('cart.som')}</h6>
                            ) : (
                                <h6 style={{display: item.product.discount ? 'block' : 'none', color: 'red'}}>{reFormat(item.product.price)} $</h6>
                            )
                        }
                        <div style={{display: `${item.product.sale.status ? 'flex' : 'none'}`}}>{t('product.sale')}</div>
                    </div>
                    {
                        currency === 'UZS' ? (
                            <h3>{reFormat(Math.ceil(item.product.price * (100 - item.product.discount) / 100) * currencyValue)} {t('cart.som')}</h3>
                        ) : (
                            <h3>{reFormat(Math.ceil(item.product.price * (100 - item.product.discount) / 100))} $</h3>
                        )
                    }
                </div>
            </div>
            <div className='bottom'>
                <div className='left'>
                    <div onClick={() => dec(item._id)}>
                        <span>-</span>
                    </div>
                    <input type='text' value={item.count} readOnly />
                    <div onClick={() => inc(item._id)}>
                        <span>+</span>
                    </div>
                </div>
                <div className='right'>
                    <div onClick={() => addFavorites(item.product._id)}>
                        <FaHeart className='icon' />
                    </div>
                    <div onClick={() => deleteCart(item._id)}>
                        <MdDelete className='icon' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OneMobileCart