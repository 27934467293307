import axios from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactInputMask from 'react-input-mask'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import sellingPng from '../../img/selling.png'
import './selling.css'
import SellingModal from './SellingModal'

const Selling = () => {

    const token = window.localStorage.getItem('token')

    const navigate = useNavigate()

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const [ showModal, setShowModal ] = useState(false)

    const [ name, setName ] = useState('')
    const [ lastname, setLastname ] = useState('')
    const [ firmName, setFirmName ] = useState('')
    const [ firmType, setFirmType ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ inn, setInn ] = useState('')
    const [ address, setAddress ] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()

        const formData = {
            firstname: name,
            lastname,
            firmname: firmName,
            firmtype: firmType,
            number: phone,
            mail: email,
            inn,
            address,
            status: 'sell'
        }

        axios.post('/api/form', formData, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setShowModal(true)
            setName('')
            setLastname('')
            setFirmName('')
            setFirmType('')
            setPhone('')
            setEmail('')
            setInn('')
            setAddress('')
        }).catch(err => {
            navigate('/error')
        })
    }

    return (
        <div className='selling-component'>
            <SellingModal showModal={showModal} setShowModal={setShowModal} />
            <div className='containercha'>
                <img src={sellingPng} alt='' className='banner' />
                {
                    lang === 'uz' ? (
                        <h1>ZAP.UZ da {t('selling.be')}</h1>
                    ) : (
                        <h1>{t('selling.be')} ZAP.UZ</h1>
                    )
                }
                <h2>{t('selling.about')}</h2>
                <ol>
                    <li>{t('selling.bir')}</li>
                    <li>{t('selling.ikki')}</li>
                    <li>{t('selling.uch')}</li>
                    <li>{t('selling.turt')}</li>
                    <li>{t('selling.besh')}</li>
                    <li>{t('selling.olti')}</li>
                </ol>
                <h2>{t('selling.anceta')}</h2>
                <h3>{t('selling.true')}</h3>

                <form onSubmit={submitHandler}>
                    <div className='qator'>
                        <div>
                            <label>{t('cart.name')}</label>
                            <input type='text' value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div>
                            <label>{t('cart.surname')}</label>
                            <input type='text' value={lastname} onChange={(e) => setLastname(e.target.value)} required />
                        </div>
                    </div>
                    <div className='qator'>
                        <div>
                            <label>{t('selling.firma')}</label>
                            <input type='text' value={firmName} onChange={(e) => setFirmName(e.target.value)} required />
                        </div>
                        <div>
                            <label>{t('selling.type')}</label>
                            <input type='text' value={firmType} onChange={(e) => setFirmType(e.target.value)} required />
                        </div>
                    </div>
                    <div className='qator'>
                        <div>
                            <label>{t('cart.phone')}</label>
                            <ReactInputMask maskChar={null} name="phone" mask="+\9\9\8999999999" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </div>
                        <div>
                            <label>{t('cart.email')}</label>
                            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                    </div>
                    <div className='qator last'>
                        <div>
                            <label>{t('selling.inn')}</label>
                            <input type='text' value={inn} onChange={(e) => setInn(e.target.value)} required />
                        </div>
                    </div>
                    <div className='textarea-w'>
                        <label>{t('place-order.address')}</label>
                        <textarea value={address} onChange={(e) => setAddress(e.target.value)} required></textarea>
                    </div>
                    <div className='btn-w'>
                        <button type='submit'>{t('selling.send')}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Selling