import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import empfav from '../../img/empfav.png'
import empcart from '../../img/empcart.png'
import empcom from '../../img/empcom.png'
import './empty.css'
import { useTranslation } from 'react-i18next'

const Empty = ({ showEmpty, emptyImg, emptyH3 }) => {

    const { t } = useTranslation()

    const [ img, setImg ] = useState()
    const [ h3, setH3 ] = useState()

    useEffect(() => {
        if ( emptyImg === 'favorites' && emptyH3 === 'favorites' ) {
            setImg(empfav)
            setH3(t('empty.favorites'))
        } else if ( emptyImg === 'cart' && emptyH3 === 'cart' ) {
            setImg(empcart)
            setH3(t('empty.cart'))
        } else if ( emptyImg === 'comparison' && emptyH3 === 'comparison' ) {
            setImg(empcom)
            setH3(t('empty.comparison'))
        } 
    }, [])

    return (
        <div className={showEmpty ? 'empty-component empty-component-active' : 'empty-component'}>
            <img src={img} alt='' />
            <h3>{h3}</h3>
            <p>{t('empty.add')}</p>
            <Link to='/'>
                <button>{t('empty.continue')}</button>
            </Link>
        </div>
    )
}

export default Empty