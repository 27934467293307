import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import exchangerPng from '../../img/exchange.png'
import './returnExchange.css'

const ReturnExchange = () => {

    const { t } = useTranslation()

    return (
        <div className='make-order-component'>
            <div className='containercha'>
                <img src={exchangerPng} alt='' className='banner' />
                <h1>{t('return.h1')}</h1>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('return.p1')}</p>
                <Link to='/contact'>
                    <p>{t('return.p2')}</p>
                </Link>
                <p>{t('return.p3')}</p>
                <p>{t('return.p4')}</p>
                <p>{t('return.p5')}</p>
                <p>{t('return.p6')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('return.p7')}</p>
                <p>{t('return.p8')}</p>
                <p>{t('return.p9')}</p>
                <p>{t('return.p10')}</p>
                <p>{t('return.p11')}</p>
                <p>{t('return.p12')}</p>
                <p>{t('return.p13')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('return.p14')}</p>
                <p>{t('return.p15')}</p>
                <p>{t('return.p16')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('return.p17')}</p>
                <p>{t('return.p18')}</p>
                <p>{t('return.p19')}</p>
                <p>{t('return.p20')}</p>
                <p>{t('return.p21')}</p>
                <p>{t('return.p22')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('return.p23')}</p>
                <p>{t('return.p24')}</p>
                <p>{t('return.p25')}</p>
                <p>{t('return.p26')}</p>
                <p>{t('return.p27')}</p>
                <p>{t('return.p28')}</p>
                <p>{t('return.p29')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('return.p30')}</p>
                <p>{t('return.p31')}</p>
                <p>{t('return.p32')}</p>
                <p>{t('return.p33')}</p>
                <p>{t('return.p34')}</p>
                <p>{t('return.p35')}</p>
            </div>
        </div>
    )
}

export default ReturnExchange