import { Modal } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCloseOutline } from 'react-icons/io5'
import ReactInputMask from 'react-input-mask'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import undovPng from '../../img/undov.png'
import Empty from '../empty/Empty'
import SuccessOrderModal from '../fast-view/SuccessOrderModal'
import Loader from '../loader/Loader'
import './cart.css'
import OneCart from './OneCart'
import OneMobileCart from './OneMobileCart'

const Cart = ({ link, getCartCount, addFavorites }) => {

    const token = window.localStorage.getItem('token')

    const navigate = useNavigate()

    const { t } = useTranslation()

    const { currency } = useSelector(state => state.currencyReducer)

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course?.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [isModalVisible1, setIsModalVisible1] = useState(false)

    const [showEmpty, setShowEmpty] = useState(false)
    const [emptyImg, setEmptyImg] = useState("cart")
    const [emptyH3, setEmptyH3] = useState("cart")

    const [loading1, setLoading1] = useState(true)
    const loader1 = loading1 ? <Loader /> : null

    const [count, setCount] = useState(0)
    const [allCart, setAllCart] = useState([])
    const [allRate, setAllRate] = useState([])

    const inc = (cardId) => {
        const cardIdx = allCart.findIndex((i) => i._id === cardId);
        allCart[cardIdx].count = allCart[cardIdx].count + 1;
        setAllCart([...allCart])
    }

    const dec = (cardId) => {
        const cardIdx = allCart.findIndex((i) => i._id === cardId);
        const cartItemCount = allCart[cardIdx].count;
        if (cartItemCount > 1)
            allCart[cardIdx].count = allCart[cardIdx].count - 1;

        setAllCart([...allCart])
    }

    const checkedCart = (cardId) => {
        const cardIdx = allCart.findIndex((i) => i._id === cardId);
        allCart[cardIdx].checked = !allCart[cardIdx].checked;
        setAllCart([...allCart]);
    }

    const checkedMaster = (cardId) => {
        const cardIdx = allCart.findIndex((i) => i._id === cardId)
        allCart[cardIdx].master = !allCart[cardIdx].master
        setAllCart([...allCart]);
    }

    const getAllCart = () => {
        if (token) {
            axios.get("/api/card", {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }).then(res => {
                const { cards, rate, count } = res.data;
                if (count > 0) {
                    setShowEmpty(false)
                } else {
                    setShowEmpty(true)
                }
                setEmptyImg("cart")
                setEmptyH3("cart")
                setCount(count)
                setLoading1(false)
                cards.map((c) => {
                    c.checked = true;
                    return c
                });
                setAllCart(cards)
                setAllRate(rate)
            }).catch(err => {
                setEmptyImg("cart")
                setEmptyH3("cart")
                setCount(0)
                setShowEmpty(true)
                setLoading1(false)
                navigate('/error')
            })
        } else {
            setEmptyImg("cart")
            setEmptyH3("cart")
            setCount(0)
            setShowEmpty(true)
            setLoading1(false)
        }
    }

    const deleteCart = (id) => {
        axios.delete(`/api/card/${id}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            getAllCart()
            getCartCount()
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getAllCart()
        //eslint-disable-next-line
    }, [])

    let totalcount = 0;
    let totalPrice = 0;
    let totalDiscount = 0;
    let totalMaster = 0;

    allCart.forEach((c) => {
        const product = c.product;
        if (c.checked) {
            totalcount += c.count;
            totalPrice += c.count * product.price;
            totalDiscount += c.count * Math.floor((product.discount * product.price) / 100);
            totalMaster += c.master ? c.product.master : 0;
        }
    })

    const [showModal, setShowModal] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [phone, setPhone] = useState('')
    const [orderId, setOrderId] = useState('')
    const handlerOrder = (e) => {
        e.preventDefault()

        const originalCarts = allCart
            .filter((c) => c.checked)
            .map((c) => ({
                product: c.product._id,
                count: c.count,
                master: c.master ? c.product.master : 0,
                sum: c.count * Math.ceil((c.product.price * (100 - c.product.discount)) / 100),
                discount: c.count * Math.floor((c.product.price * c.product.discount) / 100)
            }));

        const body = {
            firstname,
            phone,
            totalCount: totalcount,
            totalDiscount,
            totalPrice,
            totalMaster,
            products: originalCarts
        }

        axios.post('/api/order/quick', body)
            .then((res) => {
                setIsModalVisible1(false)
                setShowModal(true)
                setOrderId(res.data.orderId)
            })
            .catch((err) => {
                navigate('/error')
            })
    }

    const handleOrderForLocal = () => {
        const originalCarts = allCart
            .filter((c) => c.checked)
            .map((c) => ({
                product: c.product._id,
                count: c.count,
                master: c.master ? c.product.master : 0,
                sum: c.count * Math.ceil((c.product.price * (100 - c.product.discount)) / 100),
                discount: c.count * Math.floor((c.product.price * c.product.discount) / 100)
            }));

        const body = {
            firstname,
            phone,
            totalCount: totalcount,
            totalDiscount,
            totalPrice,
            totalMaster,
            products: originalCarts
        }

        window.localStorage.setItem('order', JSON.stringify(body))
    }

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        getCurrencyValue()
    }, [])

    return (
        <div className='cart-component'>
            <SuccessOrderModal
                setShowModal={setShowModal}
                showModal={showModal}
                orderId={orderId}
            />
            <Modal
                centered
                title={null}
                visible={isModalVisible1}
                onOk={() => setIsModalVisible1(false)}
                onCancel={() => setIsModalVisible1(false)}
                footer={null}
                closable={false}
                className='fast-order-modal'
            >
                <form onSubmit={handlerOrder}>
                    <IoCloseOutline className='icon' onClick={() => setIsModalVisible1(false)} />
                    <h2>{t('product.fast-order')}</h2>
                    <div className='wrap'>
                        <label>{t('cart.name')}</label>
                        <input type='text' value={firstname} onChange={(e) => setFirstname(e.target.value)} required />
                    </div>
                    <div className='wrap'>
                        <label>{t('cart.phone')}</label>
                        <ReactInputMask maskChar={null} name="phone" mask="+\9\9\8999999999" placeholder='+998' value={phone} onChange={(e) => setPhone(e.target.value)} required />
                    </div>
                    <button onClick={handlerOrder}>{t('cart.send')}</button>
                    <Link to='/terms-of-use'>
                        <p>{t('cart.text')}</p>
                    </Link>
                </form>
            </Modal>

            <div className='containercha'>
                <div className='top'>
                    <h1>{t('cart.cart')}</h1>
                    <p>{t('cart.product')}
                        {
                            <span>({count} {t('cart.ta')})</span>
                        }
                    </p>
                </div>

                {
                    <Empty showEmpty={showEmpty} emptyImg={emptyImg} emptyH3={emptyH3} />
                }

                <div className='wrapper'>
                    <div className='all-cart-products'>

                        {/* COMP VERSION PRODUCT */}
                        {
                            allCart && Array.isArray(allCart) ? allCart.map((item, index) => {
                                return (
                                    <OneCart
                                        allRate={allRate}
                                        checkedCart={checkedCart}
                                        key={index}
                                        item={item}
                                        link={link}
                                        deleteCart={deleteCart}
                                        addFavorites={addFavorites}
                                        inc={inc}
                                        dec={dec}
                                        checkedMaster={checkedMaster}
                                        currency={currency}
                                        currencyValue={currencyValue}
                                    />
                                )
                            }) : ''
                        }



                        {/* MOBILE VERSION PRODUCT */}
                        {
                            allCart && Array.isArray(allCart) ? allCart.map((item, index) => {
                                return (
                                    <OneMobileCart
                                        allRate={allRate}
                                        checkedCart={checkedCart}
                                        key={index}
                                        item={item}
                                        link={link}
                                        deleteCart={deleteCart}
                                        addFavorites={addFavorites}
                                        inc={inc}
                                        dec={dec}
                                        checkedMaster={checkedMaster}
                                        currency={currency}
                                        currencyValue={currencyValue}
                                    />
                                )
                            }) : ''
                        }

                        <div className='last' style={{ display: count === 0 ? 'none' : 'flex' }}>
                            <img src={undovPng} alt='' />
                            <p>{t('cart.operator')}</p>
                        </div>
                        {loader1}

                    </div>
                    <div className='right-data' style={{ display: count === 0 ? 'none' : 'flex' }}>
                        <div>
                            <p>{t('cart.product')} ({totalcount} {t('cart.ta')})</p>
                            {
                                currency === 'UZS' ? (
                                    <p>{reFormat(totalPrice * currencyValue)} {t('cart.som')}</p>
                                ) : (
                                    <p>{reFormat(totalPrice)} $</p>
                                )
                            }
                        </div>
                        <div>
                            <p>{t('cart.sale')}</p>
                            {
                                currency === 'UZS' ? (
                                    <p style={{ color: 'red' }}>-{reFormat(totalDiscount * currencyValue)} {t('cart.som')}</p>
                                ) : (
                                    <p style={{ color: 'red' }}>-{reFormat(totalDiscount)} $</p>
                                )
                            }
                        </div>
                        <div>
                            <p>{t('cart.all-mas')}</p>
                            <p>{reFormat(totalMaster)} {t('cart.som')}</p>
                        </div>
                        <div>
                            <h3>{t('cart.all')}</h3>
                            {
                                currency === 'UZS' ? (
                                    <h3>{reFormat((totalPrice * currencyValue - totalDiscount * currencyValue) + totalMaster)} {t('cart.som')}</h3>
                                ) : (
                                    <h3>{reFormat(totalPrice - totalDiscount)} $</h3>
                                )
                            }
                        </div>
                        <button onClick={() => setIsModalVisible1(true)} disabled={totalcount > 0 ? false : true}>{t('product.fast-order')}</button>
                        <Link to='/place-order' onClick={handleOrderForLocal}>
                            <button disabled={totalcount > 0 ? false : true}>{t('cart.place')}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart