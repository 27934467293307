import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import './catalog.css'

const Catalog = (props) => {

    const { lang } = useSelector(state => state.nowLanguage)

    const { catalogShow, setCatalogShow, link } = props

    const navigate = useNavigate()

    const [banner, setBanner] = useState('')
    const getBanner = () => {
        axios.get(`/api/banner?status=menu`).then(res => {
            setBanner(res.data.banners.length !== 0 ? res.data.banners[0] : '')
        }).catch(err => {
            navigate('/error')
        })
    }

    // CATEGORY
    const [allCategories, setAllCategories] = useState([])
    const getCategories = () => {
        axios.get(`/api/category`, {
            params: {
                ref: null
            },
        }).then(res => {
            setAllCategories(res.data.categories)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [allSubCategories, setAllSubCategories] = useState([])
    const [allSubSubCategories, setAllSubSubCategories] = useState([])
    const getSubCategories = (id) => {
        axios.get(`/api/category/menu`, {
            params: {
                ref: id
            },
        }).then(res => {
            setAllSubCategories(res.data.categories)
            setAllSubSubCategories(res.data.subcategories)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getBanner()
        getCategories()
        //eslint-disable-next-line
    }, [])

    return (
        <div className={catalogShow ? 'catalog-component catalog-component-active' : 'catalog-component'}>
            <div className='left'>
                <div className='close-w' onClick={() => setCatalogShow(false)}>
                    <IoCloseSharp className='icon' />
                </div>

                <div className='column-catalog'>
                    {
                        allCategories ? allCategories.map((item, index) => {
                            return (
                                <Link to={`/all-products?main=${item._id}?status=${item.status}`} key={index} onMouseEnter={() => getSubCategories(item._id)} onClick={() => setCatalogShow(false)}>{item.name[`${lang}`]}</Link>
                            )
                        }) : ''
                    }
                </div>

                <div className='system-w first-system-w'>
                    {
                        allSubCategories ? allSubCategories.map((item, index) => {
                            return (
                                <div key={index} className=''>
                                    <Link to={`/all-products?sub=${item._id}?status=${item.status}`} onClick={() => setCatalogShow(false)} className='h6'>
                                        <h6>{item.name[`${lang}`]}</h6>
                                    </Link>
                                    {
                                        allSubSubCategories ? allSubSubCategories.filter(c => c.ref === item._id).map((item1, index1) => {
                                            return (
                                                <Link to={`/all-products?subsub=${item1._id}?status=${item1.status}`} onClick={() => setCatalogShow(false)} key={index1}>{item1.name[`${lang}`]}</Link>
                                            )
                                        }) : ''
                                    }
                                </div>
                            )
                        }) : ''
                    }
                </div>

                {
                    banner ? (
                        <div className='img-wrap'>
                            <Link onClick={() => setCatalogShow(false)} to={banner.product === null ? banner.brand !== null ? `/all-products?brand=${banner.brand._id}` : '/' : `/product/${banner.product?._id}`}>
                                <img src={`${link}/${banner?.photo ? banner?.photo[`${lang}`] : ''}`} alt='' className='catalog-banner' />
                            </Link>
                        </div>
                    ) : null
                }
            </div>
            <div className='right' onClick={() => setCatalogShow(false)}></div>
        </div>
    )
}

export default Catalog