import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiEditAlt } from 'react-icons/bi'
import { HiOutlineLogout } from 'react-icons/hi'
import { IoIosArrowForward } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader'
import './myOrders.css'
import { BASE_URL } from '../../App'

const MyOrders = ({ userData, setRend, rend, setFavoritesCount, setCartCount, setComparisonCount }) => {

    const token = window.localStorage.getItem('token')
    const { currency } = useSelector(state => state.currencyReducer)

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const [loading1, setLoading1] = useState(true)
    const loader1 = loading1 ? <Loader /> : null

    const navigate = useNavigate()
    const logout = () => {
        window.localStorage.removeItem('token')
        navigate('/')
        setRend(!rend)
        setFavoritesCount(0)
        setCartCount(0)
        setComparisonCount(0)
    }

    const [show, setShow] = useState('all')

    const [allOrders, setAllOrders] = useState([])
    const getAllOrders = () => {
        axios.get('/api/order', {
            params: {
                status: show === 'all' || show === 'ispaid' ? null : show,
                isPaid: show === 'ispaid' ? true : null
            },
            headers: {
                'authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                setAllOrders(res.data.orders)
            }).catch(err => {
                navigate('/error')
            }).finally(fin => setLoading1(false))
    }

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course?.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getAllOrders()
    }, [show])

    useEffect(() => {
        getCurrencyValue()
    }, [])

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    return (
        <div className='my-orders-component'>
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/profile'>{t('home.profile')}</Link> / <span>{t('profile.my-orders')}</span></p>
                </div>

                <div className='wrapper'>
                    <div className='left-user-menu'>
                        <div className='user-top'>
                            <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
                            <div className='name-phone'>
                                <h4>{userData ? userData.firstname : ''} {userData ? userData.lastname : ''}</h4>
                                <p>{userData ? userData.number : ''}</p>
                            </div>
                            <Link to='/profile' className='icon-wrap'>
                                <BiEditAlt className='icon' />
                            </Link>
                        </div>
                        <div className='links-wrapper'>
                            <Link to='/my-orders'>
                                <h5>{t('profile.my-orders')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-address'>
                                <h5>{t('profile.my-address')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-messages'>
                                <h5>{t('profile.sms')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-comments'>
                                <h5>{t('profile.my-comments')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/favorites'>
                                <h5>{t('navbar.favorites')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/comparison'>
                                <h5>{t('profile.comparison-list')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                        </div>
                        <div className='logout-wrap' style={{ cursor: 'pointer' }} onClick={logout}>
                            <HiOutlineLogout className='icon' />
                            <h5>{t('profile.logout')}</h5>
                        </div>
                    </div>

                    <div className='right-layout'>
                        <h1>{t('profile.my-orders')}</h1>
                        <div className='select'>
                            <h6>{t('my-orders.show')}</h6>
                            <select value={show} onChange={(e) => setShow(e.target.value)}>
                                <option value='all'>{t('my-orders.all')}</option>
                                <option value='wait'>{t('my-orders.wait')}</option>
                                <option value='confirm'>{t('my-orders.confirm')}</option>
                                <option value='ispaid'>{t('my-orders.paid')}</option>
                                <option value='process'>{t('my-orders.process')}</option>
                                <option value='delivery'>{t('my-orders.delivered')}</option>
                                <option value='cancel'>{t('my-orders.cancelled')}</option>
                            </select>
                        </div>
                        <div className='all-orders'>
                            {loader1}
                            {
                                allOrders && Array.isArray(allOrders) ? allOrders.map((item, index) => {
                                    const date = new Date(item.createdAt)
                                    return (
                                        <div className='product' key={index}>
                                            <div className='left'>
                                                <h3>Заказ № {item.orderId}</h3>
                                                <Link to={item._id} className='pod'>{t('my-orders.more')}</Link>
                                                <div className='oj' style={{ display: item.status === 'wait' ? 'block' : 'none' }}>
                                                    <div></div>
                                                    <span>{t('my-orders.wait')}</span>
                                                </div>
                                                <div className='podt' style={{ display: item.status === 'confirm' ? 'block' : 'none' }}>
                                                    <div></div>
                                                    <span>{t('my-orders.confirm')}</span>
                                                </div>
                                                <div className='opl' style={{ display: item.isPaid === true ? 'block' : 'none' }}>
                                                    <span>{t('my-orders.paid')}</span>
                                                </div>
                                                <div className='prot' style={{ display: item.status === 'process' ? 'block' : 'none' }}>
                                                    <div></div>
                                                    <span>{t('my-orders.process')}</span>
                                                </div>
                                                <div className='dos' style={{ display: item.status === 'delivery' ? 'block' : 'none' }}>
                                                    <div></div>
                                                    <span>{t('my-orders.delivered')}</span>
                                                </div>
                                                <div className='otm' style={{ display: item.status === 'cancel' ? 'block' : 'none' }}>
                                                    <div></div>
                                                    <span>{t('my-orders.cancelled')}</span>
                                                </div>
                                            </div>
                                            <div className='right'>
                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.order-time')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{date.getDate()}.{date.getMonth()}.{date.getFullYear()} {date.getHours()}:{date.getMinutes()}</p>
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.number-product')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{item.totalCount} {t('cart.ta')}</p>
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.total-cost')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        {currency === 'UZS' ? (
                                                            <p>{reFormat(item.totalPrice * currencyValue)} {t('cart.som')}</p>
                                                        ) : (
                                                            <p>{reFormat(item.totalPrice)} $</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.sale')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s' style={{ color: 'red' }}>
                                                        {
                                                            currency === 'UZS' ? (
                                                                <p>-{reFormat(item.totalDiscount * currencyValue)} {t('cart.som')}</p>
                                                            ) : (
                                                                <p>-{item.totalDiscount} $</p>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('cart.all')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s' style={{ color: 'green' }}>
                                                        {
                                                            currency === 'UZS' ? (
                                                                <p>{reFormat((item.totalPrice - item.totalDiscount) * currencyValue)} {t('cart.som')}</p>
                                                            ) : (
                                                                <p>{item.totalPrice - item.totalDiscount} $</p>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    item.delivery ? (
                                                        <div className='qator'>
                                                            <div className='left-s'>
                                                                <p>{t('my-orders.order-cost')}</p>
                                                                <div></div>
                                                            </div>
                                                            <div className='right-s'>
                                                                <p>{item.delivery} {t('cart.som')}</p>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }

                                                {
                                                    item.pay ? (
                                                        <div className='qator'>
                                                            <div className='left-s'>
                                                                <p>{t('my-orders.type')}</p>
                                                                <div></div>
                                                            </div>
                                                            <div className='right-s'>
                                                                <p>{item.pay}</p>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.type-del')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{t('my-orders.zap')}</p>
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.face')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{item.firstname}</p>
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.phone')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{item.phone}</p>
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.address')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{item.region[`${lang}`]} {item.district[`${lang}`]} {item.address}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }) : ''
                            }
                            {
                                allOrders?.length === 0 ? (
                                    <div>Mavjud emas</div>
                                ) : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyOrders