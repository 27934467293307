import React from 'react'
import { useTranslation } from 'react-i18next'
import { GrClose } from 'react-icons/gr'
import truePng from '../../img/true.png'
import './sellingModal.css'

const SellingModal = (props) => {

    const { t } = useTranslation()

    const { showModal, setShowModal } = props

    return (
        <>
            <div className={showModal ? 'modals-component modals-component-active' : 'modals-component'} onClick={() => setShowModal(false)}></div>
            <div className={showModal ? 'comment-inner-modal comment-inner-modal-active' : 'comment-inner-modal'}>
                <GrClose className='icon' onClick={() => setShowModal(false)} />
                <div>
                    <img src={truePng} alt='' />
                    <h3>{t('selling.succes')}</h3>
                </div>
            </div>
        </>
    )
}

export default SellingModal