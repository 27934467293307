import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import carServicesPng from '../../img/carServices.png'
import './carServices.css'

const CarServices = () => {

    const { t } = useTranslation()

    return (
        <div className='car-services-component'>
            <div className='containercha'>
                <img src={carServicesPng} alt='' className='banner' />
                <h1>{t('car-services.h1')}</h1>
                
                <h2>{t('car-services.h2-1')}</h2>
                <p>{t('car-services.p-1')}</p>
                <p><span>ZAP.UZ</span> {t('car-services.p-2')}</p>
                <p>{t('car-services.p-3')}</p>
                <p>{t('car-services.p-4')}</p>

                <h2>{t('car-services.h2-2')}</h2>
                <p><span>{t('car-services.h2-p1-span')}</span> {t('car-services.h2-p1')}</p>
                <p><span>{t('car-services.h2-p2-span')}</span> {t('car-services.h2-p2')}</p>
                <p><span>{t('car-services.h2-p3-span')}</span> {t('car-services.h2-p3')}</p>
                <p><span>{t('car-services.h2-p4')}</span></p>

                <h2>{t('car-services.h2-3')}</h2>
                <p><span>{t('car-services.h2-3-p1-span')}</span> {t('car-services.h2-3-p1')}</p>
                <p><span>{t('car-services.h2-3-p2-span')}</span> {t('car-services.h2-3-p2')}</p>
                <p><span>{t('car-services.h2-3-p3-span')}</span> {t('car-services.h2-3-p3')}</p>
                <p><span>{t('car-services.h2-3-p4-span')}</span> {t('car-services.h2-3-p4')}</p>
                <p><span>{t('car-services.h2-3-p5-span')}</span> {t('car-services.h2-3-p5')}</p>

                <Link to='/wholesale-buyers'>
                    <p className='last-p'>{t('car-services.last-p')}</p>
                </Link>
            </div>
        </div>
    )
}

export default CarServices