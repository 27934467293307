import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Brands from '../brands/Brands'
import News from '../news/News'
import PopularProducts from '../popular-products/PopularProducts'
import HomeBanner from './banner/HomeBanner'
import './home.css'

const Home = (props) => {

    const { link, auth, addCart, addFavorites, addComparison, addSeen } = props
    const { t } = useTranslation()

    return (
        <div className='containercha home-component'>
            <HomeBanner link={link} />
            <PopularProducts link={link} auth={auth} addFavorites={addFavorites} addCart={addCart} addComparison={addComparison} addSeen={addSeen} />

            <div className='t-w'>
                <h1>{t('footer.news')}</h1>
                <Link to='/news-blog'>{t('home.see')}</Link>
            </div>
            <News link={link} />

            <div className='t-w'>
                <h1>{t('home.brands')}</h1>
                <Link to='/all-brands'>{t('home.see')}</Link>
            </div>
            <Brands link={link} />
        </div>
    )
}

export default Home