import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./extraImages.css";

import { Pagination, Navigation } from "swiper";

export default function ExtraImages({ images, link, setActiveIndex }) {

  return (
    <div className="extra-images-wrapper">
      <Swiper
        slidesPerView={3}
        centeredSlides={true}
        spaceBetween={30}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        onActiveIndexChange={(e) => setActiveIndex(e.activeIndex)}
      >
        {
          images && Array.isArray(images) ? images.map((item, index) => {
            return (
              <SwiperSlide key={index} style={{ backgroundImage: `url(${link}/${item})` }}></SwiperSlide>
            )
          }) : ''
        }
      </Swiper>

    </div>
  );
}
