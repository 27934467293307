import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiFillHeart } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Empty from '../empty/Empty'
import Loader from '../loader/Loader'
import './favorites.css'

const Favorites = ({ link, getFavoritesCount, addCart }) => {

    const token = window.localStorage.getItem('token')

    const navigate = useNavigate()

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)
    const { currency } = useSelector(state => state.currencyReducer)

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course?.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [showEmpty, setShowEmpty] = useState(false)
    const [emptyImg, setEmptyImg] = useState("favorites")
    const [emptyH3, setEmptyH3] = useState("favorites")

    const [loading1, setLoading1] = useState(true)
    const loader1 = loading1 ? <Loader /> : null

    const [count, setCount] = useState()
    const [allFavorites, setAllFavorites] = useState([])

    const getAllFavorites = () => {
        if (token) {
            axios.get("/api/favourite", {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }).then(res => {
                if (res.data.count > 0) {
                    setShowEmpty(false)
                } else {
                    setShowEmpty(true)
                }
                setEmptyImg("favorites")
                setEmptyH3("favorites")
                setCount(res.data.count)
                setLoading1(false)
                setAllFavorites(res.data.favourites)
            }).catch(err => {
                setEmptyImg("favorites")
                setEmptyH3("favorites")
                setCount(0)
                setShowEmpty(true)
                setLoading1(false)
                navigate('/error')
            })
        } else {
            setEmptyImg("favorites")
            setEmptyH3("favorites")
            setCount(0)
            setShowEmpty(true)
            setLoading1(false)
        }
    }

    const deleteFavorite = (id) => {
        axios.delete(`/api/favourite/${id}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            getAllFavorites()
            getFavoritesCount()
        }).catch(err => {
            navigate('/error')
        })
    }

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        getAllFavorites()
        getCurrencyValue()
        //eslint-disable-next-line
    }, [])

    return (
        <div className='favorites-component'>
            <div className='containercha'>
                <div className='top'>
                    <h1>{t('navbar.favorites')}</h1>
                    <p>{t('cart.product')} <span>({count} {t('cart.ta')})</span></p>
                </div>

                {<Empty showEmpty={showEmpty} emptyImg={emptyImg} emptyH3={emptyH3} />}
                {loader1}
                <Row>
                    {
                        allFavorites && Array.isArray(allFavorites) ? allFavorites.map((item, index) => {
                            return (
                                <Col key={index} lg={2} md={3} sm={4} xs={6}>
                                    <div className='prod'>
                                        <div className='bg' style={{ backgroundImage: `url(${link}/${item.product.photo})` }}>
                                            <div className='left-top' style={{ display: `${item.product.discount === null ? 'none' : 'flex'}` }}>
                                                <span>{`-${item.product.discount}%`}</span>
                                            </div>
                                            <div className='right-top' onClick={() => deleteFavorite(item._id)}>
                                                <AiFillHeart className='icon' />
                                            </div>
                                        </div>
                                        <p onClick={() => navigate(`/product/${item?.product?._id}`)}>{item.product.brand.name} / {item.product.name[`${lang}`]} / {item.product.partner.name} {item.product.model.name}</p>
                                        {
                                            currency === 'UZS' ? (
                                                <h4>{reFormat(Math.ceil(item.product.price * (100 - item.product.discount) / 100) * currencyValue)} {t('cart.som')}</h4>
                                            ) : (
                                                <h4>{reFormat(Math.ceil(item.product.price * (100 - item.product.discount) / 100))} $</h4>
                                            )
                                        }
                                        <div className='sale'>
                                            {
                                                currency === 'UZS' ? (
                                                    <h6 style={{ display: item.product.discount ? 'block' : 'none', color: 'red' }}>{reFormat(item.product.price * currencyValue)} {t('cart.som')}</h6>
                                                ) : (
                                                    <h6 style={{ display: item.product.discount ? 'block' : 'none', color: 'red' }}>{reFormat(item.product.price)} $</h6>
                                                )
                                            }
                                        </div>
                                        <button onClick={() => addCart(item.product._id)}>{t('comparison.add-cart')}</button>
                                    </div>
                                </Col>
                            )
                        }) : ''
                    }
                </Row>
            </div>
        </div>
    )
}

export default Favorites