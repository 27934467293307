import React from 'react'
import { useTranslation } from 'react-i18next'
import aboutUsPng from '../../img/aboutUs.png'
import './aboutUs.css'

const AboutUs = () => {

    const { t } = useTranslation()

    return (
        <div className='make-order-component'>
            <div className='containercha'>
                <img src={aboutUsPng} alt='' className='banner' />
                <h1>{t('about.h1')}</h1>

                <h2>{t('about.h2-1')}</h2>
                <p>{t('about.p1')}</p>
                <p>{t('about.p2')}</p>
                <p>{t('about.p3')}</p>
                <p>{t('about.p4')}</p>
                <p>{t('about.p5')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('about.p6')}</p>
                <p>{t('about.p7')}</p>
                <p>{t('about.p8')}</p>
                <p>{t('about.p9')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('about.p10')}</p>
                <p>{t('about.p11')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('about.p12')}</p>
                <p>{t('about.p13')}</p>

                <p style={{color: '#212121', fontWeight: '600'}}>{t('about.p14')}</p>
                <p>{t('about.p15')}</p>
                <p>{t('about.p16')}</p>
                <p>{t('about.p17')}</p>
                <p>{t('about.p18')}</p>
                <p>{t('about.p19')}</p>
                <p>{t('about.p20')}</p>
                <p>{t('about.p21')}</p>
                <p>{t('about.p22')}</p>

                <p>{t('about.p23')}</p>

                <p>{t('about.p24')}</p>

                <h2 style={{marginTop: '40px'}}>{t('about.h2-2')}</h2>
            </div>
        </div>
    )
}

export default AboutUs