import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi'
import { HiOutlineLogout } from 'react-icons/hi'
import { IoIosArrowForward } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Loader from '../loader/Loader'
import './myOrders.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../App'

const OneMyOrder = ({ link, userData, setRend, rend, setFavoritesCount, setCartCount, setComparisonCount }) => {

    const token = window.localStorage.getItem('token')
    const params = useParams()
    const id = params.id
    const { currency } = useSelector(state => state.currencyReducer)

    const { t } = useTranslation()

    const [loading1, setLoading1] = useState(true)
    const loader1 = loading1 ? <Loader /> : null

    const navigate = useNavigate()
    const logout = () => {
        window.localStorage.removeItem('token')
        navigate('/')
        setRend(!rend)
        setFavoritesCount(0)
        setCartCount(0)
        setComparisonCount(0)
    }

    const [innerProducs, setInnerProducts] = useState([])
    const [order, setOrder] = useState()
    const getOrderById = () => {
        axios.get(`/api/order`, {
            params: {
                id
            },
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setInnerProducts(res.data.orders.products)
            setOrder(res.data.orders)
        }).catch(err => {
            navigate('/error')
        }).finally(fin => setLoading1(false))
    }

    const date = new Date(order && order.createdAt ? order.createdAt : '')

    useEffect(() => {
        getOrderById()
    }, [])

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course?.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getCurrencyValue()
    }, [])

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    return (
        <div className='my-orders-component'>
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/profile'>{t('home.profile')}</Link> / <Link to='/my-orders'>{t('profile.my-orders')}</Link> / <span>{order?.orderId}</span></p>
                </div>

                <div className='wrapper'>
                    <div className='left-user-menu'>
                        <div className='user-top'>
                            <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
                            <div className='name-phone'>
                                <h4>{userData ? userData.firstname : ''} {userData ? userData.lastname : ''}</h4>
                                <p>{userData ? userData.number : ''}</p>
                            </div>
                            <Link to='/profile' className='icon-wrap'>
                                <BiEditAlt className='icon' />
                            </Link>
                        </div>
                        <div className='links-wrapper'>
                            <Link to='/my-orders'>
                                <h5>{t('profile.my-orders')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-address'>
                                <h5>{t('profile.my-address')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-messages'>
                                <h5>{t('profile.sms')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-comments'>
                                <h5>{t('profile.my-comments')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/favorites'>
                                <h5>{t('navbar.favorites')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/comparison'>
                                <h5>{t('profile.comparison-list')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                        </div>
                        <div className='logout-wrap' style={{ cursor: 'pointer' }} onClick={logout}>
                            <HiOutlineLogout className='icon' />
                            <h5>Выйти</h5>
                        </div>
                    </div>

                    <div className='right-layout'>
                        <h1>{t('profile.my-orders')}</h1>
                        <div className='top'>
                            <div className='left'>
                                <h2>Заказ №{order?.orderId}</h2>
                                <p>{t('my-orders.order-time')} {date.getDate()}:{date.getMonth()}:{date.getFullYear()} {date.getHours()}:{date.getMinutes()}</p>
                                <p>{t('my-orders.number-product')} {order ? order.totalCount : ''} {t('cart.ta')}</p>
                            </div>
                            <div className='right'>
                                <div className='oj' style={{ display: order && order.status === 'wait' ? 'block' : 'none' }}>
                                    <div></div>
                                    <span>{t('my-orders.wait')}</span>
                                </div>
                                <div className='podt' style={{ display: order && order.status === 'confirm' ? 'block' : 'none' }}>
                                    <div></div>
                                    <span>{t('my-orders.confirm')}</span>
                                </div>
                                <div className='opl' style={{ display: order && order.isPaid === true ? 'block' : 'none' }}>
                                    <span>{t('my-orders.paid')}</span>
                                </div>
                                <div className='prot' style={{ display: order && order.status === 'process' ? 'block' : 'none' }}>
                                    <div></div>
                                    <span>{t('my-orders.process')}</span>
                                </div>
                                <div className='dos' style={{ display: order && order.status === 'delivery' ? 'block' : 'none' }}>
                                    <div></div>
                                    <span>{t('my-orders.delivered')}</span>
                                </div>
                                <div className='otm' style={{ display: order && order.status === 'cancel' ? 'block' : 'none' }}>
                                    <div></div>
                                    <span>{t('my-orders.cancelled')}</span>
                                </div>
                            </div>
                        </div>
                        <div className='all-orders'>
                            {loader1}
                            {
                                innerProducs && Array.isArray(innerProducs) ? innerProducs.map((item, index) => {
                                    return (
                                        <div className='product' key={index}>
                                            <div className='left'>
                                                <div className='bg' style={{ backgroundImage: `url(${link}/${item.product.photo})` }}></div>
                                            </div>
                                            <div className='right'>
                                                <h4>{item.product.brand.name} / {item.product.name.uz} / {item.product.partner.name} {item.product.model.name}</h4>
                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('product.kod')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{item.product.productCode}</p>
                                                    </div>
                                                </div>
                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.number-product')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{item.count} {t('cart.ta')}</p>
                                                    </div>
                                                </div>
                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.total-cost')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        {
                                                            currency === 'UZS' ? (
                                                                <p>{reFormat((item.sum + item.discount) * currencyValue)} {t('cart.som')}</p>
                                                            ) : (
                                                                <p>{(item.sum + item.discount)} $</p>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('my-orders.sale')} </p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s' style={{ color: 'red' }}>
                                                        {
                                                            currency === 'UZS' ? (
                                                                <p>-{reFormat(item.discount * currencyValue)} {t('cart.som')}</p>
                                                            ) : (
                                                                <p>-{item.discount} $</p>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('cart.all')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s' style={{ color: 'green' }}>
                                                        {
                                                            currency === 'UZS' ? (
                                                                <p>{reFormat(item.sum * currencyValue)} {t('cart.som')}</p>
                                                            ) : (
                                                                <p>{item.sum} $</p>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('product.country')}</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{item.product.country.uz}</p>
                                                    </div>
                                                </div>

                                                <div className='qator'>
                                                    <div className='left-s'>
                                                        <p>{t('footer.master')}:</p>
                                                        <div></div>
                                                    </div>
                                                    <div className='right-s'>
                                                        <p>{item.master} {t('cart.som')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OneMyOrder