import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaCalendarAlt } from 'react-icons/fa'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './oneMessage.css'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const OneMessage = ({ link }) => {

    const token = window.localStorage.getItem('token')
    const { lang } = useSelector(state => state.nowLanguage)

    const { t } = useTranslation()

    const navigate = useNavigate()

    const params = useParams()
    const id = params.id

    const [messageById, setMessageById] = useState()
    const getMessageById = () => {
        axios.get(`/api/notification/${id}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setMessageById(res.data.notification)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getMessageById()
    }, [])

    const date = new Date(messageById ? messageById.createdAt : '')

    return (
        <div className='one-message-component'>
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/profile'>{t('home.profile')}</Link> / <span>{t('profile.sms')}</span></p>
                </div>
                <div className='bg-img' style={{ backgroundImage: `url(${link}/${messageById ? messageById.image : ''})` }}></div>
                <div className='bg-img-bottom'>
                    <div>
                        <FaCalendarAlt className='icon' />
                        <p className='pp'>{date.getDate()}-{date.getMonth() + 1}-{date.getFullYear()}</p>
                        <p>{date.getHours()}:{date.getMinutes()}:{date.getSeconds()}</p>
                    </div>
                </div>
                <h1>{messageById ? messageById.title[`${lang}`] : ''}</h1>
                <p>{messageById ? messageById.text[`${lang}`] : ''}</p>
            </div>
        </div>
    )
}

export default OneMessage