import React from 'react'
import { useTranslation } from 'react-i18next'
import { GrClose } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import truePng from '../../img/true.png'
import './commentModal.css'

const CommentModal = (props) => {

    const navigate = useNavigate()

    const { t } = useTranslation()

    const { showModal, setShowModal, productId } = props

    const cancel = () => {
        setShowModal(false)
        navigate(`/product/${productId}`)
    }

    return (
        <>
            <div className={showModal ? 'modals-component modals-component-active' : 'modals-component'} onClick={cancel}></div>
            <div className={showModal ? 'comment-inner-modal comment-inner-modal-active' : 'comment-inner-modal'}>
                <GrClose className='icon' onClick={cancel} />
                <div>
                    <img src={truePng} alt='' />
                    <h3>{t('cart.success-comment')}</h3>
                </div>
            </div>
        </>
    )
}

export default CommentModal