import React from 'react'
import { useTranslation } from 'react-i18next'
import advertisementPng from '../../img/advertisement.png'
import { Link } from 'react-router-dom'
import './advertisement.css'

const Advertisement = () => {

    const { t } = useTranslation()

    return (
        <div className='make-order-component'>
            <div className='containercha'>
                <img src={advertisementPng} alt='' className='banner' />
                <h1>{t('advert.h1')}</h1>

                <h2>{t('advert.h2-1')}</h2>
                <p>{t('advert.p1')}</p>
                <p>{t('advert.p2')}</p>
                <p>{t('advert.p3')}</p>

                <p style={{fontWeight: '600', color: '#212121'}}>{t('advert.p4')}</p>
                <p>{t('advert.p5')}</p>
                <p>{t('advert.p6')}</p>
                <p>{t('advert.p7')}</p>
                <p>{t('advert.p8')}</p>
                <p>{t('advert.p9')}</p>
                <p>{t('advert.p10')}</p>

                <p style={{fontWeight: '600', color: '#212121'}}>{t('advert.p11')}</p>
                <p>{t('advert.p12')}</p>
                <p>{t('advert.p13')}</p>
                <p>{t('advert.p14')}</p>
                <p>{t('advert.p15')}</p>
                <p>{t('advert.p16')}</p>

                <Link to='/contact'>
                    <p>{t('advert.p17')}</p>
                </Link>
                <h2 style={{marginTop: '40px'}}>{t('advert.h2-2')}</h2>
            </div>
        </div>
    )
}

export default Advertisement