import React, { useEffect, useState } from 'react'
import './continueProduct.css'
import { Rating } from 'react-simple-star-rating'
import InnerOneProduct from './InnerOneProduct'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../App'

const ContinueProduct = ({ productId, productById, allSimilarProducts, link, allSimilarAdsProducts, commentsCount, allComments, auth, currency, currencyValue, addSeen }) => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    const [allCharacter, setAllCharacter] = useState([])
    const getCharacters = () => {
        axios.get(`/api/character/${productId}`).then(res => {
            setAllCharacter(res.data.character?.characters)
        }).catch(err => {
            navigate('/error')
        })
    }

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    const [allSeen, setAllSeen] = useState([])
    useEffect(() => {
        getCharacters()
        const allSeenId = window.localStorage.getItem('seen') ? JSON.parse(window.localStorage.getItem('seen')) : []
        setAllSeen(allSeenId)
        //eslint-disable-next-line
    }, [productId])

    return (
        <div className='continue-product'>
            <div className='left-side'>
                <div>
                    <h6>{t('product.descr')}</h6>
                    <p>{productById && productById.text ? productById.text[`${lang}`] : ''}</p>
                </div>
                <div>
                    <h6>{t('product.char')}:</h6>
                    {
                        allCharacter && Array.isArray(allCharacter) ? allCharacter.map((item, index) => {
                            return (
                                <div className='qator' key={index}>
                                    <p>{item.key[`${lang}`]}:</p>
                                    <p>{item.value[`${lang}`]}</p>
                                </div>
                            )
                        }) : ''
                    }
                </div>
                <div>
                    <h6>{t('product.coms')}</h6>
                    <span>{t('product.coms')} {commentsCount}</span>
                    <Link to={auth ? 'leave-review' : '/register'} className='link'>
                        <button>{t('product.write')}</button>
                    </Link>
                </div>
                <div className='all-comments'>
                    {
                        allComments && Array.isArray(allComments) ? allComments.map((item, index) => {
                            const date = new Date(item.date)
                            return (
                                <div className='comment' key={index} >
                                    <div className='topp'>
                                        <div className='ll'>
                                            <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${item.user.photo})` }}></div>
                                            <div>
                                                <h4>{item.user.firstname} {item.user.lastname}</h4>
                                                <Rating ratingValue={item.rate * 20} allowHover={false} size={24} readonly />
                                            </div>
                                        </div>
                                        <span>{date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-{date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-{date.getFullYear()} {date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:{date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}</span>
                                    </div>
                                    <p>{item.comment}</p>
                                </div>
                            )
                        }) : ''
                    }
                    <div className='linear-bottom'></div>
                </div>
            </div>
            <div className='right-side'>
                {
                    allSimilarAdsProducts ? (
                        <div className='h1-link-wrap'>
                            <h1>{t('product.adver')}</h1>
                            {/* <Link to='/'>{t('home.see')}</Link> */}
                        </div>
                    ) : null
                }
                <div className='wrapper-prod'>
                    {
                        allSimilarAdsProducts && Array.isArray(allSimilarAdsProducts) ? allSimilarAdsProducts.map((item, index) => {
                            return (
                                <div className='one-product-component' style={{ display: item.isActive === false ? 'none' : 'block' }} key={index}>
                                    <Link to={`/product/${item._id}`} onClick={() => addSeen(item._id)}>
                                        <div className='bg' style={{ backgroundImage: `url(${link}/${item ? item.photo : ''})` }}>
                                            <div className='left-top' style={{ display: `${item.discount === null ? 'none' : 'flex'}` }}>
                                                <span>{`-${item ? item.discount : ''}%`}</span>
                                            </div>
                                            <div className='right-top' style={{ display: `${item.top ? 'flex' : 'none'}` }}>
                                                <div></div>
                                                <span>{t('product.lider')}</span>
                                            </div>
                                            <div className='left-bottom' style={{ display: `${item.status === 'advert' ? 'flex' : 'none'}` }}>
                                                <span>{t('product.advert')}</span>
                                            </div>
                                            <div className='left-bottom left-bottom-second' style={{ display: `${item.status === 'new' ? 'flex' : 'none'}` }}>
                                                <span>{t('product.new')}</span>
                                            </div>
                                            <div className='right-bottom' style={{ display: `${(item.sale && item.sale.status) ? 'flex' : 'none'}` }}>
                                                <span>{t('product.sale')}</span>
                                            </div>
                                        </div>
                                        {
                                            currency === 'UZS' ? (
                                                <div className='nums'>
                                                    <h4>{(item.price * (100 - item.discount) / 100) ? reFormat(Math.ceil((item.price * currencyValue * (100 - item.discount)) / 100)) : ''} {t('cart.som')}</h4>
                                                    {
                                                        item.discount ? (
                                                            <h6>{reFormat(item.price * currencyValue)} {t('cart.som')}</h6>
                                                        ) : ''
                                                    }
                                                </div>
                                            ) : (
                                                <div className='nums'>
                                                    <h4>{(item.price * (100 - item.discount) / 100) ? reFormat(Math.ceil((item.price * (100 - item.discount)) / 100)) : ''} $</h4>
                                                    {
                                                        item.discount ? (
                                                            <h6>{reFormat(item.price)} $</h6>
                                                        ) : ''
                                                    }
                                                </div>
                                            )
                                        }
                                        <p>{item.brand ? item.brand.name : ''} / {item.name ? item.name[`${lang}`] : ''} / {item.partner ? item.partner.name : ''} {item.model ? item.model.name : ''}</p>
                                    </Link>
                                </div>
                            )
                        }) : ''
                    }
                </div>

                {
                    allSimilarProducts ? (
                        <div className='h1-link-wrap'>
                            <h1>{t('product.sim')}</h1>
                            {/* <Link to='/'>{t('home.see')}</Link> */}
                        </div>
                    ) : null
                }
                <div className='wrapper-prod'>
                    {
                        allSimilarProducts && Array.isArray(allSimilarProducts) ? allSimilarProducts.map((item, index) => {
                            return (
                                <div className='one-product-component' style={{ display: item.isActive === false ? 'none' : 'block' }} key={index}>
                                    <Link to={`/product/${item._id}`} onClick={() => addSeen(item._id)}>
                                        <div className='bg' style={{ backgroundImage: `url(${link}/${item ? item.photo : ''})` }}>
                                            <div className='left-top' style={{ display: `${item.discount === null ? 'none' : 'flex'}` }}>
                                                <span>{`-${item ? item.discount : ''}%`}</span>
                                            </div>
                                            <div className='right-top' style={{ display: `${item.top ? 'flex' : 'none'}` }}>
                                                <div></div>
                                                <span>{t('product.lider')}</span>
                                            </div>
                                            <div className='left-bottom' style={{ display: `${item.status === 'advert' ? 'flex' : 'none'}` }}>
                                                <span>{t('product.advert')}</span>
                                            </div>
                                            <div className='left-bottom left-bottom-second' style={{ display: `${item.status === 'new' ? 'flex' : 'none'}` }}>
                                                <span>{t('product.new')}</span>
                                            </div>
                                            <div className='right-bottom' style={{ display: `${(item.sale && item.sale.status) ? 'flex' : 'none'}` }}>
                                                <span>{t('product.sale')}</span>
                                            </div>
                                        </div>
                                        {
                                            currency === 'UZS' ? (
                                                <div className='nums'>
                                                    <h4>{(item.price * (100 - item.discount) / 100) ? reFormat(Math.ceil((item.price * currencyValue * (100 - item.discount)) / 100)) : ''} {t('cart.som')}</h4>
                                                    {
                                                        item.discount ? (
                                                            <h6>{reFormat(item.price * currencyValue)} {t('cart.som')}</h6>
                                                        ) : ''
                                                    }
                                                </div>
                                            ) : (
                                                <div className='nums'>
                                                    <h4>{(item.price * (100 - item.discount) / 100) ? reFormat(Math.ceil((item.price * (100 - item.discount)) / 100)) : ''} $</h4>
                                                    {
                                                        item.discount ? (
                                                            <h6>{reFormat(item.price)} $</h6>
                                                        ) : ''
                                                    }
                                                </div>
                                            )
                                        }
                                        <p>{item.brand ? item.brand.name : ''} / {item.name ? item.name[`${lang}`] : ''} / {item.partner ? item.partner.name : ''} {item.model ? item.model.name : ''}</p>
                                    </Link>
                                </div>
                            )
                        }) : ''
                    }
                </div>

                <div className='h1-link-wrap' style={{ display: allSeen.length > 0 ? 'flex' : 'none' }}>
                    <h1>{t('product.last')}</h1>
                    {/* <Link to='/'>{t('home.see')}</Link> */}
                </div>
                <div className='wrapper-prod'>
                    {
                        allSeen ? allSeen.map((item, index) => {
                            return (
                                <InnerOneProduct
                                    key={index}
                                    item={item}
                                    link={link}
                                    currency={currency}
                                    currencyValue={currencyValue}
                                />
                            )
                        }) : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default ContinueProduct