import React from 'react'
import { GrClose } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import truePng from '../../img/true.png'
import falsePng from '../../img/false.png'
import './modals.css'

const Modals = (props) => {

    const { setShowModal, showModal, modalImg, modalText, modalBtn, modalLink } = props

    return (
        <>
            <div className={showModal ? 'modals-component modals-component-active' : 'modals-component'} onClick={() => setShowModal(false)}></div>
            <div className={showModal ? 'inner-modal inner-modal-active' : 'inner-modal'}>
                <GrClose className='icon' onClick={() => setShowModal(false)} />
                <div>
                    <img src={modalImg ? truePng : falsePng} alt='' />
                    <h3>{modalText}</h3>
                </div>
                <Link to={modalLink} onClick={() => setShowModal(false)} >
                    <button>{modalBtn}</button>
                </Link>
            </div>
        </>
    )
}

export default Modals