import React, { useState } from 'react'
import wholesalePng from '../../img/wholesale.png'
import bir from '../../img/whole1.png'
import ikki from '../../img/whole2.png'
import uch from '../../img/whole3.png'
import turt from '../../img/whole4.png'
import './wholesale.css'
import ReactInputMask from 'react-input-mask'
import axios from 'axios'
import SellingModal from '../selling/SellingModal'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Wholesale = () => {

    const token = window.localStorage.getItem('token')

    const navigate = useNavigate()

    const { t } = useTranslation()

    const [showModal, setShowModal] = useState(false)

    const [name, setName] = useState('')
    const [lastname, setLastname] = useState('')
    const [firmName, setFirmName] = useState('')
    const [firmType, setFirmType] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [inn, setInn] = useState('')
    const [address, setAddress] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()

        const formData = {
            firstname: name,
            lastname,
            firmname: firmName,
            firmtype: firmType,
            number: phone,
            mail: email,
            inn,
            address,
            status: 'buy'
        }

        axios.post('/api/form', formData, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setShowModal(true)
            setName('')
            setLastname('')
            setFirmName('')
            setFirmType('')
            setPhone('')
            setEmail('')
            setInn('')
            setAddress('')
        }).catch(err => {
            navigate('/error')
        })
    }

    return (
        <div className='wholesale-component'>
            <SellingModal showModal={showModal} setShowModal={setShowModal} />
            <div className='containercha'>
                <img src={wholesalePng} alt='' className='banner' />
                <div className='qator'>
                    <div>
                        <h3>{t('whole.bir')}</h3>
                        <p>{t('whole.bir-text')}</p>
                    </div>
                    <img src={bir} alt='' />
                </div>
                <div className='qator'>
                    <img src={ikki} alt='' />
                    <div>
                        <h3>{t('whole.ikki')}</h3>
                        <p>{t('whole.ikki-text')}</p>
                    </div>
                </div>
                <div className='qator'>
                    <div>
                        <h3>{t('whole.uch')}</h3>
                        <p>{t('whole.uch-text')}</p>
                    </div>
                    <img src={uch} alt='' />
                </div>
                <div className='qator'>
                    <img src={turt} alt='' />
                    <div>
                        <h3>{t('whole.turt')}</h3>
                        <p>{t('whole.turt-text')}</p>
                    </div>
                </div>

                <form onSubmit={submitHandler}>
                    <div className='qator'>
                        <div>
                            <label>{t('cart.name')}</label>
                            <input type='text' value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div>
                            <label>{t('cart.surname')}</label>
                            <input type='text' value={lastname} onChange={(e) => setLastname(e.target.value)} required />
                        </div>
                    </div>
                    <div className='qator'>
                        <div>
                            <label>{t('selling.firma')}</label>
                            <input type='text' value={firmName} onChange={(e) => setFirmName(e.target.value)} required />
                        </div>
                        <div>
                            <label>{t('selling.type')}</label>
                            <input type='text' value={firmType} onChange={(e) => setFirmType(e.target.value)} required />
                        </div>
                    </div>
                    <div className='qator'>
                        <div>
                            <label>{t('cart.phone')}</label>
                            <ReactInputMask maskChar={null} name="phone" mask="+\9\9\8999999999" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </div>
                        <div>
                            <label>{t('cart.email')}</label>
                            <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                    </div>
                    <div className='qator last'>
                        <div>
                            <label>{t('selling.inn')}</label>
                            <input type='text' value={inn} onChange={(e) => setInn(e.target.value)} required />
                        </div>
                    </div>
                    <div className='textarea-w'>
                        <label>{t('place-order.address')}</label>
                        <textarea value={address} onChange={(e) => setAddress(e.target.value)} required></textarea>
                    </div>
                    <div className='btn-w'>
                        <button type='submit'>{t('selling.send')}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Wholesale