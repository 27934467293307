import { Pagination, Slider } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Accordion, Row } from 'react-bootstrap'
import { MdClose } from 'react-icons/md'
import { RiSearchLine } from 'react-icons/ri'
import { TiArrowSortedUp } from 'react-icons/ti'
import Loader from '../loader/Loader'
import './allProducts.css'
import ProductStyle1 from './ProductStyle1'
import ProductStyle2 from './ProductStyle2'
import ProductStyle3 from './ProductStyle3'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import MobileFilter from './MobileFilter'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const AllProducts = (props) => {

    const { link, addFavorites, addCart, addComparison, addSeen } = props

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)
    const { currency } = useSelector(state => state.currencyReducer)

    const navigate = useNavigate()

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course?.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const location = useLocation()

    const [mobFilter, setMobFilter] = useState(false)

    const [prodStyle, setProdStyle] = useState('style1')

    const [loading, setLoading] = useState(true)
    const loader = loading ? <Loader /> : null

    const [priceShow, setPriceShow] = useState(true)
    const [tireShow, setTireShow] = useState(true)
    const [discShow, setDiscShow] = useState(true)
    const [typeDiscShow, setTypeDiscShow] = useState(true)
    const [sezonShow, setSezonShow] = useState(true)
    const [texShow, setTexShow] = useState(true)
    const [tipShow, setTipShow] = useState(true)
    const [discountShow, setDiscountShow] = useState(true)
    const [markaShow, setMarkaShow] = useState(true)
    const [countryShow, setCountryShow] = useState(true)
    const [brandShow, setBrandShow] = useState(true)

    const [rangeValue1, setRangeValue1] = useState()
    const [rangeValue2, setRangeValue2] = useState()
    const onChange = (value) => {
        setRangeValue1(value ? value[0] * 1000000 : '')
        setRangeValue2(value ? value[1] * 1000000 : '')
    }

    const [banner, setBanner] = useState('')
    const getBanner = () => {
        axios.get(`/api/banner?status=filter`).then(res => {
            setBanner(res.data.banners.length !== 0 ? res.data.banners[0] : '')
            setLoading(false)
        }).catch(err => {
            navigate('/error')
            setLoading(false)
        })
    }


    const [statusDiscTire, setStatusDiscTire] = useState(null)

    // CATEGORY
    const [allCategories, setAllCategories] = useState([])
    const getCategories = () => {
        axios.get(`/api/category`, {
            params: {
                ref: null
            },
        }).then(res => {
            setAllCategories(res.data.categories)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [allSubCategories, setAllSubCategories] = useState([])
    const getSubCategories = (id) => {
        axios.get(`/api/category`, {
            params: {
                ref: id
            },
        }).then(res => {
            setAllSubCategories(res.data.categories)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [allSubSubCategories, setAllSubSubCategories] = useState([])
    const getSubSubCategories = (id) => {
        axios.get(`/api/category`, {
            params: {
                ref: id
            },
        }).then(res => {
            setAllSubSubCategories(res.data.categories)
        }).catch(err => {
            navigate('/error')
        })
    }

    // SEARCHED CATEGORIES
    const [searchedCategories, setSearchedCategories] = useState([])
    const [categoryName, setCategoryName] = useState('')
    const searchCategory = () => {
        if (categoryName.length > 0) {
            axios.get(`/api/category/search?search=${categoryName}`).then(res => {
                setSearchedCategories(res.data.categories)
            }).catch(err => {
                navigate('/error')
            })
        }
    }

    // PARTNERS
    const [allPartners, setAllPartners] = useState([])
    const getPartners = () => {
        axios.get('/api/partners').then(res => {
            setAllPartners(res.data.partners)
        }).catch(err => {
            navigate('/error')
        })
    }

    // MODELS
    const [allModels, setAllModels] = useState([])
    const getModels = (id) => {
        axios.get(`/api/model?marka=${id}`).then(res => {
            setAllModels(res.data.models)
        }).catch(err => {
            navigate('/error')
        })
    }

    // BRANDS
    const [allBrands, setAllBrands] = useState([])
    const getBrands = () => {
        axios.get(`/api/brand`).then(res => {
            setAllBrands(res.data.brands)
        }).catch(err => {
            navigate('/error')
        })
    }
    // BRAND SEARCH
    const [searchedBrands, setSearchedBrands] = useState([])
    const [brandName, setBrandName] = useState('')
    const searchBrand = () => {
        if (brandName.length > 0) {
            axios.get(`/api/brand/search?brand=${brandName}`).then(res => {
                setSearchedBrands(res.data.brands)
            }).catch(err => {
                navigate('/error')
            })
        }
    }

    // COUNTRY
    const [allCountries, setAllCountries] = useState([])
    const getCounties = () => {
        axios.get(`/api/products/country`).then(res => {
            setAllCountries(res.data.countries)
        }).catch(err => {
            navigate('/error')
        })
    }

    // TIRE
    const [tireDiametr, setTireDiametr] = useState([])
    const [tireHeight, setTireHeight] = useState([])
    const [tireWidth, setTireWidth] = useState([])
    const getTireDiametr = () => {
        axios.get('/api/tyre/character').then(res => {
            setTireDiametr(res.data.character?.diameter)
            setTireHeight(res.data.character?.height)
            setTireWidth(res.data.character?.width)
        }).catch(err => {
            navigate('/error')
        })
    }

    // TIRE
    const [discDiametr, setDiscDiametr] = useState([])
    const [hubDiametr, setHubDiametr] = useState([])
    const [countDisc, setCountDisc] = useState([])
    const [spaceDisc, setSpaceDisc] = useState([])
    const [vyletDisc, setVyletDisc] = useState([])
    const [widthDisc, setWidthDisc] = useState([])
    const getDiscDiametr = () => {
        axios.get('/api/disc/character').then(res => {
            setDiscDiametr(res.data.character?.diameterDisc)
            setHubDiametr(res.data.character?.diametrHub)
            setCountDisc(res.data.character?.count)
            setSpaceDisc(res.data.character?.space)
            setVyletDisc(res.data.character?.vylet)
            setWidthDisc(res.data.character?.width)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [sale, setSale] = useState(null)
    const [discount, setDiscount] = useState(null)
    const [countryId, setCountryId] = useState(null)
    const [brandId, setBrandId] = useState(null)
    const [categoryId, setCategoryId] = useState(null)
    const [mainCategoryId, setMainCategoryId] = useState(null)
    const [subCategoryId, setSubCategoryId] = useState(null)
    const [modelId, setModelId] = useState(null)
    const [partnerId, setPartnerId] = useState(null)
    const [limit, setLimit] = useState(24)
    const [filteredProducts, setFilteredProducts] = useState([])
    const [allCountriesId, setAllCountriesId] = useState([])

    const checkCountriesId = (id) => {
        if (allCountriesId?.includes(id)) {
            setAllCountriesId(allCountriesId.filter(i => i !== id))
        } else {
            setAllCountriesId(prev => [...prev, id])
        }
    }

    const [ren, setRen] = useState(false)
    useEffect(() => {
        const arr = location.search.split('=')
        const name = arr[0]
        const id = arr[1]

        if (name === '?main') {
            setMainCategoryId(id.split('?')[0])
            setCategoryId(null)
            setSubCategoryId(null)
            setDiscount(null)
            setBrandId(null)
            setPartnerId(null)
            setSale(null)
            setStatusDiscTire(arr[2])
            setRen(!ren)
        } else if (name === '?sub') {
            setSubCategoryId(id.split('?')[0])
            setCategoryId(null)
            setMainCategoryId(null)
            setDiscount(null)
            setBrandId(null)
            setPartnerId(null)
            setSale(null)
            setStatusDiscTire(arr[2])
            setRen(!ren)
        } else if (name === '?subsub') {
            setCategoryId(id.split('?')[0])
            setSubCategoryId(null)
            setMainCategoryId(null)
            setDiscount(null)
            setBrandId(null)
            setPartnerId(null)
            setSale(null)
            setStatusDiscTire(arr[2])
            setRen(!ren)
        } else if (name === '?brand') {
            setBrandId(id)
            setDiscount(null)
            setCategoryId(null)
            setSubCategoryId(null)
            setMainCategoryId(null)
            setPartnerId(null)
            setSale(null)
            setRen(!ren)
        } else if (name === '?partner') {
            setPartnerId(id)
            setBrandId(null)
            setDiscount(null)
            setCategoryId(null)
            setSubCategoryId(null)
            setMainCategoryId(null)
            setSale(null)
            setRen(!ren)
        } else if (location.search === '?discount=1') {
            setDiscount(1)
            setBrandId(null)
            setCategoryId(null)
            setSubCategoryId(null)
            setMainCategoryId(null)
            setPartnerId(null)
            setSale(null)
            setRen(!ren)
        } else if (location.search === '?discount=1&sale=true') {
            setPartnerId(null)
            setBrandId(null)
            setDiscount(1)
            setSale(true)
            setCategoryId(null)
            setSubCategoryId(null)
            setMainCategoryId(null)
            setRen(!ren)
        } else {
            setRen(!ren)
        }

        return () => {
            setPartnerId(null)
            setBrandId(null)
            setDiscount(null)
            setSale(null)
            setCategoryId(null)
            setSubCategoryId(null)
            setMainCategoryId(null)
        }

        //eslint-disable-next-line
    }, [location])

    const [tireDiametrValue, setTireDiametrValue] = useState('')
    const [tireHeightValue, setTireHeightValue] = useState('')
    const [tireWidthValue, setTireWidthValue] = useState('')
    const [seasonValue, setSeasonValue] = useState('')
    const [typeTireValue, setTypeTireValue] = useState('')
    const [techValue, setTechValue] = useState(null)

    const [discDiametrValue, setDiscDiametrValue] = useState('')
    const [hubDiametrValue, setHubDiametrValue] = useState('')
    const [countDiscValue, setCountDiscValue] = useState('')
    const [spaceDiscValue, setSpaceDiscValue] = useState('')
    const [vyletDiscValue, setVyletDiscValue] = useState('')
    const [widthDiscValue, setWidthDiscValue] = useState('')
    const [discTypeValue, setDiscTypeValue] = useState('')

    const [sortValue, setSortValue] = useState('')
    const [skip, setSkip] = useState(1)

    const getProductsByFilter = () => {
        let course;
        axios.get('/api/course').then(res => {
            course = res.data.course?.uzs

            setFilteredProducts([])
            axios.get('/api/products/filter', {
                params: {
                    skip: skip,
                    limit: limit,
                    fromPrice: course ? rangeValue1 ? rangeValue1 / course : 0 / course : 0,
                    toPrice: course ? rangeValue2 ? rangeValue2 / course : 100000000 / course : 10000,
                    discount: discount,
                    country: allCountriesId,
                    brand: brandId === 'all' ? null : brandId,
                    category: categoryId,
                    mainCategory: mainCategoryId,
                    subCategory: subCategoryId,
                    model: modelId,
                    partner: partnerId,
                    sale: sale,

                    diametrTire: tireDiametrValue === '' ? null : tireDiametrValue,
                    widthTire: tireWidthValue === '' ? null : tireWidthValue,
                    heightTire: tireHeightValue === '' ? null : tireHeightValue,
                    season: seasonValue === '' ? null : seasonValue,
                    type: typeTireValue === '' ? null : typeTireValue,
                    technology: techValue,

                    diametrDisk: discDiametrValue === '' ? null : discDiametrValue,
                    widthDisc: widthDiscValue === '' ? null : widthDiscValue,
                    diametrDisc: hubDiametrValue === '' ? null : hubDiametrValue,
                    count: countDiscValue === '' ? null : countDiscValue,
                    vylet: vyletDiscValue === '' ? null : vyletDiscValue,
                    space: spaceDiscValue === '' ? null : spaceDiscValue,
                    typeDisc: discTypeValue === '' ? null : discTypeValue,

                    topPrice: sortValue === 'expensive' ? true : null,
                    cheapPrice: sortValue === 'cheap' ? true : null,
                    newProduct: sortValue === 'new' ? true : null,
                    topProduct: sortValue === 'top' ? true : null,
                    advertProduct: sortValue === 'advert' ? true : null
                }
            }).then(res => {
                setFilteredProducts(res.data.products)
            }).catch(err => {
                navigate('/error')
            })

        }).catch(err => {
            navigate('/error')
        })
    }

    const [countProducts, setCountProducts] = useState(0)
    const getAllProductsLength = () => {
        axios.get('/api/products/filter', {
            params: {
                skip: 1,
                limit: 100000
            }
        })
            .then(res => {
                setCountProducts(res.data.products.length)
            }).catch(err => {
                console.log(err)
            })
    }

    const clearFilter = () => {
        setTechValue(null)
        setDiscount(null)
        setAllCountriesId([])
        setBrandId(null)
        setCategoryId(null)
        setMainCategoryId(null)
        setSubCategoryId(null)
        setModelId(null)
        setPartnerId(null)
        setSale(null)
        setTireDiametrValue(null)
        setTireWidthValue('')
        setTireHeightValue('')
        setSeasonValue('')
        setTypeTireValue('')
        setTechValue(null)
        setDiscDiametrValue('')
        setHubDiametrValue('')
        setCountDiscValue('')
        setSpaceDiscValue('')
        setVyletDiscValue('')
        setWidthDiscValue('')
        setDiscTypeValue('')
        setSortValue('')
    }

    useEffect(() => {
        getProductsByFilter()
        //eslint-disable-next-line
    }, [rangeValue1, rangeValue2, limit, discount, allCountriesId, brandId, categoryId,
        modelId, mainCategoryId, subCategoryId, tireDiametrValue, tireWidthValue, tireHeightValue,
        discDiametrValue, widthDiscValue, hubDiametrValue, countDiscValue, vyletDiscValue, spaceDiscValue,
        discTypeValue, seasonValue, typeTireValue, techValue, sortValue, skip, sale, location, ren
    ])

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        onChange()
        getCategories()
        getPartners()
        getBrands()
        getCounties()
        getTireDiametr()
        getDiscDiametr()
        getCurrencyValue()
        getAllProductsLength()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        getBanner()
    }, [lang])

    const searchEndBrand = () => {
        setBrandName('')
        setSearchedBrands([])
    }

    const searchEndCategory = () => {
        setCategoryName('')
        setSearchedCategories([])
    }

    useEffect(() => {
        searchBrand()
        //eslint-disable-next-line
    }, [brandName])

    useEffect(() => {
        searchCategory()
        //eslint-disable-next-line
    }, [categoryName])

    const style1 = (
        <Row>
            {
                filteredProducts ? filteredProducts.map((item, index) => {
                    return (
                        <ProductStyle1
                            key={index}
                            item={item}
                            link={link}
                            addComparison={addComparison}
                            addCart={addCart}
                            addFavorites={addFavorites}
                            currency={currency}
                            currencyValue={currencyValue}
                            addSeen={addSeen}
                        />
                    )
                }) : ''
            }
            {
                filteredProducts.length === 0 ? (
                    <h6 style={{ textAlign: 'center', marginTop: '20px' }}>{t('empty.empty')}</h6>
                ) : null
            }
        </Row>
    )

    const style2 = (
        <Row>
            {
                filteredProducts ? filteredProducts.map((item, index) => {
                    return (
                        <ProductStyle2
                            key={index}
                            item={item}
                            link={link}
                            addComparison={addComparison}
                            addCart={addCart}
                            addFavorites={addFavorites}
                            currency={currency}
                            currencyValue={currencyValue}
                            addSeen={addSeen}
                        />
                    )
                }) : ''
            }
            {
                filteredProducts.length === 0 ? (
                    <h6 style={{ textAlign: 'center', marginTop: '20px' }}>{t('empty.empty')}</h6>
                ) : null
            }
        </Row>
    )

    const style3 = (
        <Row>
            {
                filteredProducts ? filteredProducts.map((item, index) => {
                    return (
                        <ProductStyle3
                            key={index}
                            item={item}
                            link={link}
                            addComparison={addComparison}
                            addCart={addCart}
                            addFavorites={addFavorites}
                            currency={currency}
                            currencyValue={currencyValue}
                            addSeen={addSeen}
                        />
                    )
                }) : ''
            }
            {
                filteredProducts.length === 0 ? (
                    <h6 style={{ textAlign: 'center', marginTop: '20px' }}>{t('empty.empty')}</h6>
                ) : null
            }
        </Row>
    )

    const isStyele1 = prodStyle === 'style1' ? style1 : null
    const isStyele2 = prodStyle === 'style2' ? style2 : null
    const isStyele3 = prodStyle === 'style3' ? style3 : null

    return (
        <div className='all-products-component'>
            <div className='containercha'>
                {loader}
                <Link to={banner.product === null ? banner.brand !== null ? `/all-products?brand=${banner.brand._id}` : '/' : `/product/${banner.product?._id}`}>
                    <img src={`${link}/${banner?.photo ? banner?.photo[`${lang}`] : ''}`} alt='' className='banner' />
                </Link>
                {/* <div className='top'>
                    <p>Главная  /  Каталог по маркам автомобилей  /  <span>Chevrolet</span></p>
                </div>
                <div className='header-wrap'>
                    <h1>Chevrolet</h1>
                    <p>103 296 товаров</p>
                </div> */}

                <div className='wrapper'>
                    <div className='left-filter'>
                        {/* FILTER BY CATEGORY */}
                        <div className='by-category'>
                            <h3>{t('product.category')}</h3>
                            <div className={searchedCategories.length > 0 ? 'searched' : 'searched not-searched'}>
                                {
                                    searchedCategories && Array.isArray(searchedCategories) ? searchedCategories.map((item, index) => {
                                        return (
                                            <div key={index} onClick={() => { setMainCategoryId(item._id); searchEndCategory(); setCategoryId(null) }}>{item.name[`${lang}`]}</div>
                                        )
                                    }) : ''
                                }
                            </div>
                            {/* main category */}
                            <Accordion>
                                {
                                    allCategories && Array.isArray(allCategories) ? allCategories.map((item, index) => {
                                        return (
                                            <Accordion.Item eventKey={index} key={index} onClick={() => getSubCategories(item._id)}>
                                                <Accordion.Header>{item.name[`${lang}`]}</Accordion.Header>
                                                <Accordion.Body className='main-body'>
                                                    {/* sub category */}
                                                    <Accordion>
                                                        {
                                                            allSubCategories ? allSubCategories.map((item1, index1) => {
                                                                return (
                                                                    <Accordion.Item eventKey={index1} key={index1} onClick={() => getSubSubCategories(item1._id)} >
                                                                        <Accordion.Header>{item1.name[`${lang}`]}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {/* sub sub category */}
                                                                            {
                                                                                allSubSubCategories ? allSubSubCategories.map((item2, index2) => {
                                                                                    return (
                                                                                        <p key={index2} onClick={() => { setCategoryId(item2._id); setStatusDiscTire(item2.status); setMainCategoryId(null) }}>{item2.name[`${lang}`]}</p>
                                                                                    )
                                                                                }) : ''
                                                                            }
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                )
                                                            }) : ''
                                                        }
                                                    </Accordion>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    }) : ''
                                }
                            </Accordion>
                        </div>

                        {/* FILTER BY PRICE */}
                        <div className={priceShow ? 'by-price' : 'by-price by-price-false'}>
                            <div className='top'>
                                <h6>{t('product.price')}</h6>
                                <div className='icon-wrap' onClick={() => setPriceShow(!priceShow)}>
                                    <TiArrowSortedUp className={priceShow ? 'icon' : 'icon icon-false'} />
                                </div>
                            </div>
                            <div className='prices'>
                                <div>{reFormat(rangeValue1)} {t('cart.som')}</div>
                                <span>-</span>
                                <div>{reFormat(rangeValue2)} {t('cart.som')}</div>
                            </div>
                            <div className='range-w'>
                                <Slider
                                    range={{
                                        draggableTrack: true,
                                    }}
                                    defaultValue={[0, 100]}
                                    onChange={onChange}
                                />
                            </div>
                        </div>

                        {/* FILTER BY TIRE */}
                        <div className={tireShow ? 'by-tire' : 'by-tire by-tire-false'} style={{ display: statusDiscTire === 'tire' ? 'block' : 'none' }}>
                            <div className='top'>
                                <h6>{t('product.size-tire')}</h6>
                                <div className='icon-wrap' onClick={() => setTireShow(!tireShow)}>
                                    <TiArrowSortedUp className={tireShow ? 'icon' : 'icon icon-false'} />
                                </div>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.diametr-tire')}</label>
                                <select value={tireDiametrValue} onChange={(e) => setTireDiametrValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        tireDiametr ? tireDiametr.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.width-tire')}</label>
                                <select value={tireWidthValue} onChange={(e) => setTireWidthValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        tireWidth ? tireWidth.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.height-tire')}</label>
                                <select value={tireHeightValue} onChange={(e) => setTireHeightValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        tireHeight ? tireHeight.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>

                            <div className={sezonShow ? 'by-sezon' : 'by-sezon by-sezon-false'}>
                                <div className='top'>
                                    <h6>{t('product.sezon')}</h6>
                                    <div className='icon-wrap' onClick={() => setSezonShow(!sezonShow)}>
                                        <TiArrowSortedUp className={sezonShow ? 'icon' : 'icon icon-false'} />
                                    </div>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='season' id='yoz' value='summer' onChange={(e) => setSeasonValue(e.target.value)} />
                                    <label htmlFor='yoz'>{t('product.yoz')}</label>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='season' id='qish' value='winter' onChange={(e) => setSeasonValue(e.target.value)} />
                                    <label htmlFor='qish'>{t('product.qish')}</label>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='season' id='qish-w' value='spikes' onChange={(e) => setSeasonValue(e.target.value)} />
                                    <label htmlFor='qish-w'>{t('product.qish-w')}</label>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='season' id='all' value='all' onChange={(e) => setSeasonValue(e.target.value)} />
                                    <label htmlFor='all'>{t('product.all-sezon')}</label>
                                </div>
                            </div>

                            <div className={texShow ? 'by-tex' : 'by-tex by-tex-false'}>
                                <div className='top'>
                                    <h6>{t('product.tech')}</h6>
                                    <div className='icon-wrap' onClick={() => setTexShow(!texShow)}>
                                        <TiArrowSortedUp className={texShow ? 'icon' : 'icon icon-false'} />
                                    </div>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='checkbox' id='runflat' checked={techValue} value={techValue} onChange={() => setTechValue(!techValue)} />
                                    <label htmlFor='runflat'>RunFlat</label>
                                </div>
                            </div>

                            <div className={tipShow ? 'by-sezon' : 'by-sezon by-sezon-false'}>
                                <div className='top'>
                                    <h6>{t('product.type')}</h6>
                                    <div className='icon-wrap' onClick={() => setTipShow(!tipShow)}>
                                        <TiArrowSortedUp className={tipShow ? 'icon' : 'icon icon-false'} />
                                    </div>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='tire-type' id='avto' value='cars' onChange={(e) => setTypeTireValue(e.target.value)} />
                                    <label htmlFor='avto'>{t('product.avto')}</label>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='tire-type' id='no-way' value='offroad' onChange={(e) => setTypeTireValue(e.target.value)} />
                                    <label htmlFor='no-way'>{t('product.no-way')}</label>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='tire-type' id='sport' value='sport' onChange={(e) => setTypeTireValue(e.target.value)} />
                                    <label htmlFor='sport'>{t('product.sport')}</label>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='tire-type' id='commer' value='commercial' onChange={(e) => setTypeTireValue(e.target.value)} />
                                    <label htmlFor='commer'>{t('product.com')}</label>
                                </div>
                            </div>

                        </div>

                        {/* FILTER BY DISC */}
                        <div className={discShow ? 'by-tire' : 'by-tire by-tire-false'} style={{ display: statusDiscTire === 'disc' ? 'block' : 'none' }}>
                            <div className='top'>
                                <h6>{t('product.size-disk')}</h6>
                                <div className='icon-wrap' onClick={() => setDiscShow(!discShow)}>
                                    <TiArrowSortedUp className={discShow ? 'icon' : 'icon icon-false'} />
                                </div>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.diametr-disk')}</label>
                                <select value={discDiametrValue} onChange={(e) => setDiscDiametrValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        discDiametr ? discDiametr.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.width-disk')}</label>
                                <select value={widthDiscValue} onChange={(e) => setWidthDiscValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        widthDisc ? widthDisc.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.bolt')}</label>
                                <select value={spaceDiscValue} onChange={(e) => setSpaceDiscValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        spaceDisc ? spaceDisc.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.vilet')}</label>
                                <select value={vyletDiscValue} onChange={(e) => setVyletDiscValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        vyletDisc ? vyletDisc.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.center-disk')}</label>
                                <select value={hubDiametrValue} onChange={(e) => setHubDiametrValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        hubDiametr ? hubDiametr.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>
                            <div className='inp-wrap'>
                                <label>{t('product.number-fasten')}</label>
                                <select value={countDiscValue} onChange={(e) => setCountDiscValue(e.target.value)}>
                                    <option hidden value={null}>{t('place-order.not-selected')}</option>
                                    {
                                        countDisc ? countDisc.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )
                                        }) : ''
                                    }
                                </select>
                            </div>

                            <div className={typeDiscShow ? 'by-sezon' : 'by-sezon by-sezon-false'}>
                                <div className='top'>
                                    <h6>{t('product.type-disk')}</h6>
                                    <div className='icon-wrap' onClick={() => setTypeDiscShow(!typeDiscShow)}>
                                        <TiArrowSortedUp className={typeDiscShow ? 'icon' : 'icon icon-false'} />
                                    </div>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='type-disc' id='cast' value='cast' onChange={(e) => setDiscTypeValue(e.target.value)} />
                                    <label htmlFor='cast'>{t('product.cast')}</label>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='type-disc' id='stamped' value='stamped' onChange={(e) => setDiscTypeValue(e.target.value)} />
                                    <label htmlFor='stamped'>{t('product.stamped')}</label>
                                </div>
                                <div className='checkbox-wrap'>
                                    <input type='radio' name='type-disc' id='forged' value='forged' onChange={(e) => setDiscTypeValue(e.target.value)} />
                                    <label htmlFor='forged'>{t('product.forged')}</label>
                                </div>
                            </div>
                        </div>

                        {/* FILTER BY DISCOUNT */}
                        <div className={discountShow ? 'by-discount' : 'by-discount by-discount-false'}>
                            <div className='top'>
                                <h6>{t('product.discount')}</h6>
                                <div className='icon-wrap' onClick={() => setDiscountShow(!discountShow)}>
                                    <TiArrowSortedUp className={discountShow ? 'icon' : 'icon icon-false'} />
                                </div>
                            </div>
                            <div className='checkbox-wrap mt-3'>
                                <input type='radio' name='discount' id='all-discount' value={1} onChange={(e) => setDiscount(1)} />
                                <label htmlFor='all-discount'>{t('product.all')}</label>
                            </div>
                            <div className='checkbox-wrap'>
                                <input type='radio' name='discount' id='5' value={5} onChange={(e) => setDiscount(5)} />
                                <label htmlFor='5'>{t('product.5')}</label>
                            </div>
                            <div className='checkbox-wrap'>
                                <input type='radio' name='discount' id='10' value={10} onChange={(e) => setDiscount(10)} />
                                <label htmlFor='10'>{t('product.10')}</label>
                            </div>
                            <div className='checkbox-wrap'>
                                <input type='radio' name='discount' id='15' value={15} onChange={(e) => setDiscount(15)} />
                                <label htmlFor='15'>{t('product.15')}</label>
                            </div>
                            <div className='checkbox-wrap'>
                                <input type='radio' name='discount' id='20' value={20} onChange={(e) => setDiscount(20)} />
                                <label htmlFor='20'>{t('product.20')}</label>
                            </div>
                        </div>

                        {/* FILTER BY MARKA */}
                        <div className={markaShow ? 'by-marka' : 'by-marka by-marka-false'}>
                            <div className='top'>
                                <h6>{t('product.marka')}</h6>
                                <div className='icon-wrap' onClick={() => setMarkaShow(!markaShow)}>
                                    <TiArrowSortedUp className={markaShow ? 'icon' : 'icon icon-false'} />
                                </div>
                            </div>
                            <Accordion>
                                {
                                    allPartners && Array.isArray(allPartners) ? allPartners.map((item, index) => {
                                        return (
                                            <Accordion.Item eventKey={index} key={index} onClick={() => getModels(item._id)}>
                                                <Accordion.Header>{item.name}</Accordion.Header>
                                                <Accordion.Body>
                                                    {
                                                        allModels && Array.isArray(allModels) ? allModels.map((item1, index1) => {
                                                            return (
                                                                <p key={index1} onClick={() => setModelId(item1._id)}>{item1.name}</p>
                                                            )
                                                        }) : ''
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    }) : ""
                                }
                            </Accordion>
                        </div>

                        {/* FILTER BY COUNTRY */}
                        <div className={countryShow ? 'by-country' : 'by-country by-country-false'}>
                            <div className='top'>
                                <h6>{t('product.mamlakat')}</h6>
                                <div className='icon-wrap' onClick={() => setCountryShow(!countryShow)}>
                                    <TiArrowSortedUp className={countryShow ? 'icon' : 'icon icon-false'} />
                                </div>
                            </div>
                            {
                                allCountries && Array.isArray(allCountries) ? allCountries.map((item, index) => {
                                    return (
                                        <div className='checkbox-wrap' key={index}>
                                            <input type='checkbox' name='country' id={item._id[`${lang}`]} checked={allCountriesId?.includes(item._id[`${lang}`])} value={item._id[`${lang}`]} onChange={(e) => checkCountriesId(e.target.value)} />
                                            <label htmlFor={item._id[`${lang}`]}>{item._id[`${lang}`]}</label>
                                        </div>
                                    )
                                }) : ''
                            }
                        </div>

                        {/* FILTER BY BRANDS */}
                        <div className={brandShow ? 'by-brand' : 'by-brand by-brand-false'}>
                            <div className='top'>
                                <h6>{t('product.brands')}</h6>
                                <div className='icon-wrap' onClick={() => setBrandShow(!brandShow)}>
                                    <TiArrowSortedUp className={brandShow ? 'icon' : 'icon icon-false'} />
                                </div>
                            </div>
                            <div className='search-wrap mt-3'>
                                <RiSearchLine className='icon' />
                                <input type='text' value={brandName} onChange={(e) => { setBrandName(e.target.value); searchBrand() }} placeholder={t('product.search')} />
                                <MdClose className={brandName.length > 0 ? 'iconn iconn-show' : 'iconn'} onClick={searchEndBrand} />
                            </div>
                            <div className={searchedBrands.length > 0 ? 'searched' : 'searched not-searched'}>
                                {
                                    searchedBrands && Array.isArray(searchedBrands) ? searchedBrands.map((item, index) => {
                                        return (
                                            <div key={index} onClick={() => { setBrandId(item._id); searchEndBrand() }}>{item.name}</div>
                                        )
                                    }) : ''
                                }
                            </div>

                            <div className='checkbox-wrap'>
                                <input type='radio' name='brand' id='all-brands' value='all' onChange={(e) => setBrandId(e.target.value)} />
                                <label htmlFor='all-brands'>{t('product.all')}</label>
                            </div>
                            {
                                allBrands && Array.isArray(allBrands) ? allBrands.map((item, index) => {
                                    return (
                                        <div key={index} className='checkbox-wrap'>
                                            <input type='radio' name='brand' id={item.name} value={item._id} onChange={(e) => setBrandId(e.target.value)} />
                                            <label htmlFor={item.name}>{item.name}</label>
                                        </div>
                                    )
                                }) : ''
                            }
                        </div>

                        <button className='reset-filter' onClick={clearFilter} style={{ textTransform: 'uppercase' }}>{t('product.clear-filter')}</button>

                    </div>
                    <div className='right-all-products'>
                        <div className='top-filter-prod'>
                            <div>
                                <label>{t('product.sort')}</label>
                                <select value={sortValue} onChange={(e) => setSortValue(e.target.value)}>
                                    <option hidden>Tanlanmagan</option>
                                    <option value='expensive'>Qimmatroq</option>
                                    <option value='cheap'>Arzonroq</option>
                                    <option value='new'>Yangi</option>
                                    <option value='sale'>Aksiya</option>
                                    <option value='top'>Eng ko'p sotilgan</option>
                                    <option value='advert'>Reklama</option>
                                </select>
                            </div>
                            <div>
                                <label>{t('product.show')}</label>
                                <div className='nums'>
                                    <div className={limit === 24 ? 'active-div' : ''} onClick={() => setLimit(24)}>
                                        <span>24</span>
                                    </div>
                                    <div className={limit === 36 ? 'active-div' : ''} onClick={() => setLimit(36)}>
                                        <span>36</span>
                                    </div>
                                    <div className={limit === 48 ? 'active-div' : ''} onClick={() => setLimit(48)}>
                                        <span>48</span>
                                    </div>
                                    <div className={limit === 60 ? 'active-div' : ''} onClick={() => setLimit(60)}>
                                        <span>60</span>
                                    </div>
                                </div>
                            </div>
                            <div className='sort'>
                                <div className={prodStyle === 'style1' ? 'bg active-bg' : 'bg'} onClick={() => setProdStyle('style1')}>
                                    <div></div>
                                </div>
                                <div className={prodStyle === 'style2' ? 'bg active-bg bg22' : 'bg bg22'} onClick={() => setProdStyle('style2')}>
                                    <div></div>
                                </div>
                                <div className={prodStyle === 'style3' ? 'bg active-bg' : 'bg'} onClick={() => setProdStyle('style3')}>
                                    <div></div>
                                </div>
                            </div>
                        </div>

                        <div className='mobile-top-filter'>
                            <div>
                                <div className={prodStyle === 'style3' ? 'mob-bg mob-bg-act' : 'mob-bg'} onClick={() => setProdStyle('style1')}>
                                    <div></div>
                                </div>
                                <div className={prodStyle === 'style1' ? 'mob-bg mob-bg-act' : 'mob-bg'} onClick={() => setProdStyle('style3')} >
                                    <div></div>
                                </div>
                            </div>
                            <select value={sortValue} onChange={(e) => setSortValue(e.target.value)}>
                                <option hidden>Tanlanmagan</option>
                                <option value='expensive'>Qimmat</option>
                                <option value='cheap'>Arzon</option>
                                <option value='new'>Yangi</option>
                                <option value='sale'>Aksiya</option>
                                <option value='top'>Eng ko'p sotilgan</option>
                                <option value='advert'>Reklama</option>
                            </select>
                            <div className='mob-filter' onClick={() => setMobFilter(true)}>
                                <div></div>
                            </div>
                        </div>

                        <MobileFilter
                            setAllCountriesId={setAllCountriesId}
                            checkCountriesId={checkCountriesId}
                            allCountriesId={allCountriesId}
                            setMobFilter={setMobFilter}
                            mobFilter={mobFilter}
                            categoryName={categoryName}
                            setCategoryName={setCategoryName}
                            searchCategory={searchCategory}
                            searchEndCategory={searchEndCategory}
                            searchedCategories={searchedCategories}
                            setMainCategoryId={setMainCategoryId}
                            allCategories={allCategories}
                            getSubCategories={getSubCategories}
                            allSubCategories={allSubCategories}
                            getSubSubCategories={getSubSubCategories}
                            allSubSubCategories={allSubSubCategories}
                            setCategoryId={setCategoryId}
                            setStatusDiscTire={setStatusDiscTire}
                            priceShow={priceShow}
                            setPriceShow={setPriceShow}
                            reFormat={reFormat}
                            rangeValue1={rangeValue1}
                            rangeValue2={rangeValue2}
                            onChange={onChange}
                            tireShow={tireShow}
                            statusDiscTire={statusDiscTire}
                            setTireShow={setTireShow}
                            tireDiametrValue={tireDiametrValue}
                            setTireDiametrValue={setTireDiametrValue}
                            tireDiametr={tireDiametr}
                            tireWidthValue={tireWidthValue}
                            setTireWidthValue={setTireWidthValue}
                            tireWidth={tireWidth}
                            tireHeightValue={tireHeightValue}
                            setTireHeightValue={setTireHeightValue}
                            tireHeight={tireHeight}
                            sezonShow={sezonShow}
                            setSezonShow={setSezonShow}
                            setSeasonValue={setSeasonValue}
                            texShow={texShow}
                            setTexShow={setTexShow}
                            techValue={techValue}
                            setTechValue={setTechValue}
                            tipShow={tipShow}
                            setTipShow={setTipShow}
                            setTypeTireValue={setTypeTireValue}
                            discShow={discShow}
                            setDiscShow={setDiscShow}
                            discDiametrValue={discDiametrValue}
                            setDiscDiametrValue={setDiscDiametrValue}
                            discDiametr={discDiametr}
                            widthDiscValue={widthDiscValue}
                            setWidthDiscValue={setWidthDiscValue}
                            widthDisc={widthDisc}
                            spaceDiscValue={spaceDiscValue}
                            setSpaceDiscValue={setSpaceDiscValue}
                            spaceDisc={spaceDisc}
                            vyletDiscValue={vyletDiscValue}
                            setVyletDiscValue={setVyletDiscValue}
                            vyletDisc={vyletDisc}
                            hubDiametrValue={hubDiametrValue}
                            setHubDiametrValue={setHubDiametrValue}
                            hubDiametr={hubDiametr}
                            countDiscValue={countDiscValue}
                            setCountDiscValue={setCountDiscValue}
                            countDisc={countDisc}
                            typeDiscShow={typeDiscShow}
                            setTypeDiscShow={setTypeDiscShow}
                            setDiscTypeValue={setDiscTypeValue}
                            discountShow={discountShow}
                            setDiscountShow={setDiscountShow}
                            setDiscount={setDiscount}
                            markaShow={markaShow}
                            setMarkaShow={setMarkaShow}
                            allPartners={allPartners}
                            getModels={getModels}
                            allModels={allModels}
                            setModelId={setModelId}
                            countryShow={countryShow}
                            setCountryShow={setCountryShow}
                            setCountryId={setCountryId}
                            allCountries={allCountries}
                            brandShow={brandShow}
                            setBrandShow={setBrandShow}
                            brandName={brandName}
                            setBrandName={setBrandName}
                            searchBrand={searchBrand}
                            searchEndBrand={searchEndBrand}
                            searchedBrands={searchedBrands}
                            setBrandId={setBrandId}
                            allBrands={allBrands}
                            clearFilter={clearFilter}
                        />


                        {isStyele1}
                        {isStyele2}
                        {isStyele3}

                        <div className='pagination-wrapper'>
                            <Pagination defaultCurrent={skip} current={skip} onChange={(e) => setSkip(e)} pageSize={limit} total={countProducts} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllProducts