import axios from 'axios'
import React, { useEffect, useState } from 'react'
import masterPng from '../../img/master.png'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './masterService.css'
import { useNavigate } from 'react-router-dom'

const MasterServise = () => {

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    const [allServices, setAllServices] = useState([])
    const getAllServices = () => {
        axios.get('/api/master', {
            params: {
                ref: null
            }
        }).then(res => {
            setAllServices(res.data.prices)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [header, setHeader] = useState('')
    const [allSubServices, setAllSubServices] = useState([])
    const getAllSubServices = (id) => {

        axios.get(`/api/master?id=${id}`).then(res => {
            setHeader(res.data.prices.name[`${lang}`])
        }).catch(err => {
            navigate('/error')
        })

        axios.get('/api/master', {
            params: {
                ref: id
            }
        }).then(res => {
            setAllSubServices(res.data.prices)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getAllServices()
    }, [lang])

    return (
        <div className='master-service-component'>
            <div className='containercha'>
                <img src={masterPng} alt='' className='banner' />
                <h1>{t('login.master')}</h1>
                <br />

                <div className='wrapper'>
                    <div className='left'>
                        {
                            allServices && Array.isArray(allServices) ? allServices.map((item, index) => {
                                return (
                                    <p key={index} onClick={() => getAllSubServices(item._id)}>{item.name[`${lang}`]}</p>
                                )
                            }) : ''
                        }
                    </div>
                    <div className='right'>
                        <div className='one'>
                            <h3>{header}</h3>
                            {
                                allSubServices && Array.isArray(allSubServices) ? allSubServices.map((item, index) => {
                                    return (
                                        <div key={index} className='qator'>
                                            <p>{item.name[`${lang}`]}</p>
                                            <div></div>
                                            <p>{item.price} {t('cart.som')}</p>
                                        </div>
                                    )
                                }) : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MasterServise