import React, { useEffect, useState } from 'react'
import './mobileCatalog.css'
import { Accordion } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'

const MobileCatalog = ({ setMobileMenu }) => {

    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    // CATEGORY
    const [ allCategories, setAllCategories ] = useState([])
    const getCategories = () => {
        axios.get(`/api/category`, {
            params: {
                ref: null
            },
        }).then(res => {
            setAllCategories(res.data.categories)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [ allSubCategories, setAllSubCategories ] = useState([])
    const getSubCategories = (id) => {
        axios.get(`/api/category`, {
            params: {
                ref: id
            },
        }).then(res => {
            setAllSubCategories(res.data.categories)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [ allSubSubCategories, setAllSubSubCategories ] = useState([])
    const getSubSubCategories = (id) => {
        axios.get(`/api/category`, {
            params: {
                ref: id
            },
        }).then(res => {
            setAllSubSubCategories(res.data.categories)
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getCategories()
        //eslint-disable-next-line
    }, [])

    return (
        <div className='mobile-catalog-component'>
            {/* MAIN CATEGORIES */}
            <Accordion>
                {
                    allCategories ? allCategories.map((item, index) => {
                        return (
                            <Accordion.Item eventKey={index} key={index} onClick={() => getSubCategories(item._id)} className='main-accordion-item'>
                                <Accordion.Header>{item.name[`${lang}`]}</Accordion.Header>
                                <Accordion.Body>
                                    {/* SUB CATEGORIES */}
                                    <Accordion>
                                        {
                                            allSubCategories ? allSubCategories.map((item1, index1) => {
                                                return (
                                                    <Accordion.Item eventKey={index1} key={index1} onClick={() => getSubSubCategories(item1._id)}>
                                                        <Accordion.Header>{item1.name[`${lang}`]}</Accordion.Header>
                                                        <Accordion.Body className='sub-sub-wrap'>
                                                            {/* SUB SUB CATEGORIES */}
                                                            {
                                                                allSubSubCategories ? allSubSubCategories.map((item2, index2) => {
                                                                    return (
                                                                        <Link to={`/all-products?subsub=${item2._id}?status=${item2.status}`} key={index2} onClick={() => setMobileMenu(false)}>{item2.name[`${lang}`]}</Link>
                                                                    )
                                                                }) : ''
                                                            }
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            }) : ''
                                        }
                                    </Accordion>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }) : ''
                }
            </Accordion>
        </div>
    )
}

export default MobileCatalog