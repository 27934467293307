import axios from "axios"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { AiFillDelete } from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import { Rating } from 'react-simple-star-rating'

const OneComparisonProduct = (props) => {
    const { item, link, lang, currency, deleteComparison, currencyValue, addCart } = props

    const navigate = useNavigate()

    const [avarageRating, setAvarageRating] = useState(null)

    const { t } = useTranslation()

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    const getComments = () => {
        axios.get(`/api/comment`, {
            params: {
                product: item?.product._id
            }
        }).then(res => {
            const comments = res.data.comments
            const count = res.data.count

            if (count > 0) {
                let allRating = 0
                for (let i = 0; i < comments.length; i++) {
                    allRating += comments[i].rate;
                }
                setAvarageRating(allRating / count)
            } else {
                setAvarageRating(null)
            }
        }).catch(err => {
        })
    }

    useEffect(() => {
        getComments()
    }, [])

    return (
        <div className='one-column'>
            <div className='product-wrap'>
                <div className='product'>
                    <div className='bg' style={{ backgroundImage: `url(${link}/${item?.product.photo})` }}>
                        <div className='left-top' style={{ display: `${item?.product.discount === null ? 'none' : 'flex'}` }}>
                            <span>{`-${item?.product.discount}%`}</span>
                        </div>
                        <div className='right-top' onClick={() => deleteComparison(item?._id)}>
                            <AiFillDelete className='icon' />
                        </div>
                    </div>
                    <p onClick={() => navigate(`/product/${item?.product?._id}`)}>{item?.product.brand.name} / {item?.product.name[`${lang}`]} / {item?.product.partner.name} {item?.product.model.name}</p>
                    {
                        currency === 'UZS' ? (
                            <h4>{reFormat(Math.ceil(item?.product.price * (100 - item?.product.discount) / 100) * currencyValue)} {t('cart.som')}</h4>
                        ) : (
                            <h4>{reFormat(Math.ceil(item?.product.price * (100 - item?.product.discount) / 100))} $</h4>
                        )
                    }
                    <div className='sale'>
                        {
                            currency === 'UZS' ? (
                                <h6 style={{ display: item?.product.discount ? 'block' : 'none' }}>{reFormat(item?.product.price * currencyValue)} {t('cart.som')}</h6>
                            ) : (
                                <h6 style={{ display: item?.product.discount ? 'block' : 'none' }}>{reFormat(item?.product.price)} $</h6>
                            )
                        }
                    </div>
                    <button onClick={() => addCart(item?.product._id)}>{t('comparison.add-cart')}</button>
                </div>
            </div>
            <div className='text'>
                <p>{item?.product.productCode}</p>
            </div>
            <div className='text'>
                <p>{item?.product.country[`${lang}`]}</p>
            </div>
            <div className='text'>
                {avarageRating ? (
                    <Rating ratingValue={avarageRating * 20} readonly size={32} />
                ) : '-'}
            </div>
            {/* <div className='text'>
                <p>-</p>
            </div>
            <div className='text'>
                <p>-</p>
            </div> */}
            <div className='text'>
                <p>{item?.product.productCode}</p>
            </div>
        </div>
    )
}
export default OneComparisonProduct