import { Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IoCloseOutline } from 'react-icons/io5'
import ReactInputMask from 'react-input-mask'
import { Link } from 'react-router-dom'
import './fastOrder.css'

const FastOrder = (props) => {

    const { t } = useTranslation()

    const { isModalVisible1, setIsModalVisible1, submitOrder, setPhone, phone, setName, name } = props

    return (
        <Modal 
            centered
            title={null} 
            visible={isModalVisible1} 
            onOk={() => setIsModalVisible1(false)} 
            onCancel={() => setIsModalVisible1(false)}
            footer={null}
            closable={false}
            className='fast-order-modal'
        >
            <form onSubmit={submitOrder}>
                <IoCloseOutline className='icon'  onClick={() => setIsModalVisible1(false)} />
                <h2>{t('product.fast-order')}</h2>
                <div className='wrap'>
                    <label>{t('cart.name')}</label>
                    <input type='text' value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className='wrap'>
                    <label>{t('cart.phone')}</label>
                    <ReactInputMask maskChar={null} name="phone" mask="+\9\9\8999999999" placeholder='+998' value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                </div>
                <button>{t('cart.send')}</button>
                <Link to='/terms-of-use'>
                    <p>{t('cart.text')}</p>
                </Link>
            </form>
        </Modal>
    )
}

export default FastOrder