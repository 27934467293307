import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BsEyeFill } from 'react-icons/bs'
import { FaCalendarAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from "react-slick";
import './newsById.css'

const NewsById = ({ link }) => {

    const params = useParams()
    const id = params.id
    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)

    const navigate = useNavigate()

    const [newsById, setNewsById] = useState()
    const getNewsById = () => {
        axios.get(`/api/blog?id=${id}`).then(res => {
            setNewsById(res.data.blogs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [randomBlogs, setRandomBlogs] = useState([])
    const getRandomBlogs = () => {
        axios.get(`/api/blog/random`).then(res => {
            setRandomBlogs(res.data.blogs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const editView = () => {
        axios.put(`/api/blog/${id}`, {}).then(res => {
        }).catch(err => {
            navigate('/error')
        })
    }

    useEffect(() => {
        getNewsById()
        getRandomBlogs()
        editView()
        //eslint-disable-next-line
    }, [id])

    const settings = {
        infinite: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const date = new Date(newsById ? newsById.createdAt : '')

    return (
        <div className='news-by-id-component'>
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/news-blog'>{t('footer.news')}</Link> / <span>{newsById ? newsById.title[`${lang}`] : ''}</span></p>
                </div>
                <div className='bg-img' style={{ backgroundImage: `url(${link}/${newsById ? newsById.photo : ''})` }}></div>
                <div className='bg-img-bottom'>
                    <div>
                        <FaCalendarAlt className='icon' />
                        <p className='pp'>{date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-{(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-{date.getFullYear()}</p>
                        <p>{date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:{date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:{date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}</p>
                    </div>
                    <div>
                        <BsEyeFill className='icon' />
                        <p>{newsById ? newsById.views : ''}</p>
                    </div>
                </div>
                <h1>{newsById ? newsById.title[`${lang}`] : ''}</h1>
                <p>{newsById ? newsById.text[`${lang}`] : ''}</p>
                <Slider {...settings}>
                    {
                        randomBlogs && Array.isArray(randomBlogs) ? randomBlogs.map((item, index) => {
                            const date = new Date(item.createdAt)
                            return (
                                <Link to={`/news/${item._id}`} className='one' key={index}>
                                    <div className='bg' style={{ backgroundImage: `url(${link}/${item.photo})` }}></div>
                                    <div className='bg-img-bottom'>
                                        <div>
                                            <FaCalendarAlt className='icon' />
                                            <p className='pp'>{date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}-{date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-{date.getFullYear()}</p>
                                            <p>{date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:{date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:{date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}</p>
                                        </div>
                                        <div>
                                            <BsEyeFill className='icon' />
                                            <p>{item.views}</p>
                                        </div>
                                    </div>
                                    <h2>{item.title[`${lang}`]}</h2>
                                </Link>
                            )
                        }) : ''
                    }
                </Slider>
            </div>
        </div>
    )
}

export default NewsById