import React from 'react'
import errorPng from './../../img/error.png'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './errorPage.css'

const ErrorPage = () => {

    const { t } = useTranslation()

    return (
        <div className='error-page-component'>
            <div className='containercha'>
                <div className='wrapper'>
                    <img src={errorPng} alt='' />
                    <h2>{t('not-found.error')}</h2>
                    <p>{t('not-found.error-p')}</p>
                    <Link to='/'>
                        <button>{t('not-found.home')}</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage