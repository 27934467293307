import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Navbar from './components/navbar/Navbar'
import Home from './components/home/Home'
import Footer from './components/footer/Footer'
import Delivery from './components/delivery/Delivery'
import MasterServise from './components/master-service/MasterServise'
import Selling from './components/selling/Selling'
import MakeOrder from './components/make-order/MakeOrder'
import QualityAssurance from './components/quality-assurance/QualityAssurance'
import ReturnExchange from './components/return-exchange/ReturnExchange'
import TermsOfUse from './components/terms-of-use/TermsOfUse'
import CarServices from './components/car-services/CarServices'
import Advertisement from './components/advertisement/Advertisement'
import AboutUs from './components/about-us/AboutUs'
import Wholesale from './components/wholesale/Wholesale'
import NewsBlog from './components/news-blog/NewsBlog'
import Contact from './components/contact/Contact'
import Jobs from './components/jobs/Jobs'
import InnerProduct from './components/inner-product/InnerProduct'
import Cart from './components/cart/Cart'
import Login from './components/login/Login'
import MyOrders from './components/my-orders/MyOrders'
import MyAddress from './components/my-address/MyAddress'
import MyMessages from './components/my-messages/MyMessages'
import MyComments from './components/my-comments/MyComments'
import Favorites from './components/favorites/Favorites'
import Comparison from './components/comparison/Comparison'
import AllBrands from './components/brands/AllBrands'
import axios from 'axios'
import NewsById from './components/news/NewsById'
import Modals from './components/modals/Modals'
import OneMessage from './components/my-messages/OneMessage'
import Register from './components/login/Register'
import Profile from './components/profile/Profile'
import AllProducts from './components/all-products/AllProducts'
import LeaveReview from './components/leave-review/LeaveReview'
import CatalogByCarBrands from './components/catalog-by-car-brands/CatalogByCarBrands'
import CatalogProducts from './components/catalog-products/CatalogProducts'
import NotFound from './components/not-found/NotFound'
import PlaceOrder from './components/place-order/PlaceOrder'
import OneMyOrder from './components/my-orders/OneMyOrder'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'
import { lanProduct } from './redux/action/LanguageAction'
import AllCategories from './components/all-categories/AllCategories'
import { useTranslation } from 'react-i18next'
import ErrorPage from './components/error/ErrorPage'
import { ToastContainer } from 'react-toastify'
import ScrollToTop from './ScrollToTop'

export const BASE_URL = "https://test.zap.uz"

const App = () => {

  const token = window.localStorage.getItem('token')
  const auth = token ? true : false

  axios.defaults.baseURL = BASE_URL

  const { t } = useTranslation()

  const dispatch = useDispatch()
  const changeLan = (lang) => {
    i18next.changeLanguage(lang)
    dispatch(lanProduct(lang))
  }

  const [rend, setRend] = useState(false)
  const [userMenu, setUserMenu] = useState(false)

  const [favoritesCount, setFavoritesCount] = useState('')
  const getFavoritesCount = () => {
    if (auth) {
      axios.get("/api/favourite", {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }).then(res => {
        setFavoritesCount(res.data.count)
      }).catch(err => {
        setFavoritesCount(0)
      })
    }
  }

  const [cartCount, setCartCount] = useState('')
  const getCartCount = () => {
    if (auth) {
      axios.get("/api/card", {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }).then(res => {
        setCartCount(res.data.count)
      }).catch(err => {
        setCartCount(0)
      })
    }
  }

  const [comparisonCount, setComparisonCount] = useState('')
  const getComparisonCount = () => {
    if (auth) {
      axios.get("/api/contrast", {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }).then(res => {
        setComparisonCount(res.data.count)
      }).catch(err => {
        setComparisonCount(0)
      })
    }
  }

  // MODAL 
  const [showModal, setShowModal] = useState(false)
  const [modalImg, setModalImg] = useState(true)
  const [modalText, setModalText] = useState("")
  const [modalBtn, setModalBtn] = useState("")
  const [modalLink, setModalLink] = useState("")

  const addFavorites = (id) => {
    axios.post(`/api/favourite`, { product: id }, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    }).then(res => {
      setShowModal(true)
      setModalImg(true)
      getFavoritesCount()
      setModalText(t('adding.fav-completed'))
      setModalBtn(t('adding.go-fav'))
      setModalLink("favorites")
    }).catch(err => {
      setShowModal(true)
      setModalImg(false)
      setModalText(t('adding.please-fav'))
      setModalBtn(t('adding.register'))
      setModalLink("register")
    })
  }

  const addCart = (id) => {
    axios.post('/api/card', { product: id, count: 1, master: false }, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    }).then(res => {
      setShowModal(true)
      setModalImg(true)
      getCartCount()
      setModalText(t('adding.cart-completed'))
      setModalBtn(t('adding.go-cart'))
      setModalLink("cart")
    }).catch(() => {
      setShowModal(true)
      setModalImg(false)
      setModalText(t('adding.please-cart'))
      setModalBtn(t('adding.register'))
      setModalLink("register")
    })
  }

  const addComparison = (id) => {
    axios.post('/api/contrast', { product: id }, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    }).then(res => {
      setShowModal(true)
      setModalImg(true)
      getComparisonCount()
      setModalText(t('adding.com-completed'))
      setModalBtn(t('adding.go-com'))
      setModalLink("comparison")
    }).catch(err => {
      setShowModal(true)
      setModalImg(false)
      setModalText(t('adding.please-com'))
      setModalBtn(t('adding.register'))
      setModalLink("register")
    })
  }

  const [userData, setUserData] = useState([])
  const getUserData = () => {
    if (token) {
      axios.get('/api/user', {
        headers: {
          'authorization': `Bearer ${token}`
        }
      }).then(res => {
        setUserData(res.data.findUser)
      }).catch(err => {
        console.log(err)
      })
    }
  }


  const addSeen = (id) => {
    let arr = JSON.parse(window.localStorage.getItem('seen'))
    if (arr === null) {
      arr = []
    }
    arr.push(id)
    let newArr = [...new Set(arr)]
    window.localStorage.setItem('seen', JSON.stringify(newArr.slice(-5)))
  }

  useEffect(() => {
    getFavoritesCount()
    getCartCount()
    getComparisonCount()
    getUserData()
    //eslint-disable-next-line
  }, [auth, rend])

  // const link = "http://localhost:4005"
  const link = "http://admin.zap.uz"

  const loginRoutes = (
    <Routes>
      <Route path='/' element={<Home link={link} auth={auth} addCart={addCart} addFavorites={addFavorites} addComparison={addComparison} addSeen={addSeen} />} />
      <Route path='/login' element={<Login rend={rend} setRend={setRend} setUserMenu={setUserMenu} getFavoritesCount={getFavoritesCount} getUserData={getUserData} />} />
      <Route path='/register' element={<Register rend={rend} setRend={setRend} setUserMenu={setUserMenu} getUserData={getUserData} />} />
      <Route path='/all-products' element={<AllProducts link={link} addFavorites={addFavorites} addCart={addCart} addComparison={addComparison} addSeen={addSeen} />} />
      <Route path='/all-brands' element={<AllBrands link={link} />} />
      <Route path='/delivery' element={<Delivery />} />
      <Route path='/master-service' element={<MasterServise />} />
      <Route path='/sell-on-zap' element={<Selling />} />
      <Route path='/how-to-make-order' element={<MakeOrder />} />
      <Route path='/quality-assurance' element={<QualityAssurance />} />
      <Route path='/return-exchange' element={<ReturnExchange />} />
      <Route path='/terms-of-use' element={<TermsOfUse />} />
      <Route path='/car-services' element={<CarServices />} />
      <Route path='/advertisement' element={<Advertisement />} />
      <Route path='/about-us' element={<AboutUs />} />
      <Route path='/wholesale-buyers' element={<Wholesale />} />
      <Route path='/news-blog' element={<NewsBlog link={link} />} />
      <Route path='/news/:id' element={<NewsById link={link} />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/jobs' element={<Jobs />} />
      <Route path='/product/:id' element={<InnerProduct auth={auth} link={link} addFavorites={addFavorites} addCart={addCart} addComparison={addComparison} addSeen={addSeen} />} />
      <Route path='/cart' element={<Cart link={link} getCartCount={getCartCount} addFavorites={addFavorites} />} />
      <Route path='/favorites' element={<Favorites link={link} getFavoritesCount={getFavoritesCount} addCart={addCart} />} />
      <Route path='/comparison' element={<Comparison link={link} getComparisonCount={getComparisonCount} addCart={addCart} />} />
      <Route path='/leave-review' element={<LeaveReview />} />
      <Route path='/catalog-by-car-brands' element={<CatalogByCarBrands link={link} />} />
      <Route path='/catalog-products' element={<CatalogProducts link={link} />} />
      <Route path='/all-categories' element={<AllCategories />} />
      <Route path='/error' element={<ErrorPage />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )

  const authRoutes = (
    <Routes>
      <Route path='/' element={<Home link={link} auth={auth} addCart={addCart} addFavorites={addFavorites} addComparison={addComparison} addSeen={addSeen} />} />
      <Route path='/all-products' element={<AllProducts link={link} addFavorites={addFavorites} addCart={addCart} addComparison={addComparison} addSeen={addSeen} />} />
      <Route path='/all-brands' element={<AllBrands link={link} />} />
      <Route path='/profile' element={<Profile link={link} userData={userData} rend={rend} setRend={setRend} />} />
      <Route path='/delivery' element={<Delivery />} />
      <Route path='/master-service' element={<MasterServise />} />
      <Route path='/sell-on-zap' element={<Selling />} />
      <Route path='/how-to-make-order' element={<MakeOrder />} />
      <Route path='/quality-assurance' element={<QualityAssurance />} />
      <Route path='/return-exchange' element={<ReturnExchange />} />
      <Route path='/terms-of-use' element={<TermsOfUse />} />
      <Route path='/car-services' element={<CarServices />} />
      <Route path='/advertisement' element={<Advertisement />} />
      <Route path='/about-us' element={<AboutUs />} />
      <Route path='/wholesale-buyers' element={<Wholesale />} />
      <Route path='/news-blog' element={<NewsBlog link={link} />} />
      <Route path='/news/:id' element={<NewsById link={link} />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/jobs' element={<Jobs />} />
      <Route path='/product/:id' element={<InnerProduct auth={auth} link={link} addFavorites={addFavorites} addCart={addCart} addComparison={addComparison} addSeen={addSeen} />} />
      <Route path='/cart' element={<Cart link={link} getCartCount={getCartCount} addFavorites={addFavorites} auth={auth} />} />
      <Route path='/favorites' element={<Favorites link={link} getFavoritesCount={getFavoritesCount} addCart={addCart} />} />
      <Route path='/comparison' element={<Comparison link={link} getComparisonCount={getComparisonCount} addCart={addCart} />} />
      <Route path='/my-orders' element={<MyOrders link={link} userData={userData} rend={rend} setRend={setRend} setFavoritesCount={setFavoritesCount} setCartCount={setCartCount} setComparisonCount={setComparisonCount} />} />
      <Route path='/my-orders/:id' element={<OneMyOrder link={link} userData={userData} rend={rend} setRend={setRend} setFavoritesCount={setFavoritesCount} setCartCount={setCartCount} setComparisonCount={setComparisonCount} />} />
      <Route path='/my-address' element={<MyAddress link={link} userData={userData} rend={rend} setRend={setRend} setFavoritesCount={setFavoritesCount} setCartCount={setCartCount} setComparisonCount={setComparisonCount} />} />
      <Route path='/my-messages' element={<MyMessages link={link} userData={userData} rend={rend} setRend={setRend} setFavoritesCount={setFavoritesCount} setCartCount={setCartCount} setComparisonCount={setComparisonCount} />} />
      <Route path='/my-messages/:id' element={<OneMessage link={link} />} />
      <Route path='/my-comments' element={<MyComments link={link} userData={userData} rend={rend} setRend={setRend} setFavoritesCount={setFavoritesCount} setCartCount={setCartCount} setComparisonCount={setComparisonCount} />} />
      <Route path='/product/:id/leave-review' element={<LeaveReview />} />
      <Route path='/catalog-by-car-brands' element={<CatalogByCarBrands link={link} />} />
      <Route path='/catalog-products' element={<CatalogProducts link={link} />} />
      <Route path='/place-order' element={<PlaceOrder />} />
      <Route path='/all-categories' element={<AllCategories />} />
      <Route path='/error' element={<ErrorPage />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )

  return (
    <div>
      <BrowserRouter>
        <ToastContainer />
        {<Modals showModal={showModal} setShowModal={setShowModal} modalImg={modalImg} modalText={modalText} modalBtn={modalBtn} modalLink={modalLink} />}
        <Navbar
          auth={auth}
          link={link}
          rend={rend}
          setRend={setRend}
          userMenu={userMenu}
          setUserMenu={setUserMenu}
          favoritesCount={favoritesCount}
          setFavoritesCount={setFavoritesCount}
          cartCount={cartCount}
          setCartCount={setCartCount}
          comparisonCount={comparisonCount}
          setComparisonCount={setComparisonCount}
          userData={userData}
          changeLan={changeLan}
        />
        <ScrollToTop />
        {
          auth ? authRoutes : loginRoutes
        }
        <Footer link={link} />
      </BrowserRouter>
    </div>
  )
}

export default App