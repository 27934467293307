import React from 'react'
import { useTranslation } from 'react-i18next'
import { GrClose } from 'react-icons/gr'
import falsePng from '../../img/false.png'
import './loginError.css'

const LoginError = ({ setShowErr, showErr }) => {

    const { t } = useTranslation()

    return (
        <>
            <div className={showErr ? 'modals-component modals-component-active': 'modals-component'} onClick={() => setShowErr(false)}></div>
            <div className={showErr ? 'inner-modal inner-modal-active' : 'inner-modal'}>
                <GrClose className='icon' onClick={() => setShowErr(false)} />
                <div>
                    <img src={falsePng} alt='' />
                    <h3>{t('login.error')}</h3>
                </div>
            </div>
        </>
    )
}

export default LoginError