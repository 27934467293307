import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import plusPng from '../../img/plus.png'
import undovPng from '../../img/undov.png'
import { Rating } from 'react-simple-star-rating'
import './leaveReview.css'
import axios from 'axios'
import CommentModal from './CommentModal'
import { useTranslation } from 'react-i18next'

const LeaveReview = () => {

    const token = window.localStorage.getItem('token')

    const { t } = useTranslation()

    const navigate = useNavigate()

    const params = useParams()
    const productId = params.id

    const [ showModal, setShowModal ] = useState(false)

    const [ ratingValue, setRatingValue ] = useState(0)
    const [ name, setName ] = useState('')
    const [ text, setText ] = useState('')
    const [ image, setImage ] = useState(null)
    const [ imageFileUrl, setImageFileUrl ] = useState()

    const getUrl = (e) => {
        const files = Array.from(e.target.files);
        setImageFileUrl(URL.createObjectURL(files[0]));
        setImage(e.target.files);
    }
    
    const handleRating = (rate) => {
        setRatingValue(rate / 20)
    }

    const addComment = (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('photo', image ? image[0] : null)
        formData.append('rate', ratingValue)
        formData.append('name', name)
        formData.append('comment', text)
        formData.append('product', productId)

        axios.post(`/api/comment`, formData, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        }).then(res => {
            setShowModal(true)
            setImage(null)
            setImageFileUrl()
            setName('')
            setText('')
            setRatingValue(0)
        }).catch(err => {
            navigate('/error')
        })

    }

    return (
        <div className='leave-review-component'>
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/profile'>{t('home.profile')}</Link> / <span>{(t('home.my-comment'))}</span></p>
                </div>
                <h1>{t('home.place-comment')}</h1>
                <form onSubmit={addComment}>
                    <div>
                        <label>{t('cart.rate')}</label>
                        <Rating onClick={handleRating} ratingValue={ratingValue} allowHover={false} size={36} required />
                    </div>
                    <div>
                        <label>{t('cart.name')}</label>
                        <input type='text' className='input' value={name} onChange={(e) => setName(e.target.value)} required placeholder={t('cart.name')} />
                    </div>
                    <div>
                        <label>{t('cart.comment')}</label>
                        <textarea placeholder={t('cart.write')} value={text} onChange={(e) => setText(e.target.value)} required></textarea>
                    </div>
                    <div className='image'>
                        <input type='file' id='logo' files={image} accept='.jpg, .jpeg, .png' onChange={getUrl} />
                        <div className='add'>
                            <img src={plusPng} alt='' />
                            <p>{t('cart.add-photo')}</p>
                        </div>
                        <div className='bg-img' style={{backgroundImage: `url(${imageFileUrl})`, display: image && imageFileUrl ? 'block' : 'none'}}></div>
                    </div>
                    <div className='bottom' style={{marginTop: image ? '0px' : '80px'}}>
                        <div className='left'>
                            <img src={undovPng} alt='' />
                            <p>{t('cart.comment-text')}</p>
                        </div>
                        <button type='submit'>{t('cart.send-comment')}</button>
                    </div>
                </form>
            </div>
            <CommentModal showModal={showModal} setShowModal={setShowModal} productId={productId} />
        </div>
    )
}

export default LeaveReview