import React from 'react'
import './loader.css'

const Loader = () => {
    return (
        <div>
            <div className="loadingio-spinner-double-ring-37xotn52hhw">
                <div className="ldio-rp9wzwkflh">
                    <div></div>
                    <div></div>
                    <div><div></div></div>
                    <div><div></div></div>
                </div>
            </div>
        </div>
    )
}

export default Loader