import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { IoCloseOutline, IoShareSocialSharp } from 'react-icons/io5'
import ExtraImages from './ExtraImages'
import { AiFillHeart } from 'react-icons/ai'
import { RiBarChartFill } from 'react-icons/ri'
import { BsFillCartFill } from 'react-icons/bs'
import { Rating } from 'react-simple-star-rating'

import './innerProduct.css'
import ContinueProduct from './ContinueProduct'
import axios from 'axios'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import { Modal } from 'antd'
import ReactInputMask from 'react-input-mask'
import SuccessOrderModal from '../fast-view/SuccessOrderModal'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const InnerProduct = ({ link, addFavorites, addCart, addComparison, auth, addSeen }) => {

    const params = useParams()
    const productId = params.id

    const [activeIndex, setActiveIndex] = useState(null);

    const { t } = useTranslation()
    const { lang } = useSelector(state => state.nowLanguage)
    const { currency } = useSelector(state => state.currencyReducer)

    const navigate = useNavigate()

    const [currencyValue, setCurrencyValue] = useState()
    const getCurrencyValue = () => {
        axios.get('/api/course').then(res => {
            setCurrencyValue(res.data.course.uzs)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [isModalVisible1, setIsModalVisible1] = useState(false)

    const [productById, setProductById] = useState([])

    const [productRate, setProductRate] = useState()
    const getProductById = () => {
        axios.get(`/api/products?id=${productId}`).then(res => {
            setProductById(res.data.products)
            setProductRate(res.data.rate[0] ? res.data.rate[0].rate : null)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [allSimilarProducts, setAllSimilarProducts] = useState([])
    const getSimilarProducts = () => {
        axios.get('/api/similar', {
            params: {
                id: productId
            }
        }).then(res => {
            setAllSimilarProducts(res.data.products?.similar.slice(0, 6))
        }).catch(err => {
            navigate('/error')
        })
    }

    const [allSimilarAdsProducts, setAllSimilarAdsProducts] = useState([])
    const getSimilarAdsProducts = () => {
        axios.get('/api/similarads', {
            params: {
                id: productId
            }
        }).then(res => {
            setAllSimilarAdsProducts(res.data.products?.similar.slice(0, 6))
        }).catch(err => {
            navigate('/error')
        })
    }

    const [allComments, setAllComments] = useState([])
    const [commentsCount, setCommentsCount] = useState('')
    const getComments = () => {
        axios.get(`/api/comment`, {
            params: {
                product: productId
            }
        }).then(res => {
            setAllComments(res.data.comments)
            setCommentsCount(res.data.count)
        }).catch(err => {
            navigate('/error')
        })
    }

    const [num, setNum] = useState(1)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [master, setMaster] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [orderId, setOrderId] = useState('')
    const submitOrder = (e) => {
        e.preventDefault()
        const formData = {
            firstname: name,
            phone,
            totalCount: num,
            totalDiscount: productById.discount ? Math.floor((productById.discount * productById.price) / 100) * num : 0,
            totalPrice: productById.price * num,
            totalMaster: master ? productById.master : 0,
            products: [
                {
                    product: productId,
                    count: num,
                    master: master ? productById.master : 0,
                    sum: productById.price * num,
                    discount: productById.discount ? Math.floor((productById.discount * productById.price) / 100) * num : 0
                }
            ]
        }
        axios.post('/api/order/quick', formData).then(res => {
            setOrderId(res.data.orderId)
            setIsModalVisible1(false)
            setShowModal(true)
        }).catch(err => {
            navigate('/error')
        })
    }

    function reFormat(num) {
        return ('' + num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    }

    useEffect(() => {
        getProductById()
        getSimilarProducts()
        getSimilarAdsProducts()
        getComments()
        //eslint-disable-next-line
    }, [productId])

    useEffect(() => {
        getCurrencyValue()
    }, [])

    const copyClipboard = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.info(t('extra.copied'))
    }

    return (
        <div className='inner-product-component'>
            <SuccessOrderModal
                setShowModal={setShowModal}
                showModal={showModal}
                orderId={orderId}
            />
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/' className='gl'>{t('home.home')}</Link> / <Link to={`/all-products?brand=${productById.brand?._id}`} className='gl'>{productById.brand ? productById.brand.name : ''}</Link> / <span>{productById.name ? productById.name[`${lang}`] : ''} {productById.partner ? productById.partner.name : ''} {productById.model ? productById.model.name : ''}</span></p>
                    <MdOutlineKeyboardBackspace className='iconn' onClick={() => navigate(-1)} />
                    <div>
                        <IoShareSocialSharp title={t('extra.share')} className='icon' onClick={copyClipboard} />
                    </div>
                </div>

                <div className='about-product'>
                    <div className='images-wrap'>
                        <div className='primary-img' style={{ backgroundImage: activeIndex === null ? `url(${link}/${productById ? productById.photo : ''})` : `url(${link}/${productById.photos[activeIndex]})` }}>
                            <div className='left-top' style={{ display: `${productById.discount === null ? 'none' : 'flex'}` }}>
                                <span>{`-${productById.discount}%`}</span>
                            </div>
                            <div className='right-top' style={{ display: `${productById.top ? 'flex' : 'none'}` }}>
                                <div></div>
                                <span>{t('product.lider')}</span>
                            </div>
                            <div className='left-bottom' style={{ display: `${productById.status === 'advert' ? 'flex' : 'none'}` }}>
                                <span>{t('product.advert')}</span>
                            </div>
                            <div className='left-bottom left-bottom-second' style={{ display: `${productById.status === 'new' ? 'flex' : 'none'}` }}>
                                <span>{t('product.new')}</span>
                            </div>
                            <div className='right-bottom' style={{ display: `${(productById.sale && productById.sale.status) ? 'flex' : 'none'}` }}>
                                <span>{t('product.sale')}</span>
                            </div>
                        </div>
                        <ExtraImages link={link} images={productById ? productById.photos : ''} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
                    </div>

                    <div className='data-text'>
                        <h2>{productById.brand ? productById.brand.name : ''} / {productById.name ? productById.name[`${lang}`] : ''} / {productById.partner ? productById.partner.name : ''} {productById.model ? productById.model.name : ''}</h2>
                        {
                            productRate ? (
                                <Rating ratingValue={productRate * 20} readonly size={32} />
                            ) : null
                        }
                        <p>{t('product.kod')} <strong>{productById.productCode}</strong></p>
                        {
                            currency === 'UZS' ? (
                                <div className='jc'>
                                    <h3>{(productById.price * (100 - productById.discount) / 100) ? reFormat(Math.ceil((productById.price * (100 - productById.discount)) / 100) * currencyValue) : ''} {t('cart.som')}</h3>
                                    <h6 style={{ display: productById.discount ? 'block' : 'none', color: 'red' }}>{reFormat(productById.price * currencyValue)} {t('cart.som')}</h6>
                                </div>
                            ) : (
                                <div className='jc'>
                                    <h3>{(productById.price * (100 - productById.discount) / 100) ? reFormat(Math.ceil((productById.price * (100 - productById.discount)) / 100)) : ''} $</h3>
                                    <h6 style={{ display: productById.discount ? 'block' : 'none', color: 'red' }}>{reFormat(productById.price)} $</h6>
                                </div>
                            )
                        }
                        <div className='jc' style={{ display: `${(productById.sale && productById.sale.status) ? 'flex' : 'none'}` }}>
                            <span>{t('product.time-sale')} <strong>{productById.sale ? productById.sale[`${lang}`] : ''}</strong></span>
                            <div>{t('product.sale')}</div>
                        </div>
                        <p>{t('product.country')} <strong>{productById.country ? productById.country[`${lang}`] : ''}</strong></p>
                        <img src={`${link}/${productById.brand ? productById.brand.photo : ''}`} alt='' onClick={() => navigate(`/all-products/?brand=${productId}`)} />
                        {
                            productById.master ? (
                                <div className='input-label'>
                                    <input type='checkbox' value={master} onChange={() => setMaster(!master)} />
                                    <label>{t('product.master')} <span className='mx-2'>{reFormat(productById.master)} {t('cart.som')}</span></label>
                                </div>
                            ) : null
                        }
                        <div className='in-btn'>
                            <div className='add'>
                                <div onClick={() => num > 1 ? setNum(num - 1) : null}>
                                    <p>-</p>
                                </div>
                                <input type='number' value={num} readOnly />
                                <div onClick={() => setNum(num + 1)}>
                                    <p>+</p>
                                </div>
                            </div>
                            <button onClick={() => setIsModalVisible1(true)}>{t('product.fast-order')}</button>
                        </div>
                        <div className='bottom'>
                            <div className='left'>
                                <div onClick={() => addFavorites(productId)}>
                                    <AiFillHeart className='icon' />
                                </div>
                                <div onClick={() => addComparison(productId)}>
                                    <RiBarChartFill className='icon' />
                                </div>
                            </div>
                            <button onClick={() => addCart(productId)}><BsFillCartFill className='icon' />{t('product.cart')}</button>
                        </div>
                    </div>
                </div>

                <Modal
                    centered
                    title={null}
                    visible={isModalVisible1}
                    onOk={() => setIsModalVisible1(false)}
                    onCancel={() => setIsModalVisible1(false)}
                    footer={null}
                    closable={false}
                    className='fast-order-modal'
                >
                    <form onSubmit={submitOrder}>
                        <IoCloseOutline className='icon' onClick={() => setIsModalVisible1(false)} />
                        <h2>{t('product.fast-order')}</h2>
                        <div className='wrap'>
                            <label>{t('cart.name')}</label>
                            <input type='text' value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div className='wrap'>
                            <label>{t('cart.phone')}</label>
                            <ReactInputMask maskChar={null} name="phone" mask="+\9\9\8999999999" placeholder='+998' value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </div>
                        <button>{t('cart.send')}</button>
                        <Link to='/terms-of-use'>
                            <p>{t('cart.text')}</p>
                        </Link>
                    </form>
                </Modal>

                <ContinueProduct
                    productId={productId}
                    productById={productById}
                    allSimilarProducts={allSimilarProducts}
                    link={link}
                    allSimilarAdsProducts={allSimilarAdsProducts}
                    commentsCount={commentsCount}
                    allComments={allComments}
                    auth={auth}
                    currency={currency}
                    currencyValue={currencyValue}
                    addSeen={addSeen}
                />

            </div>
        </div>
    )
}

export default InnerProduct