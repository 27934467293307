import { CURRENCY } from '../action/Types'
const initilState = {
    currency: window.localStorage.getItem('currency') ? window.localStorage.getItem('currency') : "UZS"
}
export const CurrencyReducers = (state = initilState, action) => {

    switch (action.type) {
        case CURRENCY:

            return {
                currency: action.payload
            }

        default:
            return state
    }
}



export default CurrencyReducers