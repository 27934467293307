import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BiEditAlt } from 'react-icons/bi'
import { HiOutlineLogout } from 'react-icons/hi'
import { IoIosArrowForward } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import './myAddress.css'
import { BASE_URL } from '../../App'

const MyAddress = ({ link, userData, setRend, rend, setFavoritesCount, setCartCount, setComparisonCount }) => {

    const { t } = useTranslation()

    const navigate = useNavigate()
    const logout = () => {
        window.localStorage.removeItem('token')
        navigate('/')
        setRend(!rend)
        setFavoritesCount(0)
        setCartCount(0)
        setComparisonCount(0)
    }

    return (
        <div className='my-address-component'>
            <div className='containercha'>
                <div className='top'>
                    <p><Link to='/'>{t('home.home')}</Link> / <Link to='/profile'>{t('home.profile')}</Link> / <span>{t('profile.my-address')}</span></p>
                </div>

                <div className='wrapper'>
                    <div className='left-user-menu'>
                        <div className='user-top'>
                            <div className='bg' style={{ backgroundImage: `url(${BASE_URL}/${userData ? userData.photo : ''})` }}></div>
                            <div className='name-phone'>
                                <h4>{userData ? userData.firstname : ''} {userData ? userData.lastname : ''}</h4>
                                <p>{userData ? userData.number : ''}</p>
                            </div>
                            <Link to='/profile' className='icon-wrap'>
                                <BiEditAlt className='icon' />
                            </Link>
                        </div>
                        <div className='links-wrapper'>
                            <Link to='/my-orders'>
                                <h5>{t('profile.my-orders')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-address'>
                                <h5>{t('profile.my-address')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-messages'>
                                <h5>{t('profile.sms')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/my-comments'>
                                <h5>{t('profile.my-comments')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/favorites'>
                                <h5>{t('navbar.favorites')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                            <Link to='/comparison'>
                                <h5>{t('profile.comparison-list')}</h5>
                                <IoIosArrowForward className='icon' />
                            </Link>
                        </div>
                        <div className='logout-wrap' style={{ cursor: 'pointer' }} onClick={logout}>
                            <HiOutlineLogout className='icon' />
                            <h5>{t('profile.logout')}</h5>
                        </div>
                    </div>

                    <div className='right-layout'>
                        <h1>{t('profile.my-address')}</h1>
                        <button className='add-btn'>+ Добавить новый</button>
                        <Row className='all-address'>
                            <Col className='col' lg={6} md={6} sm={12} xs={12}>
                                <div className='address'>
                                    <div className='p-left'>
                                        <p>Адрес:</p>
                                    </div>
                                    <div className='p-right'>
                                        <p>Ташкент, Юнусабадский район, 15-квартал, 48</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col' lg={6} md={6} sm={12} xs={12}>
                                <div className='address'>
                                    <div className='p-left'>
                                        <p>Адрес:</p>
                                    </div>
                                    <div className='p-right'>
                                        <p>Ташкент, Юнусабадский район, 15-квартал, 48</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyAddress